<div class="ml-8 login-container" *ngIf="!isResetDone">
  <h1 class="text-center mb-3">Password reset</h1>
  <div class="pwd-label">
    <span>Enter a strong password</span>
    <aux-info class="center-tip" [content]="passwordRequirement"></aux-info>
  </div>
  <div class="field fluid mb-3">
    <span class="p-input-icon-right">
      <i class="pi pi-eye pointer" *ngIf="p1.type === 'password'" (click)="p1.type = 'text'"></i>
      <i class="pi pi-eye-slash pointer" *ngIf="p1.type === 'text'" (click)="p1.type = 'password'"></i>
      <input #p1 id="password" name="password" type="password" pInputText placeholder="New Password"
             [(ngModel)]="passwords.password" autocomplete="new-password" [required]="true"
             (ngModelChange)="checkPasswordStrength($event)"
             [ngClass]="{'invalid-border': showPasswordFeedback}">
    </span>
  </div>
  <ng-container *ngIf="showPasswordFeedback" >
    <aux-strong-pwd-feedback [passwordCriteria]="passwordCriteria" [column]="2"></aux-strong-pwd-feedback>
  </ng-container>
  <div class="field fluid mb-1">
    <span class="p-input-icon-right">
      <i class="pi pi-eye pointer" *ngIf="p2.type === 'password'" (click)="p2.type = 'text'"></i>
      <i class="pi pi-eye-slash pointer" *ngIf="p2.type === 'text'" (click)="p2.type = 'password'"></i>
      <input #p2 id="rePassword" name="password" type="password" pInputText placeholder="Confirm New Password"
             [(ngModel)]="passwords.rePass" autocomplete="new-password" [required]="true">
    </span>
  </div>
  <div class="mb-3" *ngIf="error">
    <small class="login-error" id="password-error">{{error}}</small>
  </div>
  <div class="field fluid mb-3 text-center">
    <p-button label="reset password" styleClass="login-btn-secondary" (onClick)="resetPassword()"></p-button>
  </div>
  <div class="text-center mb-3">
    <div class="new-user">Did you remember your password?</div>
    <p-button styleClass="p-button-link" label="Try logging in" (onClick)="logIn()" [link]="true"></p-button>
  </div>
</div>
<div class="login-container" *ngIf="isResetDone">
  <h1 class="text-center mb-3">Successful password reset 🙌</h1>
  <div class="text-center mb-3">
    <div class="new-user">You can now use your new password to log in to your account</div>
  </div>
  <div class="field fluid mb-3 text-center">
    <p-button label="continue" styleClass="login-btn-secondary" (onClick)="logIn()"></p-button>
  </div>
</div>
