import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileLayoutComponent} from '../profile-layout.component';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {Location} from '@angular/common';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  loading = false;
  unsubscribeParams: any  = {
    event: null,
    subscription: null,
    jobId: null,
    aid: null,
    email: null,
  };
  constructor(private router: Router, private route: ActivatedRoute, private location: Location,
              @Inject(ProfileLayoutComponent) public parent: ProfileLayoutComponent) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.route.queryParams
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe(params => {
          this.unsubscribeParams = _.clone(params);
          this.location.replaceState('unsubscribeMe');
          this.unsubscribe();
        }
      );
  }
  logIn(): void {
    this.parent.winRef.reloadLocation();
  }
  async unsubscribe(): Promise<void> {
      // TEST unsubscribeMe/?email=vadim@adcore.com&event=notification&subscription=job&jobId=586cdf0ab3c07a4e32b0608a
      const result = await this.parent.apiCallService.unsubscribe(this.unsubscribeParams).toPromise();
      console.log('unsubscribe: ', {result});
      this.loading = false;
  }
}
