import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as helper from '../shared/helper';
import moment from 'moment-timezone';

@Component({
  selector: 'aux-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {
  @Input() disabled = false;
  @Input() translateService: any | undefined;
  @Input() toolBarActionKey: string | undefined;
  @Input() appKey: string | undefined;
  @Input() isAdmin = false;
  @Input() showTitle = false;
  @Input() withNow = false;
  @Input() schedule: helper.ISchedule | undefined;
  @Input() classStyle = '';
  @Output() scheduleChange = new EventEmitter<helper.ISchedule>(false);
  private repeaterList: helper.ISchedule[] = [];
  private formErrors: any = null;

  constructor() {
  }
  getToolTip(key: string): string {
    // if (this.toolBarActionKey) {
      // console.log(this.toolBarActionKey, ' translations ', ` ng-${this.toolBarActionKey}-${key}`.toUpperCase());
      // return this.translate(`ng-${this.toolBarActionKey}-${key}`);
    // }
    const prop = this.appKey ? `${this.appKey}-` : '';
    return this.translate(`ng-${prop}schedule-tooltip-${key}`);
  }
  get selectedScheduleRepeater(): helper.EScheduleType {
    return this.schedule?.repeater || helper.EScheduleType.NONE;
  }

  set selectedScheduleRepeater(value: helper.EScheduleType) {
    helper.safeToObjProp(this.schedule, 'repeater', value);
  }

  ngOnInit(): void {
    if (!this.schedule) {
      throw new Error('schedule not defined');
    }
    if (!this.schedule?.repeater) {
      helper.safeToObjProp(this.schedule, 'repeater', helper.EScheduleType.NONE);
    }
    this.repeaterList = [{
      repeater: helper.EScheduleType.NOW,
      label: this.translate('NOW'),
    }, {
      repeater: helper.EScheduleType.ONE_TIME,
      label: this.translate('ONE_TIME'),
    },
      {
        repeater: helper.EScheduleType.HOURLY,
        label: this.translate('HOURLY'),
      }, {
        repeater: helper.EScheduleType.DAILY,
        label: this.translate('DAILY'),
      }, {
        repeater: helper.EScheduleType.WEEKLY,
        label: this.translate('WEEKLY'),
      },
      {
        repeater: helper.EScheduleType.MONTHLY,
        label: this.translate('MONTHLY'),
      },
      {
        repeater: helper.EScheduleType.NEVER,
        label: this.translate('NEVER'),
      }
    ];
    if (this.isAdmin) {
      this.repeaterList.splice(2, 0,
        {
          repeater: helper.EScheduleType.MINUTELY,
          label: this.translate('MINUTELY'),
        });
    }
    const timeZone = moment.tz.guess(true);
    // console.log({timeZone});
    helper.safeToObjProp(this.schedule, 'timezone', timeZone);
  }

  get repeaterLabel(): string {
    return this.repeaterList.find((l: helper.ISchedule) => l.repeater === this.selectedScheduleRepeater)?.label || '';
  }
  get filteredRepeaterList(): helper.ISchedule[] {
    return this.repeaterList.reduce((arr: helper.ISchedule[], l: helper.ISchedule) => {
      if ((this.withNow && l.repeater === helper.EScheduleType.NOW) || l.repeater !== helper.EScheduleType.NOW) {
        arr.push(l);
      }
      return arr;
    }, []);
  }

  onRepeaterChange(event: any): void {
    helper.safeToObjProp(this.schedule, 'repeater', event);
    helper.safeToObjProp(this.schedule, 'repeaterOptions', []);
    helper.safeToObjProp(this.schedule, 'isSpecificHour', false);
    helper.safeToObjProp(this.schedule, 'timezone', moment.tz.guess());
  }

  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }

  get showLabel(): boolean {
    return ![helper.EScheduleType.ONE_TIME, helper.EScheduleType.NEVER, helper.EScheduleType.NOW,
      helper.EScheduleType.NONE].includes(this.selectedScheduleRepeater);
  }

  addError(errKey: string, errMsg: string): void {
    if (!this.formErrors) {
      this.formErrors = {};
    }
    if (!this.formErrors[errKey]) {
      this.formErrors[errKey] = [];
    }
    this.formErrors[errKey].push(errMsg);
  }

  getError(errKey: string): string[] {
    if (this.formErrors) {
      return this.formErrors[errKey] || [];
    }
    return [];
  }

  public validate(): void {
    this.formErrors = null;
    switch (this.schedule?.repeater) {
      case helper.EScheduleType.HOURLY:
      case helper.EScheduleType.DAILY:
      case helper.EScheduleType.MINUTELY:
        if (!this.schedule?.repeaterOptions?.length) {
          this.addError(this.schedule?.repeater, 'REQUIRED');
        }
        break;
      case helper.EScheduleType.ONE_TIME:
        if (helper.safeListAt(this.schedule?.repeaterOptions, 0) < new Date()) {
          this.addError(this.schedule?.repeater, 'WRONG_TIME');
        }
        break;
      case helper.EScheduleType.MONTHLY:
      case helper.EScheduleType.WEEKLY:
        if (!this.schedule?.repeaterOptions?.length || this.schedule?.repeaterOptions?.length < 2) {
          this.addError(this.schedule?.repeater, 'REQUIRED');
        }
        break;
    }
  }

  public get isValid(): boolean {
    return !this.formErrors || Object.keys(this.formErrors).length === 0;
  }

}

export const changeRepeaterBaseOnStatus = (schedule: helper.ISchedule | null | undefined,
                                           status: string | null | undefined, defaultRepeater?: helper.EScheduleType): helper.ISchedule => {
  if (!schedule) {
    // schedule = {
    //   repeaterOptions: [1], isSpecificHour: false,
    //   repeater: helper.EScheduleType.DAILY,
    //   timezone: moment.tz.guess(true)
    // };
    schedule = {
      repeaterOptions: defaultRepeater ===  helper.EScheduleType.HOURLY ? [6] : [] , isSpecificHour: false,
      repeater: (defaultRepeater) ? defaultRepeater as helper.EScheduleType : helper.EScheduleType.NONE,
      timezone: moment.tz.guess(true),
    };
  }
  if (status === 'paused') {
    schedule.repeaterOptions = [];
    schedule.repeater = helper.EScheduleType.NEVER;
  }

  // console.log('changeRepeaterBaseOnStatus: ', {schedule});
  return schedule;
};
