<!--suppress HtmlUnknownAttribute -->
<p-toast key="2faMessage" position="top-center"></p-toast>
<div class="ml-8 login-container join" [ngClass]="parent.isDesktopDevice ? formButtonClass + ' ' + currentStage: 'login-mobile '+ formButtonClass + ' ' + currentStage" >
  <div class="logo-img first-stage" [ngClass]="currentStage" *ngIf="currentStage === 'stage1'">
    <span [ngClass]="planImgClass"></span>
    <label>{{translatedTitle}}</label>
  </div>
  <ng-container *ngIf="!uit">
    <h1 class="text-center" [ngClass]="currentStage">{{formHeader}}</h1>
    <h2 class="text-center mt-2" [ngClass]="currentStage" *ngIf="formSubHeader">{{formSubHeader}}</h2>
  </ng-container>
  <ng-container *ngIf="uit">
    <h1 class="text-center">Welcome to Adcore Marketing Cloud!</h1>
    <h2 class="text-center mt-2">Complete Your Sign Up</h2>
  </ng-container>
  <ng-container *ngIf="currentStage === 'stage1'">
    <div class="justify-content-center mb-3" [ngClass]="!parent.isDesktopDevice ? 'block': 'flex'" *ngIf="!failedReCaptchaV3">
      <div class="align-g-btn">
        <asl-google-signin-button type='standard' size='large' logo_alignment="left" [width]="214" [style]="failedReCaptchaV3 ? {'pointer-events': 'none'} : {'pointer-events': 'all'}"
                                text="signup_with" shape="rectangular" theme="filled_blue"></asl-google-signin-button>
      </div>
      <div>
        <p-button label="Sign up with Facebook" icon="pi pi-facebook" styleClass="social-button social-button-facebook"
        (onClick)="parent.signInWithFB()" [style.pointer-events]="failedReCaptchaV3 ? 'none' : 'all'"></p-button>
      </div>
    </div>
    <p-divider align="center"><span class="text-uppercase">or</span></p-divider>
    <div class="field fluid" [ngStyle]="{'margin-bottom' : !parent.isDesktopDevice ? '0' : '15px'}">
      <small class="login-error mb-1" id="email-error" *ngIf="emailError">{{emailError}}</small>
      <label *ngIf="!parent.isDesktopDevice">Email</label>
      <input id="email" name="email" type="email" pInputText placeholder="Email"
             [disabled]="inProcess || !!loginInfo.socialLogin || failedReCaptchaV3"
             [(ngModel)]="loginInfo.email" autocomplete="off" [required]="true" aria-errormessage="email-error"/>
    </div>
    <div class="field fluid mb-3 text-center">
      <div class="captcha" *ngIf="isReCaptchaErrorV3">
        <small class="login-error mb-1">Google reCaptcha has detected a user with a high risk profile. Please reach out to us at <a href="mailto:amc@adcore.com" target="_blank">amc&#64;adcore.com</a> for further assistance.</small>
      </div>
      <p-button [label]="inProcess ? '': formButton" styleClass="login-btn-secondary" (onClick)="validateEmail()" *ngIf = "!failedReCaptchaV3"
                [disabled]="inProcess || !!loginInfo.socialLogin" [style.pointer-events]="failedReCaptchaV3 ? 'none' : 'all'">
        <div class="loader-box-dots" *ngIf="inProcess">
          <div class="loader">
            <div></div>
          </div>
        </div>
      </p-button>

    </div>
  </ng-container>
  <form autocomplete="off" *ngIf="currentStage === 'stage2'" class="mt-4">
    <ng-container *ngIf="!loginInfo.socialLogin">
      <small class="login-error mb-1" id="fullName-error" *ngIf="fullNameError">{{fullNameError}}</small>
      <div class="flex field fluid mb-3">
        <label *ngIf="!parent.isDesktopDevice">First Name*</label>
        <input id="firstName" name="firstName" type="text" pInputText placeholder="First Name*"
               [(ngModel)]="loginInfo.firstName" autocomplete="off" [ngModelOptions]="{standalone: true}"
               [required]="true" class="mr-3" autofocus
               [disabled]="inProcess"/>
        <label *ngIf="!parent.isDesktopDevice">Last Name*</label>
        <input id="lastName" name="lastName" type="text" pInputText placeholder="Last Name*"
               [(ngModel)]="loginInfo.lastName" autocomplete="off" [ngModelOptions]="{standalone: true}"
               [required]="true" [disabled]="inProcess"/>
      </div>
    </ng-container>
    <div class="field fluid mb-3 phone" [ngClass]="{'invalid-border': phoneError}">
      <small class="login-error mb-1" id="phone-error" *ngIf="phoneError">{{phoneError}}</small>
      <aux-phone #phone [countryCode]="currentCountryCode" (phoneChangeEvent)="phoneError=null"
                 [showExamplePlaceholder]="false" placeholder="Mobile Phone*" [autofocus]="false">
      </aux-phone>
    </div>
    <div class="field fluid mb-3" [ngClass]="{'invalid-border': websiteError}">
      <label *ngIf="!parent.isDesktopDevice">Your company website*</label>
      <small class="login-error mb-1" id="website-error" *ngIf="websiteError">{{websiteError}}</small>
      <input id="websiteUrl" name="websiteUrl" type="text" pInputText placeholder="Your company website*"
             [(ngModel)]="loginInfo.websiteUrl" autocomplete="off" [ngModelOptions]="{standalone: true}"
             [required]="true" [disabled]="inProcess" (keyup)="removeError('website')"/>
    </div>
    <ng-container *ngIf="!loginInfo.socialLogin">
      <div *ngIf="passwordError">
        <small class="login-error" id="password-error">{{passwordError}}</small>
      </div>
      <div [ngClass]="{'new-pwd-container': parent.isDesktopDevice}">
        <div class="field fluid mb-3 new-pwd">
          <label *ngIf="!parent.isDesktopDevice">Enter Password* <aux-info class="center-tip" [content]="passwordRequirement"></aux-info></label>
          <p-password [(ngModel)]="loginInfo.pass1" [feedback]="false"
                      [toggleMask]="true" [required]="true"  [disabled]="inProcess"
                      (ngModelChange)="checkPasswordStrength($event)"
                      [ngClass]="{'invalid-border': showPasswordFeedback}"
                      placeholder="Enter Password*" [ngModelOptions]="{standalone: true}">
          </p-password>
        </div>
        <aux-info *ngIf="parent.isDesktopDevice" class="center-tip" [content]="passwordRequirement"></aux-info>
      </div>
      <ng-container *ngIf="showPasswordFeedback" >
        <aux-strong-pwd-feedback [passwordCriteria]="passwordCriteria" [column]="2"></aux-strong-pwd-feedback>
      </ng-container>
      <div class="field fluid" [ngStyle]="{'margin-bottom' : !parent.isDesktopDevice ? '0' : '15px'}">
        <label *ngIf="!parent.isDesktopDevice">Re-Enter Password*</label>
        <p-password [(ngModel)]="loginInfo.pass2" [feedback]="false"
                    [toggleMask]="true" [required]="true" [disabled]="inProcess"
                    placeholder="Re-Enter Password*" [ngModelOptions]="{standalone: true}"></p-password>
      </div>
    </ng-container>
    <div *ngIf="responseError">
      <small class="login-error" id="response-error1">{{responseError}}</small>
    </div>
    <div class="field fluid mb-3 text-center" *ngIf="!failedReCaptchaV3">
      <p-button [label]="inProcess ? '': 'CONTINUE'" styleClass="login-btn-secondary" (onClick)="join()" [disabled]="inProcess">
        <div class="loader-box-dots" *ngIf="inProcess">
          <div class="loader">
            <div></div>
          </div>
        </div>
      </p-button>
    </div>
  </form>
  <div *ngIf="currentStage === 'stage2-verifyPhone'">
    <div class="field fluid mb-3 mt-4">
      <div class="mb-3">
        <div>We need a valid mobile number to keep your account secure when login. </div>
        <div>A 6-digit code has been sent to {{loginInfo.phoneNumber}}. </div>
        <div>Enter the code below to verify your phone number.</div>
      </div>
      <div class="card flex justify-content-center" [ngClass]="{'invalid-border': codeError, 'green-border': phoneCodeCorrect}">
        <p-inputOtp [(ngModel)]="phoneCode" [ngModelOptions]="{standalone: true}" [integerOnly]="true" [length]="6"
                    class="verify-code" (onChange)="onCodeChange($event, 'phone')"/>
      </div>
      <small class="login-error mb-1" *ngIf="codeError">Invalid verification code</small>
      <small class="login-error green-text mb-1" *ngIf="phoneCodeCorrect">Validation Successful</small>
      <small class="login-error mb-1" *ngIf="responseError">We are meeting some error, please contact Adcore support.</small>
      <div class="verify-links">
        <p-button styleClass="p-button-link" label="Resend verification code" (onClick)="send2faRequest('resend')" [disabled]="inProcess || resendTimes >= 6 || isResendDisabled" [link]="true"></p-button>
        <ng-container>
          <div class="or">OR</div>
          <p-button styleClass="p-button-link" label="Update phone number" (onClick)="changePhone()" [disabled]="inProcess || isResendDisabled" [link]="true"></p-button>
        </ng-container>
        <ng-container *ngIf="isResendDisabled">
          <div class="countdown">({{countdown}} seconds)</div>
        </ng-container>
      </div>
      <p-button *ngIf="!changePhoneStage" [label]="inProcess ? '': 'VERIFY CODE'" styleClass="login-btn-secondary"
                (onClick)="onApplyingPhoneCode()" [disabled]="inProcess">
        <div class="loader-box-dots" *ngIf="inProcess">
          <div class="loader">
            <div></div>
          </div>
        </div>
      </p-button>
      <div class="change-e" *ngIf="changePhoneStage">
        <div class="field fluid mb-3 phone" [ngClass]="{'invalid-border': newPhoneError}">
          <small class="login-error mb-1" id="phone-error-2" *ngIf="newPhoneError">{{newPhoneError}}</small>
          <aux-phone #newPhone [countryCode]="currentCountryCode" (phoneChangeEvent)="newPhoneError=null"
                     [showExamplePlaceholder]="false" placeholder="Mobile Phone*">
          </aux-phone>
        </div>
        <div class="field fluid mb-3 text-center">
          <p-button [label]="inProcess ? '': 'UPDATE PHONE'" styleClass="login-btn-secondary" (onClick)="updatePhone()"
                    [disabled]="inProcess">
            <div class="loader-box-dots" *ngIf="inProcess">
              <div class="loader">
                <div></div>
              </div>
            </div>
          </p-button>
        </div>
      </div>
    </div>
  </div>
  <div class="field fluid mt-2 text-center" *ngIf="currentStage === 'stage3'">
    <div class="card flex justify-content-center" [ngClass]="{'invalid-border': codeError, 'green-border': emailCodeCorrect}">
      <p-inputOtp [(ngModel)]="emailCode" [ngModelOptions]="{standalone: true}" [integerOnly]="true" [length]="6"
                  class="verify-code" (onChange)="onCodeChange($event, 'email')"/>
    </div>
    <small class="login-error mb-1" *ngIf="codeError || responseError">{{responseError}}</small>
    <small class="login-error green-text mb-1" *ngIf="emailCodeCorrect">Validation Successful</small>
    <div class="verify-links">
      <p-button styleClass="p-button-link" label="Resend verification code" (onClick)="reSendVerificationEmail()" [disabled]="inProcess || emailResendTimes >= 5 || isResendDisabled" [link]="true"></p-button>
      <ng-container *ngIf="isResendDisabled"> ({{countdown}} seconds)</ng-container>
      <ng-container *ngIf="!loginInfo.socialLogin">
        <div class="or">OR</div>
        <p-button styleClass="p-button-link" label="Change email address" (onClick)="changeEmailForUserStage()" [disabled]="inProcess || isResendDisabled" [link]="true"></p-button>
      </ng-container>
    </div>

    <div class="change-e" *ngIf="changeEmailStage">
      <div class="field fluid" [ngStyle]="{'margin-bottom' : !parent.isDesktopDevice ? '0' : '15px'}">
        <small class="login-error mb-1" id="new-email-error" *ngIf="emailError">{{emailError}}</small>
        <label *ngIf="!parent.isDesktopDevice">Email</label>
        <input id="newEmail" name="email" type="email" pInputText placeholder="Email"
               [disabled]="inProcess"
               [(ngModel)]="loginInfo.newEmail" autocomplete="off" [required]="true" aria-errormessage="new-email-error"/>
      </div>
      <div class="field fluid mb-3 text-center">
        <p-button [label]="inProcess ? '': 'UPDATE EMAIL'" styleClass="login-btn-secondary" (onClick)="continueChangeEmail()"
                  [disabled]="inProcess">
          <div class="loader-box-dots" *ngIf="inProcess">
            <div class="loader">
              <div></div>
            </div>
          </div>
        </p-button>
      </div>
    </div>

    <div *ngIf="!changeEmailStage" class="field fluid mb-3 text-center">
      <p-button [label]="inProcess ? '': formButton" styleClass="login-btn-secondary" (onClick)="finalizeAutoEmailInvitation()"
                [disabled]="inProcess">
        <div class="loader-box-dots" *ngIf="inProcess">
          <div class="loader">
            <div></div>
          </div>
        </div>
      </p-button>
    </div>
  </div>
  <div class="text-center mt-1 login-explain" *ngIf="formFooter" [ngClass]="{'large-text' : currentStage === 'stage1'}"><small [innerHTML]="formFooter"></small></div>
  <div class="text-center mb-3 user-log" *ngIf="currentStage === 'stage1'">
    <div class="new-user">Already have account?</div>
    <p-button styleClass="p-button-link" label="Log in" (onClick)="logIn()" [link]="true"></p-button>
  </div>
</div>
