import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as helper from '../shared/helper';
import {OverlayPanel} from 'primeng/overlaypanel';
import {style, state, animate, transition, trigger} from '@angular/animations';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({width: 0}),
        animate(500, style({width: '100%'}))
      ]),
      // transition(':leave', [   // :leave is alias to '* => void'
      //   animate(500, style({opacity: 0}))
      // ])
    ])
  ],
  selector: 'aux-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  @ViewChild('op') overlayPanel: OverlayPanel | undefined;
  searchParam: helper.ISearchParam = {searchQuery: ''} as helper.ISearchParam;
  prevSearchQuery = '';
  @Input() placeholder = 'SEARCH';
  @Input() label = 'SEARCH';
  @Input() disabled = false;
  @Input() inline = false;
  @Input() defaultQuery: string | undefined;
  @Input() pageName: helper.EMediaSubType = helper.EMediaSubType.NONE;
  // @Input() styleClass: string | undefined;
  @Output() searchStart: EventEmitter<helper.ISearchParam> = new EventEmitter<helper.ISearchParam>();
  @Output() clearing = new EventEmitter<string>();
  @Output() searchBoxVisible = new EventEmitter<boolean>();
  inProgress = false;
  isSearchVisible = false;

  constructor(private elementRef: ElementRef) {
    this.searchParam.searchQuery = this.defaultQuery || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageName) {
      this.searchParam.searchQuery = '';
    }
  }

  ngOnInit(): void {
  }

  search(): void {
    if (this.searchParam.searchQuery && !this.disabled) {
      this.prevSearchQuery = this.searchParam.searchQuery;
      this.searchStart.emit(this.searchParam);
    }
    if (!this.inline) {
      this.hidePanel();
    }
  }

  clear(): void {
    if (!this.disabled) {
      this.searchParam.searchQuery = '';
      this.clearing.emit(this.prevSearchQuery);
    }
    this.hidePanel();
  }

  toggleSearchBox(event: any, target: any): void {
    this.searchParam.searchQuery = '';
    this.prevSearchQuery = '';
    if (!this.inline) {
      this.toggleOverlayPanel(event, target);
    } else {
      if (!this.disabled) {
        this.isSearchVisible = true;
        this.searchBoxVisible.emit(true);
      }
    }
  }

  toggleOverlayPanel(event: any, target: any): void {
    if (!this.disabled) {
      this.overlayPanel?.toggle(event, target);
    }
  }

  private hidePanel(): void {
    if (this.overlayPanel && !this.inline) {
      this.overlayPanel.hide();
    }
    if (this.inline && this.isSearchVisible) {
      this.isSearchVisible = false;
      this.searchBoxVisible.emit(false);
    }
  }

  get componentTemplate(): any {
    return this.elementRef?.nativeElement;
  }
}
