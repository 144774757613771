<div class="field">
  <label for="advertiserAndKids" class="col-fixed title-row input-title">{{translate('SELECT_ADVERTISER')}}</label>
  <div class="col input-row">
    <p-dropdown id="advertiserAndKids" appendTo="body" [options]="userAdvertiserAndKids" [panelStyleClass]="classStyle"
                [(ngModel)]="selectedAdvertiser" placeholder="None"  [filter]="true" filterBy="label">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedAdvertiser">
          <div>{{selectedAdvertiser.label}}</div>
        </div>
      </ng-template>
      <ng-template let-advertiser pTemplate="item">
        <div>
          <div>{{advertiser.label}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
<div class="field alert" *ngIf="selectedAdvertiser && isGoogleChannel">
  <label>{{translate('GOOGLE_CONNECT_ALERT')}}</label>
</div>
<div>
  <p-button label="Sign in with Google" icon="pi pi-google" styleClass="social-button social-button-google"
            (click)="connect()" *ngIf="isGoogleChannel && selectedAdvertiser; else bingChannel"></p-button>
  <ng-template #bingChannel>
    <p-button label="Sign in with Bing" icon="pi pi-bing" styleClass="social-button social-button-bing"
              (click)="connect()" *ngIf="selectedAdvertiser"></p-button>
  </ng-template>
  <p-button [label]="translate('CANCEL')" styleClass="btn-secondary-link" (onClick)="close()"></p-button>
</div>

