import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import { Observable, of } from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {MediaService} from './media.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class ProfileGuardService  {
  constructor(private authService: AuthenticationService, private router: Router, private mediaService: MediaService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.routeConfig?.path;
    const fpid = route.queryParams?.fpid;
    const invitationToken = route.queryParams?.uit || null; // || route.queryParams?.auit || null;
    if (path === 'mypass' && fpid) {
      return this.authService.validateForgotPasswordToken(fpid).pipe(map(valid => {
        if (!valid) {
          this.router.navigate(['/login']);
          return false;
        }
        return valid;
      }));
    }
    if (path === 'join' && invitationToken) {
      return this.authService.validateInvitationToken(invitationToken).pipe(map(valid => {
        if (!valid) {
          this.router.navigate(['/login']);
          return false;
        }
        return valid;
      }));
      // return ret;
    }

    return true;
  }
}

export const ProfileGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(ProfileGuardService).canActivate(next, state);
};
