import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiCallService} from '../../helpers/api-call.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as utils from '../../helpers/utils';
@Component({
  selector: 'app-ignore',
  templateUrl: './ignore.component.html',
  styleUrls: ['./ignore.component.scss']
})
export class IgnoreComponent implements OnInit, OnDestroy{
  componentDestroyed: Subject<boolean> = new Subject();
  token = '';
  errMsg: string | null = null;
  ignoreOption = 'week';
  initOption: any = null;
  params: any;
  disabled = false;
  showButton = true;
  loading = true;
  constructor(private router: Router, private route: ActivatedRoute, private apiCall: ApiCallService) {}
  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.componentDestroyed)).subscribe(params => {
      if (params.token) {
        this.token = params.token;
        try {
          const decodedToken = JSON.parse(atob(this.token));
          if (decodedToken.ruleId && decodedToken.advertiser_id && decodedToken.timeStamp && decodedToken.uuid) {
            // check if decodedToken.timeStamp valid in a week
            const diff = utils.getUTCTimestampInSeconds() - decodedToken.timeStamp;
            if (diff > 0 && diff > 60 * 60 * 24 * 7) {
              this.errMsg = 'Sorry, the session was expired.';
            } else {
              this.params = JSON.parse(atob(this.token));
              this.disabled = true;
              this.apiCall.getAlerterIgnoreOption(this.params).pipe().subscribe((response) => {
                if (response.error) {
                  this.errMsg = response.error;
                } else {
                  if (response.ignoreOption) {
                    this.ignoreOption = response.ignoreOption;
                    this.initOption = response.ignoreOption;
                    if (response.ignoreOption === 'week' && !response.ignoreDueTime) {
                      this.initOption = null;
                      this.disabled = false;
                    }
                    this.loading = false;
                  } else {
                    this.disabled = false;
                    this.loading = false;
                  }
                }
              });
            }
          }else {
            this.errMsg = 'Sorry, parameters error.';
          }
        } catch (e) {
          this.errMsg = 'Sorry, meet some errors.';
          console.error(e);
        }
      } else {
        this.errMsg = 'No valid token.';
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  onChange(event: any): void {
    if (event.value !== this.ignoreOption) {
      this.ignoreOption = event.value;
      this.showButton = true;
      this.disabled = this.initOption === event.value;
    }
  }

  ignore(): void {
    this.disabled = true;
    this.params.option = this.ignoreOption;
    this.initOption = this.ignoreOption;
    if (this.ignoreOption === 'never') {
      this.params.importance = 'alert';
    } else {
      this.params.importance = 'normal';
    }
    this.apiCall.alerterIgnore(this.params).toPromise();
    this.showButton = false;
  }
}
