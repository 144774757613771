import {Injectable, OnDestroy} from '@angular/core';
import {TranslationService} from './translation.service';
import {MediaService} from './media.service';
import {ApiCallService} from './api-call.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ConnectAccountComponent} from '../shared/dialogs/connect-account/connect-account.component';
import {SelectAccountComponent} from '../shared/dialogs/select-account/select-account.component';
import {WindowRefService} from 'adcore-auxiliary';
import {environment} from '../../environments/environment';
import * as aux from 'adcore-auxiliary';
import * as models from './models';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import * as cfg from './config';

@Injectable({
  providedIn: 'root'
})
export class MerchantService implements OnDestroy{
  componentDestroyed: Subject<boolean> = new Subject();
  // options: models.IOptions | undefined;
  loading = false;
  credentialId = '';
  reauthorized = false;
  mediaId = '';
  cb: any;
  constructor(public translationService: TranslationService, public dialogService: DialogService, public mediaService: MediaService,
              public windowRefService: WindowRefService, public apiCallService: ApiCallService) { }

  get selectedAdvertiser(): aux.IMediaRow | null {
    return this.mediaService?.currentAdvertiser;
  }

  get selectedAdvertiserId(): string {
    return this.selectedAdvertiser?.key || '';
  }
  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }
  showConnect(cb: any): void {
    this.reauthorized = false;
    this.cb = cb;
    if (this.selectedAdvertiser?.value.type !== 'client') {
      const ref = this.dialogService.open(ConnectAccountComponent, {
        header: this.translationService.translate('ADD_MERCHANT_ACCOUNT'), // 'Add merchant account'
        closable: true,
        modal: true,
        data: {connect: this.connect.bind(this), connectAlert: 'GOOGLE_CONNECT_ALERT', channel: 'google'},
        styleClass: 'pink-palette'
      });
    } else {
      this.connect();
    }
  }

  connect(reAuth?: any, cb?: any): void {
    if (reAuth === true) {
      this.reauthorized = true;
      this.cb = cb;
    }
    const wndParams = `scrollbars=yes,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,top=50,left=200`;
    const url = `${environment.apiUrl}/public/google/merchants/authenticate/?advertiserId=${this.selectedAdvertiserId}&reAuth=${this.reauthorized}`;
    const newWindow = this.windowRefService.nativeWindow.open(url, 'Authenticate', wndParams);
    this.windowRefService.nativeWindow.addEventListener('message', this.receiveMessage.bind(this), {once: true}, false);
  }

  receiveMessage(event: any): any {
    if (event.origin === `${environment.apiUrl}` && event.data === 'succeed'){
      this.selectMerchants();
    } else {
      return;
    }
  }

  async selectMerchants(): Promise<void> {
    this.loading = true;
    const res = await this.apiCallService.pyGettGenericShort({
      module_fn: 'ns_google_merchant.google_merchant.google_get_accounts',
      advertiserId: this.selectedAdvertiserId,
    }).toPromise();
    if (res.message !== 'error' && res.message !== 'No new credential' && res.accounts?.length > 0) {
      this.credentialId = res.credentialId;
      const ref = this.dialogService.open(SelectAccountComponent, {
        header: this.translationService.translate('SELECT_ACCOUNT_ACCOUNTS'),
        closable: true,
        modal: true,
        data: { accounts: res.accounts},
        // data: { accounts: res.accounts, getAccounts: this.onSelectAccounts.bind(this) },
        styleClass: 'pink-palette select-acc-dialog items-length'
      });
      ref.onClose.pipe(takeUntil(this.componentDestroyed)).subscribe((result) => {
        if (result) {
          this.onSelectAccounts(result.selectedAccounts, result.importAll);
        }
      });
    }
    this.loading = false;
  }

  async onSelectAccounts(args: any, importAll: boolean): Promise<void> {
    this.loading = true;
    const accountList: any[] = [];
    args.forEach((a: any) => accountList.push( a.id ));

    this.apiCallService.pyGettGenericShort({
      module_fn: 'ns_google_merchant.google_merchant.google_import',
      credentialId: this.credentialId,
      syncMode: importAll ? 'full' : 'manual',
      accountList
    }).pipe(takeUntil(this.componentDestroyed)).subscribe(response => {
      // console.log('response onSelectAccounts', response);
      if (response.message !== 'error' ) {
        if (this.cb && this.cb.name === 'bound loadNodes') {
          this.cb({first: 0});
        } else {
          this.cb();
        }
      }
    });
    // const response = await this.apiCallService.pyGettGenericShort(this.options).toPromise();
    // console.log('response onSelectAccounts', response);
    // response.then(this.cb());
  }

  get getLanguages(): Observable<any> {
    const options = {
      module_fn: 'ns_google_merchant.google_merchant.get_languages',
      advertiserId: this.selectedAdvertiserId,
    };
    return this.apiCallService.pyGettGenericShort(options);
  }

  get getCountries(): Observable<any> {
    const options = {
      module_fn: 'ns_google_merchant.google_merchant.get_countries',
      advertiserId: this.selectedAdvertiserId,
    };
    return this.apiCallService.pyGettGenericShort(options);
  }

  feedColumnsList(): any[] {
    return this.mediaService?.feeditorMergedColumns?.filter((mc: {
        hasOwnProperty: (arg0: string) => any;
        columnIsVisible: any; columnName: string;
      }) => (!mc.hasOwnProperty('columnIsVisible') || mc.columnIsVisible)
        && mc.columnName !== '_id'
    ) || [];
  }

  async getAccounts(event: any): Promise<any> {
    const options: models.IOptions = {
      module_fn: 'mongo.dbsystem.media.media_collection.get_accounts',
      advertiser_id: this.selectedAdvertiserId,
      search: !!event ? event.query : '',
      pagination: {skip: 0, page_size: cfg.MAX_PAGE_SIZE},
      channel: 'google',
      flag: 'merchant'
    };
    const response = await this.apiCallService?.pyGettGenericShort(options).toPromise();
    const result: models.IMediaResult = (response || {rows: []}) as models.IMediaResult;
    return result?.rows?.filter(r => r.subType !== aux.EMediaSubType.MICROSOFT &&
      (r.type === aux.EMediaType.MCA || r.type === aux.EMediaType.MERCHANT)).map((r: { label: any; }) => {
      r.label = this.needToEncrypt ? `Demo account` : r.label;
      return r;
    });
  }

  get AccountsCount(): Observable<any> {
    const options = {
      module_fn: 'mongo.dbsystem.media.media_collection.count_merchant_accounts',
      advertiser_id: this.selectedAdvertiserId,
    };
    return this.apiCallService.pyGettGenericShort(options);
  }
  async getAccountsCount(): Promise<any> {
    const options: models.IOptions = {
      module_fn: 'mongo.dbsystem.media.media_collection.count_merchant_accounts',
      advertiser_id: this.selectedAdvertiserId,
    };
    return this.apiCallService?.pyGettGenericShort(options).toPromise();
  }

  get needToEncrypt(): boolean {
    return this.selectedAdvertiserId === cfg.DEMO_ADVERTISER_ID;
  }

  selectAccount(accountMediaId?: string): Observable<any> {
    const options: models.IOptions = {
      module_fn: 'mongo.dbsystem.media.media_collection.get_first_account',
      advertiser_id: this.selectedAdvertiserId,
      channel: 'google',
      flag: 'merchant',
      account_media_id: accountMediaId
    };
    return this.apiCallService?.pyGettGenericShort(options);
  }

  multipleRequests(requests: Observable<any>[]): Observable<any[]> {
    return this.apiCallService.multipleRequests(requests);
  }
}
