<h4>
  {{translate('PASSWORD')}}
</h4>
<p-panel #pp [(collapsed)]="panelCollapsed" [toggleable]="true" (onAfterToggle)="onAfterToggle($event)">
  <ng-template pTemplate="header">
    <p-button [label]="translate('CHANGE_PASSWORD')" (onClick)="changeState($event)"
              styleClass="p-button-link mr-2" *ngIf="!notChangeable" [disabled]="disabled" [link]="true"></p-button>
    <p-button [label]="translate('RESET_PASSWORD')" styleClass="p-button-link ml-2" *ngIf="!notResettable"
              [disabled]="disabled" (onClick)="resetPassword($event)" [link]="true"></p-button>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-nowrap">
      <div class="mr-2 flex-2" *ngIf="showCurrentPwd">
        <h5> {{translate('CURRENT_PASSWORD')}}</h5>
        <p-password [(ngModel)]="pass0" [toggleMask]="true" [placeholder]="translate('CURRENT_PASSWORD')"
                    [feedback]="false"></p-password>
        <div *ngIf="pwdError === 'INCORRECT_PWD'" class="pwd-error">
          <small>{{translate(pwdError)}}</small>
        </div>
      </div>
      <div class="flex-2">
        <h5> {{translate('NEW_PASSWORD')}}<aux-info class="center-tip" [content]="translate('STRONG_PWD_REQUIREMENTS')"></aux-info></h5>
        <p-password [(ngModel)]="pass1" [toggleMask]="true" [placeholder]="translate('NEW_PASSWORD')"
                    [feedback]="false" (ngModelChange)="checkPasswordStrength($event)"></p-password>
        <div *ngIf="showPasswordFeedback">
          <aux-strong-pwd-feedback [passwordCriteria]="passwordCriteria" [column]="1"></aux-strong-pwd-feedback>
        </div>
      </div>
      <div class="ml-2 flex-2">
        <h5> {{translate('REPEAT_PASSWORD_PLACEHOLDER')}}</h5>
        <p-password [(ngModel)]="pass2" [feedback]="false" [toggleMask]="true" [placeholder]="translate('REPEAT_PASSWORD_PLACEHOLDER')"></p-password>
        <div *ngIf="pwdError === 'PWD_NOT_MATCH'" class="pwd-error">
          <small>{{translate(pwdError)}}</small>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div *ngIf="pwdError && pwdError!=='PWD_NOT_MATCH' && pwdError!=='INCORRECT_PWD'" class="pwd-error">
      <small>{{translate(pwdError)}}</small>
    </div>
    <p-button [label]="translate('set')" styleClass="btn-primary" (onClick)="changePassword($event)"
    [disabled]="saving"></p-button>
    <p-button [label]="translate('cancel')" styleClass="btn-secondary-link" (onClick)="pp.collapse()"></p-button>
  </ng-template>
</p-panel>
