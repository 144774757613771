import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value?: null, format?: string, timezone?: string, locale?: string): null;
  transform(value?: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value?: any,
            format?: string,
            timezone?: string,
            locale?: string): string | null {
    // Check if the value is a number and assume it's a timestamp
    if (typeof value === 'number') {
      // If the timestamp has 10 digits, assume it's in seconds and convert to milliseconds
      // This is a simple heuristic and might need adjustments for specific use cases
      const isTimestampInSeconds = value.toString().length === 10;
      const date: any = new Date(isTimestampInSeconds ? value * 1000 : value);
      // Call the parent class's transform method with the Date object
      return super.transform(date, format, timezone, locale);
    }
    // If value is not a number, pass it directly to the parent class's transform method
    return super.transform(value, format, timezone, locale);
  }
}
