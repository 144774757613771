import {Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, DoCheck} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import * as helper from '../shared/helper';
import {MenuItem, PrimeIcons} from 'primeng/api';
@Component({
  selector: 'aux-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, DoCheck {
  @ViewChild('op') overlayPanel: OverlayPanel | undefined;
  @Input() disabled = false;
  @Input() helpItems: helper.IHelpItem[] = [];
  @Input() translateService: any | undefined;
  @Output() queryingHelp: EventEmitter<string | null> = new EventEmitter<string | null>(false);
  @Output() callCommand: EventEmitter<void> = new EventEmitter<void>();
  @Input() placeholder: string | undefined;
  @Input() classStyle = '';
  searchQuery: string | null = null;
  menuItems: MenuItem[] = [];
  constructor(private elementRef: ElementRef) { }


  search(): void {
    if (!this.disabled && this.searchQuery) {
      this.queryingHelp.emit(this.searchQuery);
    }
  }
  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }
  private callExt(): void {
    this.callCommand.emit();
  }
  private menuList(): MenuItem[] {
    const menuItems: MenuItem[] = [];
    if (this.helpItems?.length) {
      this.helpItems.forEach((h: helper.IHelpItem) => {
        if (h.active) {
          menuItems.push({
            id: h._id || undefined,
            label: (h.key) ? this.translate(h.key) : h.label,
            url: h.url,
            target: '_blank',
            command: this.callExt.bind(this),
            icon: PrimeIcons.QUESTION
          });
        }
      });
    }
    return menuItems;
  }
  ngOnInit(): void {
  }

  toggleOverlayPanel(event: any, target: any): void {
    // console.log({event, target});
    if (!this.disabled) {
      // console.log({hi: this.helpItems});
      this.menuItems = this.menuList();
      this.overlayPanel?.toggle(event, target);
    }
  }
  get componentTemplate(): any {
    return this.elementRef?.nativeElement;
  }
  save(): void {}

  ngDoCheck(): void {
  }
}
