import {Component, Input, OnInit} from '@angular/core';
import {DialogBaseComponent} from '../dialog-base.component';

@Component({
  selector: 'app-connect-account',
  templateUrl: './connect-account.component.html',
  styleUrls: ['./connect-account.component.scss']
})
export class ConnectAccountComponent extends DialogBaseComponent implements OnInit {
  @Input() classStyle = '';
  userAdvertiserAndKids: any[] = [];
  selectedAdvertiser: any = null;
  loading = false;
  connectAlert = '';
  isGoogleChannel = true;

  ngOnInit(): void {
    this.mediaService?.userAdvertiserAndKids
      .forEach((ua) => this.userAdvertiserAndKids.push({label: ua.label, data: ua.value }));
    this.connectAlert = this.dialogConfig?.data.connectAlert || '';
    this.isGoogleChannel = this.dialogConfig?.data.channel === 'google';
    if (this.mediaService?.selectedAdvertiser?.type === 'client' && this.userAdvertiserAndKids.length === 1) {
      this.selectedAdvertiser = this.userAdvertiserAndKids[0];
    }
  }
  change(event: any): void {
  }
  close(): void {
    this.cancel();
  }
  connect(): void {
    this.close();
    this.dialogConfig?.data.connect(this.selectedAdvertiser.data._id);
  }
}

