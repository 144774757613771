<table>
  <tr>
    <ng-container *ngFor="let _ of codeParams; index as i; last as isLast; trackBy: trackByFn">
      <td>
        <input [(ngModel)]="codeParams[i]" id="{{idPrefixForInputFields + i.toString()}}"
               class="{{classPrefixForInputFields + i.toString()}}"
               type="text"
               placeholder="0"
               maxlength="1"
               inputmode="numeric"
               pattern="[0-9]*"
               autocomplete="off"
               digitOnly
               pInputText
               (keyup)="onKeyUp($event, i, isLast)"
               [disabled]="disabled"/>

      </td>
      <td class="seperator-box" *ngIf="!isLast"><span class="seperator"></span></td>
    </ng-container>
  </tr>
</table>
