import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as helper from '../shared/helper';
import * as _ from 'lodash';

@Component({
  selector: 'aux-ftp-details',
  templateUrl: './ftp-details.component.html',
  styleUrls: ['./ftp-details.component.scss']
})
export class FtpDetailsComponent implements OnInit {
  @Input() disabled = false;
  @Input() translateService: any | undefined;
  @Input() channel: string | undefined;
  @Input() ftpDetails: helper.IFtpDetails = {} as helper.IFtpDetails;
  @Output() ftpDetailsChange = new EventEmitter<helper.IFtpDetails>(false);
  ftpTypes = [
    'sftp',
    'ftp'
  ];
  private formErrors: any = null;
  constructor() { }

  ngOnInit(): void {
    if (!this.ftpDetails) {
      throw new Error('ftp details not defined');
    }
  }
  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }
  get ftpHost(): string {
    return this.ftpDetails.host?.trim();
  }

  set ftpHost(value: string) {
    this.ftpDetails.host = value.trim();
    this.ftpDetailsChange.emit(this.ftpDetails);
  }

  get ftpRemotePath(): string {
    return this.ftpDetails.remoteRoot?.trim() || './';
  }

  set ftpRemotePath(value: string) {
    this.ftpDetails.remoteRoot = value.trim();
    this.ftpDetailsChange.emit(this.ftpDetails);
  }

  get ftpProtocol(): string {
    return this.ftpDetails.protocol?.trim() || 'ftp';
  }

  set ftpProtocol(value: string) {
    this.ftpDetails.protocol = value.trim();
    this.ftpDetailsChange.emit(this.ftpDetails);
  }

  get ftpPort(): number {
    return this.ftpDetails.port || 21;
  }

  set ftpPort(value: number) {
    this.ftpDetails.port = value;
    this.ftpDetailsChange.emit(this.ftpDetails);
  }
  getToolTip(key: string, same?: boolean): string {
    if (!same) {
      // console.log(this.toolBarActionKey, ' translations ', ` ng-${this.toolBarActionKey}-${key}`.toUpperCase());
      return this.translate(`ng-tooltip-custom-ftp-${key}`);
    }
    if (same) {
      return this.translate(`ng-merchant-tooltip-${key}`);
    }
    return '';
  }
  cleanErrors(): void {
    this.formErrors = null;
  }

  addError(errKey: string, errMsg: string): void {
    if (!this.formErrors) {
      this.formErrors = {};
    }
    if (!this.formErrors[errKey]) {
      this.formErrors[errKey] = [];
    }
    this.formErrors[errKey].push(errMsg);
  }

  getError(errKey: string): string[] {
    if (this.formErrors) {
      return this.formErrors[errKey] || [];
    }
    return [];
  }

  public validate(): void {
    this.formErrors = null;
    if (_.isEmpty(this.ftpHost)) {
      this.addError('ftpHost', 'REQUIRED');
    }
    if (_.isEmpty(this.ftpRemotePath)) {
      this.addError('ftpRemotePath', 'REQUIRED');
    }
  }

  public get isValid(): boolean {
    return !this.formErrors || Object.keys(this.formErrors).length === 0;
  }
}
