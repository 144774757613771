<div class="inline-flex flex-nowrap align-items-center delimiters-container field" *ngIf="horizontal">
  <div class="field-checkbox" *ngFor="let d of delimiters; trackBy: trackByMethod;">
    <p-radioButton name="delimiters" id="{{d.key}}" [value]="d.key" [label]="d.key"  [(ngModel)]="rbKey" (onClick)="onClick($event)"></p-radioButton>
  </div>
  <div *ngIf="rbKey === 'other' && selectedDelimiter">
      <input pInputText type="text" class="other" [(ngModel)]="selectedDelimiter.value" (change)="onChange($event)"/>
  </div>
</div>
<div class="flex flex-column" *ngIf="!horizontal">
    <div class="inline-flex align-items-center justify-content-start flex-nowrap" *ngFor="let d of delimiters; trackBy: trackByMethod;">
        <p-radioButton name="delimiters" id="{{d.key}}" [value]="d.key" [label]="d.key"  [(ngModel)]="rbKey" (onClick)="onClick($event)"
                       [labelStyleClass]="(d.key === 'xml') ? 'uppercase': 'capitalize'"></p-radioButton>
        <input *ngIf="d.key === 'other' && rbKey === 'other'" pInputText type="text" class="ml-2 other" [(ngModel)]="selectedDelimiter.value" (change)="onChange($event)"/>
    </div>
<!--  TODO might be will add later to help advanced clients, in addition need to support attributes checkbox-->
<!--    <div class="inline-flex align-items-center justify-content-start flex-nowrap" *ngIf="rbKey === 'xml' && selectedDelimiter" style="margin-left: -80px">-->
<!--      <div class="mr-2">{{translate('XML_ITEM_PATH')}}</div>-->
<!--      <input pInputText type="text" class="xml-x-path" [(ngModel)]="selectedDelimiter.entryPath"-->
<!--             (blur)="onChange($event)" [placeholder]="translate('XML_ITEM_PATH_INFO')"/>-->
<!--    </div>-->
</div>
