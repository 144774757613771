import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as helper from '../shared/helper';
import * as _ from 'lodash';

@Component({
  selector: 'aux-upload-merchant',
  templateUrl: './upload-merchant.component.html',
  styleUrls: ['./upload-merchant.component.scss']
})
export class UploadMerchantComponent {
  @Input() translateService: any | undefined;
  @Input() merchantService: any | undefined;
  @Input() classStyle = '';
  @Input() currentAction: helper.IAction | null = null;
  @Input() uploadInfo: helper.IMerchantFtpInfo = {} as helper.IMerchantFtpInfo;
  @Output() uploadInfoChange = new EventEmitter<helper.IMerchantFtpInfo>(false);
  @Output() onConnectNewMerchant = new EventEmitter();
  filteredAccounts = new Array<helper.IMediaRow>();
  private formErrors: any = null;
  languages: any[] = [];
  feedColumnsList: any[] = [];
  countries: any[] = [];
  channels = [{code: 'online', name: 'Online'}, {code: 'local', name: 'Local'}];
  isLoading = false;
  isSearching = false;
  isAccountsExist = false;

  constructor(private cdr: ChangeDetectorRef) {
  }
  get selectedCountryLabel(): string | undefined {
    return this.countries.find(c => c.code === this.targetCountry)?.name;
  }

  get selectedLanguageLabel(): string | undefined {
    return this.languages.find(l => l.code === this.language)?.name;
  }

  get selectedColumnLabel(): string | undefined {
    return this.feedColumnsList.find((c: any) => c.columnName === this.columnId)?.columnDisplay;
  }

  get selectedChannelLabel(): string | undefined {
    return this.channels.find(c => c.code === this.channel)?.name;
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.feedColumnsList = this.merchantService.feedColumnsList();
    const requests = [
      this.merchantService.getLanguages,
      this.merchantService.getCountries,
      this.merchantService.AccountsCount,
      this.merchantService.selectAccount(this.uploadInfo.selectedAccount?.key)
    ];

    this.merchantService.multipleRequests(requests).subscribe((responses: any[]) => {
      this.languages = responses[0].languages;
      this.countries = responses[1].countries;
      this.isAccountsExist = responses[2] > 0;
      this.selectedAccount = responses[3].account;
      this.isLoading = false;
    });
  }
  accountsCount(): void {
    this.merchantService.getAccountsCount().then((res: any) => {
      this.isAccountsExist = res > 0;
    });
  }
  accountSearch(event: any): void {
    this.isSearching = true;
    this.merchantService?.getAccounts(event).then((res: any) => {
      this.filteredAccounts = res;
      this.isSearching = false;
    });
  }
  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }
  connectNewMerchant(): void {
    this.merchantService?.showConnect(this.accountsCount.bind(this));
  }
  getToolTip(key: string): string {
    return this.translate(`ng-aux-upload-merchant-${key}`.toUpperCase());
  }
  get columnId(): string {
    return this.uploadInfo.columnId;
  }
  set columnId(value: string) {
    if (value) {
      this.uploadInfo.columnId = value;
    }
  }
  get targetCountry(): string {
    return this.uploadInfo.targetCountry;
  }
  set targetCountry(value: string) {
    if (value) {
      this.uploadInfo.targetCountry = value.trim();
    }
  }
  get language(): string {
    return this.uploadInfo.language;
  }
  set language(value: string) {
    this.uploadInfo.language = value;
  }
  get channel(): string {
    return this.uploadInfo.channel;
  }
  set channel(value: string) {
    if (!!value) {
      this.uploadInfo.channel = value;
    }
  }
  onSelectAccount(event: any): void {
    this.selectedAccount = event.value;
  }
  get selectedAccount(): any {
    // console.log('selectedAccount', this.uploadInfo.selectedAccount);
    return (this.uploadInfo.selectedAccount && _.isEmpty(this.uploadInfo.selectedAccount) ? null : this.uploadInfo.selectedAccount);
  }
  set selectedAccount(value: any) {
    if (value) {
      // console.log('selectedAccount', {value});
      this.uploadInfo.selectedAccount = value;
    }
  }
  public validate(): void {
    this.formErrors = null;
    if (_.isEmpty(this.uploadInfo?.selectedAccount)) {
      this.addError('selectedAccount', 'REQUIRED');
    }
    if (_.isEmpty(this.uploadInfo?.targetCountry)) {
      this.addError('targetCountry', 'REQUIRED');
    }
    if (_.isEmpty(this.uploadInfo?.language)) {
      this.addError('language', 'REQUIRED');
    }
    if (_.isEmpty(this.uploadInfo?.channel)) {
      this.addError('channel', 'REQUIRED');
    }
    if (_.isEmpty(this.uploadInfo?.columnId)) {
      this.addError('columnId', 'REQUIRED');
    }

  }
  public get isValid(): boolean {
    return !this.formErrors || Object.keys(this.formErrors).length === 0;
  }
  addError(errKey: string, errMsg: string): void {
    if (!this.formErrors) {
      this.formErrors = {};
    }
    if (!this.formErrors[errKey]) {
      this.formErrors[errKey] = [];
    }
    this.formErrors[errKey].push(errMsg);
  }
  getError(errKey: string): string[] {
    if (this.formErrors) {
      return this.formErrors[errKey] || [];
    }
    return [];
  }

  getClasses(key: string): string {
    return this.getError(key).length > 0 ? 'required' : '';
  }
}
