<!--{{listItem | json}}-->
<div class="img-box" (click)="openVideoPlayer($event)" [ngClass]="{'curser': listItem && !previewLoading}">
  <p-skeleton width="100%" height="100%" *ngIf="previewLoading"></p-skeleton>
  <img [src]="previewLink" [hidden]="previewLoading" (load)="onLoadPreview()">
  <div class="play-button" *ngIf="listItem && !previewLoading"></div>
</div>
<div class="video-content" *ngIf="showDetails && listItem">
  <h5>{{videoTitle}}</h5>
  <div class="inline-block content">
    <span class="adcore-icon"></span><span>Adcore <span class="dot"></span></span> {{publishDate}}
  </div>
</div>