<div class="field bold-field-text" *ngIf="showTitle">
  <label class="col-fixed title-row"></label>
  <div class="col input-row">
    <label for="advanced" class="col input-row">{{translate('SCHEDULE')}}</label>
  </div>
</div>
<div class="field align-fix">
  <label for="repeat" class="col-fixed title-row">{{translate("UPDATE_FREQUENCY")}}</label>
  <div class="col input-row with-info" [ngClass]="classStyle">
    <p-dropdown id="repeat" [options]="filteredRepeaterList" [panelStyleClass]="classStyle" dataKey="repeater"
                [ngModel]="selectedScheduleRepeater" [ngModelOptions]="{standalone: true}" (ngModelChange)="onRepeaterChange($event)"
                optionDisabled="inactive" optionValue="repeater"  scrollHeight="300px" appendTo="body"
                [autoDisplayFirst]="false">
      <ng-template pTemplate="selectedItem">
        {{repeaterLabel}}
      </ng-template>
    </p-dropdown>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('repeat')"></aux-info>
  </div>
</div>
<div [ngSwitch]="selectedScheduleRepeater.toString()" [ngClass]="{'grid':this.schedule?.repeater !== 'NEVER'}">
  <label *ngIf="showLabel" class="col-fixed title-row every"><label for="every">{{translate("EVERY")}}</label></label>
  <div class="col fluid" [ngClass]="{'no-padding':this.schedule?.repeater === 'NEVER'}">
    <aux-one-time *ngSwitchCase="'ONE_TIME'"></aux-one-time>
    <aux-minutely *ngSwitchCase="'MINUTELY'"></aux-minutely>
    <aux-hourly *ngSwitchCase="'HOURLY'" [classStyle]="classStyle"></aux-hourly>
    <aux-daily *ngSwitchCase="'DAILY'"></aux-daily>
    <aux-weekly *ngSwitchCase="'WEEKLY'" [classStyle]="classStyle"></aux-weekly>
    <aux-monthly *ngSwitchCase="'MONTHLY'"></aux-monthly>
  </div>
</div>
