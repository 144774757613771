<ng-template #profile>
  <div class="profile-panel">
    <div class="grid justify-content-center">
      <div class="panel-avatar-box">
        <p-avatar [image]="data.pictureURL" size="xlarge" shape="circle"></p-avatar>
      </div>
    </div>
    <div class="details">
      <div class="grid justify-content-center mt-2">
        <div class="text-center name">{{data.fullName}}</div>
      </div>
      <div class="grid justify-content-center mt-1">
        <div class="text-center user-name">{{data.userName}}</div>
      </div>
      <div class="grid justify-content-between">
        <div class="ml-2"><p-button (onClick)="goToMyProfile()" styleClass="p-button-link" [label]="data.profileLabel" [link]="true"></p-button></div>
        <div class="mr-2"><p-button (onClick)="goToPrivacy()" styleClass="p-button-link"  [label]="data.privacyPolicyLabel" [link]="true"></p-button></div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="grid justify-content-end mt-4">
      <div><p-button (onClick)="goToSignOut()" class="sign-out" styleClass="p-button-link" [label]="data.signOutLabel" [link]="true"></p-button></div>
    </div>
  </div>
</ng-template>
<div class="panel-avatar-box small" *ngIf="isShowProfileIcon" #actualTarget (click)="toggleOverlayPanel($event, actualTarget)">
  <p-avatar [image]="data.pictureURL" shape="circle"  ></p-avatar>
</div>
<p-overlayPanel #op  styleClass="no-arrow-panel" [appendTo]="componentTemplate">
  <ng-template pTemplate>
    <ng-template *ngTemplateOutlet="profile"></ng-template>
  </ng-template>
</p-overlayPanel>
<ng-container *ngIf="!isShowProfileIcon">
  <ng-template *ngTemplateOutlet="profile"></ng-template>
</ng-container>

