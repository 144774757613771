<div class="m-4">
  <div class="field grid">
    <label for="feedName" class="col-fixed" style="width:100px">Feed Name</label>
    <div class="col">
      <input id="feedName" [ngModel]="feedName" type="text" class="w-5" pInputText [disabled]="true"/>
    </div>
  </div>
  <div class="field grid">
    <label for="feedUrl" class="col-fixed" style="width:100px">Feed URL</label>
    <div class="col">
      <input id="feedUrl" [(ngModel)]="feedUrl" type="text" class="w-5" pInputText/>
    </div>
  </div>
  <div class="field grid">
    <label for="type" class="col-fixed" style="width:100px">Audit type</label>
    <div class="col">
      <p-checkbox [(ngModel)]="auditTypeChanged" [binary]="true"  id="auditType" inputId="auditType"
                  [label]="auditTypeChanged ? 'Free Feeditor' : 'Free Feeditor Plus'"></p-checkbox>
    </div>
  </div>
  <div class="field grid">
    <label for="feedEmails1" class="col-fixed" style="width:100px">Emails</label>
    <div class="col">
       <p-chips id="feedEmails1" [(ngModel)]="feedEmails" [allowDuplicate]="false" [max]="5"  class="w-full"></p-chips>
    </div>
  </div>
  <p-button label="Submit" (onClick)="submitForReview()" [disabled]="!feedEmails.length || !feedUrl"></p-button>
</div>
<div class="m-4">
  <div class="field grid">
    <label for="feedId" class="col-fixed" style="width:100px">Feed ID</label>
    <div class="col">
      <input id="feedId" [(ngModel)]="feedId" type="text" class="w-3" pInputText/>
    </div>
  </div>
  <div class="field grid">
    <label for="type1" class="col-fixed" style="width:100px">Audit type</label>
    <div class="col">
      <p-checkbox [(ngModel)]="auditTypeChanged" [binary]="true" id="auditType1" inputId="auditType1"
                  [label]="auditTypeChanged ? 'Free Feeditor' : 'Free Feeditor Plus'"></p-checkbox>
    </div>
  </div>
  <div class="field grid">
    <label for="feedEmails2" class="col-fixed" style="width:100px">Emails</label>
    <div class="col">
      <p-chips id="feedEmails2" [(ngModel)]="feedEmails" [allowDuplicate]="false" [max]="5"  class="w-full"></p-chips>
    </div>
  </div>
  <p-button label="Submit" (onClick)="submitForReview(true)" [disabled]="!feedEmails.length || !feedId"></p-button>
</div>

