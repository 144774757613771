import {Component, Inject, Input, OnInit} from '@angular/core';
import {SchedulerComponent} from '../scheduler.component';
import * as helper from '../../shared/helper';

@Component({
  selector: 'aux-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.scss']
})
export class WeeklyComponent implements OnInit {
  @Input() classStyle = '';
  param1: number | undefined;
  param2: string[] = [];
  days: any[] = [];
  constructor(@Inject(SchedulerComponent) public parent: SchedulerComponent) { }
  ngOnInit(): void {
    this.days =  [{
      label: this.parent.translate('SUNDAY'),
      value: 'SUNDAY'
    }, {
      label: this.parent.translate('MONDAY'),
      value: 'MONDAY'
    }, {
      label: this.parent.translate('TUESDAY'),
      value:  'TUESDAY'
    }, {
      label: this.parent.translate('WEDNESDAY'),
      value: 'WEDNESDAY'
    }, {
      label: this.parent.translate('THURSDAY'),
      value: 'THURSDAY'
    }, {
      label: this.parent.translate('FRIDAY'),
      value:  'FRIDAY'
    }, {
      label: this.parent.translate('SATURDAY'),
      value: 'SATURDAY'
    }];
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l > 1) {
      this.param1 =  helper.safeListAt(this.parent.schedule?.repeaterOptions, 0, 1);
      this.param2 =  helper.safeListFromIndex(this.parent.schedule?.repeaterOptions, 0,  ['MONDAY']);
    }else {
      this.param1 = 1;
      this.param2 = ['MONDAY'];
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param1,  ...this.param2]);
    }
  }

  onInputParamChanged(event: any): void {
    if (event.value && this.param2) {
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [event.value, ...this.param2]);
    }
  }

  onComboMultiChange(event: any): void {
    if (event.value && this.param1) {
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param1, ...event.value]);
    }
  }

  get suffix(): string {
    return ` ${this.parent.translate('weeks')}`.toLowerCase();
  }
}
