const CURRENCIES_MAP: any = {
  ALL: 'L',
  AFN: '؋',
  ARS: '$',
  AWG: 'ƒ',
  AUD: '$',
  AZN: '₼',
  BSD: '$',
  BBD: '$',
  BYR: 'p.',
  BZD: 'BZ$',
  BMD: '$',
  BOB: 'Bs.',
  BAM: 'KM',
  BWP: 'P',
  BGN: 'лв',
  BRL: 'R$',
  BND: '$',
  KHR: '៛',
  CAD: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: 'COP',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  XCD: '$',
  EGP: '£',
  SVC: '$',
  EEK: 'kr',
  EUR: '€',
  FKP: '£',
  FJD: '$',
  GHC: '₵',
  GIP: '£',
  GTQ: 'Q',
  GGP: '£',
  GYD: '$',
  HNL: 'L',
  HKD: '$',
  HUF: 'Ft',
  ISK: 'kr',
  INR: '₹',
  IDR: 'Rp',
  IRR: '﷼',
  IMP: '£',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  JEP: '£',
  KES: 'KSh',
  KZT: 'лв',
  KPW: '₩',
  KRW: '₩',
  KGS: 'лв',
  LAK: '₭',
  LVL: 'Ls',
  LBP: '£',
  LRD: '$',
  LTL: 'Lt',
  MKD: 'ден',
  MYR: 'RM',
  MUR: '₨',
  MXN: '$',
  MNT: '₮',
  MZN: 'MT',
  NAD: '$',
  NPR: '₨',
  ANG: 'ƒ',
  NZD: '$',
  NIO: 'C$',
  NGN: '₦',
  NOK: 'kr',
  OMR: '﷼',
  PKR: '₨',
  PAB: 'B/.',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QAR: '﷼',
  RON: 'lei',
  RUB: '₽',
  RMB: '￥',
  SHP: '£',
  SAR: '﷼',
  RSD: 'Дин.',
  SCR: '₨',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  ZAR: 'R',
  LKR: '₨',
  SEK: 'kr',
  CHF: 'CHF',
  SRD: '$',
  SYP: '£',
  TZS: 'TSh',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: '₺',
  TRL: '₤',
  TVD: '$',
  UGX: 'USh',
  UAH: '₴',
  GBP: '£',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  YER: '﷼',
  ZWD: 'Z$'
};

const numberWithCommas = (x: any): string => {
  if (x === undefined || x === null) { return ''; }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};


export const formatMoney = (currency: string, num: any): string => {
  currency = currency.toUpperCase();
  const result  = numberWithCommas(num);
  if (result === '') { return ''; }
  if (CURRENCIES_MAP[currency]) {
    return  CURRENCIES_MAP[currency] + result;
  }
  return result;
};

export const prependMoney = (currency?: string, value?: string): string => {
  const c: string = currency || '';
  const num: string = value || '';
  return (c && CURRENCIES_MAP[c]) ? `${CURRENCIES_MAP[c]}${num}` : num;
};
