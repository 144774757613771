<div class="field">
  <p-dropdown [options]="modes" [(ngModel)]="selectedMode" optionLabel="name"  appendTo="body" [panelStyleClass]="classStyle"
              [hidden]="selectOnly"></p-dropdown>
</div>
<!--{{selectedMode | json}}-->
<div class="field" *ngIf="selectedMode.code == 1">
  <p-listbox [options]="accounts" [(ngModel)]="selectedAccounts" [metaKeySelection]="false" [checkbox]="true" [styleClass]="classStyle"
             optionDisabled="imported"
             [filter]="true" [multiple]="true" optionLabel="name" [listStyle]="{'max-height':'250px'}" [style]="{'width':'15rem'}">
    <ng-template let-account pTemplate="item">
      <div class="country-item">
        <div>{{account.name}}</div>
      </div>
    </ng-template>
  </p-listbox>
</div>
<div class="main-form-action-btn">
    <p-button [label]="translate('IMPORT')" styleClass="btn-primary" (onClick)="import()"
              [disabled]="isImportDisabled"></p-button>
    <p-button [label]="translate('CANCEL')" styleClass="btn-secondary-link" (onClick)="close()"></p-button>
</div>
