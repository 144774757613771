import {AfterViewInit, Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {TranslationService} from '../helpers/translation.service';
import {MediaService} from '../helpers/media.service';
import {ApiCallService} from '../helpers/api-call.service';
import {lastValueFrom, Subject} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import * as models from '../helpers/models';
import * as cfg from '../helpers/config';
import {takeUntil} from 'rxjs/operators';
import * as utils from '../helpers/utils';

@Component({
  selector: 'app-mobile-my-cloud',
  templateUrl: './mobile-my-cloud.component.html',
  styleUrls: ['./mobile-my-cloud.component.scss']
})
export class MobileMyCloudComponent implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  importFeedForm = false;
  importFeedFormSent = false;
  importFeedFormSentError = false;
  gotIt = false;
  loadingList = false;
  feedData: any = {};
  listItems: {
    col1: models.IMyCloudListItem | null,
    col2: models.IMyCloudListItem | null
  }[] = [];
  private skip = 0;
  private limit = 4;
  loading = false;
  importing = false;
  feedUrlError= false;

  constructor(private translationService: TranslationService,
              private mediaService: MediaService,
              private apiCallService: ApiCallService,
              private deviceService: DeviceDetectorService,
              private router: Router) {
  }

  get trialStyleClass(): string {
    if (this.isEndSoon) {
      return 'trial trial-end-soon';
    }
    if (this.isStartedInDays) {
      return 'trial trial-started';
    }
    if (this.isEnded) {
      return 'trial trial-ended';
    }
    return 'trial';
  }

  get trialText(): string {
    if (this.isStartedInDays || this.isEndSoon) {
      return this.endSoonText;
    }
    if (this.isEnded) {
      return this.endedText;
    }
    return this.isTrialEndSoonText;
  }

  get endSoonText(): string {
    return this.translate(this.isTrial ? this.isTrialEndSoonText : 'YOUR_SUBSCRIPTION_ENDS_SOON').toUpperCase();
  }

  get endedText(): string {
    return this.translate(this.isTrial ? 'YOUR_FREE_TRIAL_ENDED' : 'YOUR_SUBSCRIPTION_ENDED').toUpperCase();
  }

  get isStartedInDays(): boolean {
    return this.mediaService.daysPlanSubscriptionEnd > 0 && (cfg.TRIAL_DAYS_LIMIT - this.mediaService.daysPlanSubscriptionEnd) <= 7;
  }

  get isEndSoon(): boolean {
    return this.mediaService.daysPlanSubscriptionEnd > 0 && this.mediaService.daysPlanSubscriptionEnd <= 7;
  }

  get isAllPlan(): boolean {
    return this.mediaService.isAllPlanSelected;
  }

  private get isTrialEndSoonText(): string {
    if (this.mediaService.daysPlanSubscriptionEnd <= 7) {
      return 'YOUR_TRIAL_ENDS_SOON';
    }
    return 'YOU_ARE_IN_TRIAL_PERIOD';
  }

  get isEnded(): boolean {
    return this.mediaService.isPlanSubscriptionEnded;
  }

  get isTrial(): boolean {
    return this.mediaService.isPlanTrial;
  }

  get daysTillEnd(): number {
    return this.mediaService.daysPlanSubscriptionEnd;
  }

  get progress(): number {
    return 100 - (this.daysTillEnd / cfg.TRIAL_DAYS_LIMIT) * 100;
  }

  get isLicenseOwner(): boolean {
    return this.mediaService.planSettings?.isLicenseOwner === 'yes';
  }

  get isShowTrialPanel(): boolean {
    return this.isLicenseOwner && [models.ESubscriptionType.TRIAL,
      models.ESubscriptionType.CANCELED, models.ESubscriptionType.EXPIRED]
      .includes(this.mediaService.planSettings?.subscriptionType || models.ESubscriptionType.INITIAL)
      && ( !this.importFeedForm || (this.importFeedForm && !this.importFeedFormSent));
  }

  get firstName(): string {
    const name = this.mediaService.currentUser?.name?.split(' ');
    if (name?.length) {
      return name[0];
    }
    return this.mediaService.currentUser?.name || '';
  }

  get welcomeMessage(): string {
    return this.translate('MOBILE_MYCLOUD_WELCOME').replace('[name]', this.firstName);
  }

  translate(key: string): string {
    return this.translationService.translate(key) || '';
  }

  async ngOnInit(): Promise<void> {
    if (this.deviceService.isDesktop()) {
      await this.router.navigate(['/my-cloud']);
      return;
    }
    this.loading = true;
    await this.getTranslations();
    await this.getMyCloudListItems();
    this.loading = false;
    this.feedData.feedEmail = this.mediaService.currentUser?.email || '';
  }


  private async getTranslations(): Promise<void> {
    const language = this.mediaService.currentUser?.language || 'en';
    const requestTranslations$ = this.apiCallService?.getTranslations(language);
    this.translationService.translations = await lastValueFrom(requestTranslations$);
  }

  private async getMyCloudListItems(): Promise<void> {
    this.loadingList = true;
    const options = {
      module_fn: 'mongo.dbsystem.mycloud_lists.my_cloud_lists.get_mycloud_list_items',
      itemType: 'video',
      skip: this.skip,
      limit: this.limit
    };
    const requestLists$ = this.apiCallService.pyGettGenericShort(options);
    const {rows} = await lastValueFrom(requestLists$);
    this.listItems.push(...this.convertToTableData(rows));
    this.loadingList = false;
  }

  private rowData(i: number, rows: any[]): any {
    if (i < rows.length) {
      return rows[i];
    }
    return null;
  }

  private convertToTableData(rows: models.IMyCloudListItem[]): {
    col1: models.IMyCloudListItem | null,
    col2: models.IMyCloudListItem | null
  }[] {
    const tableData: { col1: models.IMyCloudListItem | null, col2: models.IMyCloudListItem | null }[] = [];
    for (let i = 0; i < rows.length; i += 2) {
      const row = {col1: this.rowData(i, rows), col2: this.rowData(i + 1, rows)};
      tableData.push(row);
    }
    return tableData;
  }

  async loadMore(): Promise<void> {
    this.skip += this.limit;
    await this.getMyCloudListItems();
  }

  get valid(): boolean {
    return !!this.feedData.feedUrl &&
      (!this.feedData.feedIsSecured ||
        (this.feedData.feedUsername && this.feedData.feedPassword));
  }

  importFeed(): void {
    if (!this.validateURL()) {
      return;
    }
    this.importing = true;
    this.fixFeedData();
    // tslint:disable-next-line:variable-name
    const advertiser_id = (this.mediaService.isSuperAdmin ? this.mediaService.selectedAdvertiserId
      : this.mediaService.userAdvertiserId) || '';
    this.apiCallService.pyGettGenericLong({
      advertiser_id,
      module_fn: 'feeditor.flow.import_feed_from_mobile',
      feedData: this.feedData
    })
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe({
        next: (response) => {
          console.log({response});
          this.importFeedFormSentError = !!response?.error;
          this.importFeedFormSent = true;
          this.importing = false;
        }, error: (reason) => {
          console.log({reason});
          this.importFeedFormSentError = true;
          this.importFeedFormSent = true;
          this.importing = false;
        }
      });

  }

  private fixFeedData(): void {
    if (!this.feedData.feedName || this.feedData.feedName.length < 5) {
      this.feedData.feedName = `Audit: ${utils.getDomainNameFromURL(this.feedData.feedUrl)}`;
    }
    if (!this.feedData.feedEmail || !utils.validateEmail(this.feedData.feedEmail)) {
      this.feedData.feedEmail = this.mediaService.currentUser?.email;
    }
  }
  private validateURL(): boolean {
    this.feedUrlError = !utils.validateUrl(this.feedData.feedUrl);
    return !this.feedUrlError;
  }
  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

}
