import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'aux-emails-box',
  templateUrl: './emails-box.component.html',
  styleUrls: ['./emails-box.component.scss']
})
export class EmailsBoxComponent implements OnInit {

  constructor() {
  }

  @Input() emails: string[] = [];
  @Input() disabled = false;
  @Input() boxName = 'EMAILS';
  @Input() translateService: any | undefined;
  @Input() users: any[] = [];
  @Output() emailsChange = new EventEmitter<string[]>(false);
  @Output() searchUsers = new EventEmitter<any>(false);
  private _error: string | null = null;

  ngOnInit(): void {
    this.validate();
  }


  onModelChange(event: any): void {
    this.emailsChange.emit(event);
  }
  emailsComplete(event: any): void {
    this.searchUsers.emit(event);
  }

  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }

  public validate(): void {
    this._error = null;
    if (!this.emails?.length) {
      this._error = this.translate('EMAILS_MANDATORY');
    }
  }

  public get isValid(): boolean {
    return !this._error;
  }

  public get currentError(): string | null {
    return this._error;
  }
}
