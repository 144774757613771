import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../helpers/authentication.service';

@Component({
  selector: 'app-logout',
  template: '',
  styles: []
})
export class LogoutComponent {

  constructor(private authService: AuthenticationService) {
    this.authService.signOut();
  }
}
