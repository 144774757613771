import {Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {ProfileLayoutComponent} from '../profile-layout.component';

@Component({
  selector: 'app-mypass',
  templateUrl: './mypass.component.html',
  styleUrls: ['./mypass.component.scss']
})
export class MypassComponent implements OnInit {
  fpid: string | undefined;
  error: string | undefined;
  isResetDone = false;
  passwords = {
    password: null,
    rePass: null
  };
  passwordRequirement = '<div>Password Requirements:</div>' +
    '<div>- At least 8 characters</div>' +
    '<div>- At least 1 uppercase letter</div>' +
    '<div>- At least 1 lowercase letter</div>' +
    '<div>- At least 1 number or special character (e.g., @, !, #)</div>';
  passwordCriteria = {
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialOrNumber: false
  };
  showPasswordFeedback = false;

  constructor( private router: Router, private route: ActivatedRoute,
               @Inject(ProfileLayoutComponent) public parent: ProfileLayoutComponent) {
  }



  validate(): boolean {
    this.error = undefined;
    if (this.passwords.password && this.passwords.rePass && this.passwords.password !== this.passwords.rePass) {
      this.error = 'Passwords not identical';
    }
    if (!this.passwords.password || !this.passwords.rePass || this.showPasswordFeedback) {
      this.error = 'Please enter valid password';
    }
    if (this.passwords.password && this.passwords.rePass && this.showPasswordFeedback) {
      this.error = 'Please enter strong password';
    }
    return !this.error;
    //  TODO add validation for password strength
  }
  logIn(): void {
    this.parent.winRef.reloadLocation();
  }

  checkPasswordStrength(password: string): void {
    this.passwordCriteria.minLength = password.length >= 8;
    this.passwordCriteria.uppercase = /[A-Z]/.test(password);
    this.passwordCriteria.lowercase = /[a-z]/.test(password);
    this.passwordCriteria.specialOrNumber = /[0-9\W]/.test(password);
    console.log(password, password.length);
    // Hide the feedback if all criteria are met
    this.showPasswordFeedback = password.length > 0 && !Object.values(this.passwordCriteria).every(criteria => criteria === true);
  }

  resetPassword(): void {
    if (this.validate()) {
      this.isResetDone = false;
      this.parent.apiCallService.changePassword(this.fpid || '', this.passwords.password || '')
        .pipe(takeUntil(this.parent.componentDestroyed))
        .subscribe((response) => {
          this.error = (response || {}).err || null;
          this.isResetDone = (response || {}).result || false;
        });
    }
  }

  ngOnInit(): void {
    // .pipe(filter((params: any) => params.fpid))
    this.route.queryParams
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe(params => {
          // console.log(params); // { order: "popular" }
          this.fpid = params.fpid;
          // if (!this.fpid) {
          //   this.router.navigate(['/login']);
          // }
          // console.log(this.fpid); // popular
        }
      );
  }

}
