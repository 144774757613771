import {Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT: InjectionToken<number> = new InjectionToken<number>('defaultTimeout');


@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const t = Number(req.headers.get('timeout')) || this.defaultTimeout;
    req.headers.delete('timeout');
    return next.handle(req).pipe(timeout(t));
  }
}
