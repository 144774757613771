import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'aux-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss']
})
export class RadioButtonsComponent {
  @Input() translateService: any | undefined;
  @Input() items: any | undefined;
  @Input() itemPrefix = '';
  @Input() disabledItems: any[] = [];
  @Input() selectedItem: any;
  @Output() onChange = new EventEmitter();

  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }
  click(): void {
    this.onChange.emit({value: this.selectedItem});
  }
}
