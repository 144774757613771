<div class="centered">
  <div>
    <img src="https://cloud.adcore.com/assets/email/logo.png">
    <h1>IGNORE ALERT</h1>
  </div>
  <div *ngIf="!errMsg">
    <p>How long would you like to ignore this alert?</p>
    <div class="loader" *ngIf="loading">
      <div></div>
    </div>
    <div *ngIf="!loading">
      <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center">
          <p-radioButton name="ignoreOption" value="week" [ngModel]="ignoreOption" (onClick)="onChange($event)"></p-radioButton>
          <label class="ml-2">1 Week</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton name="ignoreOption" value="always" [ngModel]="ignoreOption" (onClick)="onChange($event)"></p-radioButton>
          <label class="ml-2">Always</label>
        </div>

        <div class="flex align-items-center">
          <p-radioButton name="ignoreOption" value="never" [ngModel]="ignoreOption" (onClick)="onChange($event)"></p-radioButton>
          <label class="ml-2">Don't ignore</label>
        </div>
      </div>
      <div style="padding-top: 20px;" *ngIf="showButton">
        <p-button (click)="ignore()" [disabled]="disabled">APPLY</p-button>
      </div>
      <div style="padding-top: 20px;" *ngIf="!showButton">
        <img src="/assets/icons/vi-check-green.svg" style="height: 27px">
      </div>
    </div>
  </div>
  <div *ngIf="errMsg">
    <p>{{errMsg}}</p>
  </div>
</div>

