import {ApplicationRef, EventEmitter, Injectable} from '@angular/core';
import * as utils from './utils';
import {ApiCallService} from './api-call.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  languageChange: EventEmitter<string> = new EventEmitter<string>();
  private currentTranslations: any = null;
  constructor(private apiCallService: ApiCallService, private ref: ApplicationRef) { }

  get translations(): any {
    return this.currentTranslations;
  }

  set translations(value: any) {
    this.currentTranslations = value;
  }

  initTranslationByLanguage(language: string | undefined): void {
    this.apiCallService.getTranslations(language).toPromise().then((response) => {
        this.translations = response || {};
        // console.log('before emit', {language});
        this.languageChange.emit(language);
        // this.ref.tick();
      },
      (reason) => {
      console.error({reason});
    });
  }
  translate(key: string= 'no key'): string {
    // console.log('translate called', {key});
    if (this.currentTranslations !== null) {

      const value = (!key) ? 'undefined key' : this.currentTranslations[key.toUpperCase()] ;
      if (utils.isUndefinedOrNull(value)) {
        return key;
      }
      return value;
    }
    return key;
  }
}
