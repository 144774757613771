import {Component, OnInit} from '@angular/core';
import {ApiCallService} from '../helpers/api-call.service';
import * as utils from '../helpers/utils';

@Component({
  selector: 'app-audit-request',
  templateUrl: './audit-request.component.html',
  styleUrls: ['./audit-request.component.scss']
})
export class AuditRequestComponent implements OnInit{
  feedUrl = '';
  feedId = '';
  feedEmails: string[] = [];
  auditTypeChanged = true;
  constructor(private apiServ: ApiCallService) {
  }
  get feedName(): string {
    return `Audit: ${utils.getDomainNameFromURL(this.feedUrl)}`;
  }
  submitForReview(registered?: boolean): void {
    if (!registered) {
      this.apiServ.submitFeedForAuditReview({feedName: this.feedName,
        feedUrl: this.feedUrl,
        feedEmails: this.feedEmails,
        feedType: 'feed_audit',
        scheduleType:  this.auditTypeChanged ? 'freeFeedAudit' : 'freeFeedPlusAudit'});
    } else {
      this.apiServ.submitRegisteredFeedForAuditReview({feedId: this.feedId,
        feedEmails: this.feedEmails,
        feedType: 'feed',
        scheduleType: this.auditTypeChanged ? 'freeFeedAudit' : 'freeFeedPlusAudit'});
    }
    this.feedId = '';
    this.feedUrl = '';
    this.auditTypeChanged = true;
    this.feedEmails = [];
  }

  ngOnInit(): void {
  }
}
