import {Component, DoCheck, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as helper from '../shared/helper';

@Component({
  selector: 'aux-get-link',
  templateUrl: './get-link.component.html',
  styleUrls: ['./get-link.component.scss']
})
export class GetLinkComponent implements OnInit, DoCheck {
  @Input() disabled = false;
  @Input() translateService: any | undefined;
  @Input() toolBarActionKey: string | undefined;
  @Input() uiURL = '';
  @Input() uiShortURL = '';
  @Input() convertTo: helper.IGetLink = {};
  @Output() finalizing = new EventEmitter<helper.IGetLinkResult>();
  @Output() copyToClipboard1 = new EventEmitter();
  @Output() copyToClipboard2 = new EventEmitter();
  @Input() exportFormat = 'tsv';
  @Input() isFeedLink: boolean | undefined;
  @Input() exportFormats: string[] | undefined;
  @Input() metadataId: string | undefined;
  @Input() buildId = false;

  constructor() {
  }
  getToolTip(key: string, same?: boolean): string {
    if (this.toolBarActionKey && !same) {
      // console.log(this.toolBarActionKey, ' translations ', ` ng-${this.toolBarActionKey}-${key}`.toUpperCase());
      return this.translate(`ng-tooltip-${this.toolBarActionKey}-${key}`);
    }
    if (same) {
      return this.translate(`ng-merchant-tooltip-${key}`);
    }
    return '';
  }
  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }

  get exportURL(): string {
    return `${this.uiURL}?param=${btoa(JSON.stringify(this.convertTo))}&format=${this.exportFormat}`;
  }
  get shortURL(): string {
    return (this.metadataId) ?  `${this.uiShortURL}/${this.metadataId}/?format=${this.exportFormat}` : '';
  }
  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.finalizing.emit( {
      exportURL: this.exportURL,
      shortURL: this.shortURL,
      exportFormat: this.exportFormat
    });
  }

}
