import {AfterContentInit, Component, Input, OnChanges, OnInit, SimpleChanges, ElementRef, HostListener,
  AfterContentChecked, ChangeDetectorRef} from '@angular/core';
import moment from 'moment-timezone';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'aux-svg-chart',
  templateUrl: './svg-chart.component.html',
  styleUrls: ['./svg-chart.component.scss']
})
export class SvgChartComponent implements OnInit, AfterContentInit, OnChanges, AfterContentChecked {
  @Input() height = 0;
  @Input() showYesterdayData = true;
  @Input() yesterdayData: number[] = [];
  @Input() todayData: number[] = [];
  @Input() todayHour = 0;
  @Input() currency = 'USD';
  @Input() timeInterval = 6;
  @Input() dataType = 'cost';

  dataTimeIntervalWidth = 0;
  len10 = 10;
  offSetX = 35;
  width = 800;
  offSetY = 20;
  filtered: any[] = [];
  timeIntervalWidth = 0;
  chartStart = { x: 0, y: 0 };
  chartEnd = { x: 0, y: 0 };
  yesterdayChartPath = '';
  yesterdayChartCoords: any[] = [];
  todayChartCoords: any[] = [];
  todayChartPath = '';
  todayChartPathFilled = '';
  todayHourPath = '';
  todayValueTitlePath = '';
  todayValue = '';
  todayValueTitleCoords = {
    x: 0,
    y: 0
  };
  todayVLine = { x: 0, y: 0 };
  currencyFormat: any;
  excludeTimesIndexes = [1, 3];

  ngOnInit(): void {}

  drawCharts(): void {
    this.filtered = [];
    this.yesterdayChartCoords = [];
    this.todayChartCoords = [];

    const max = Math.max(Math.max(...this.yesterdayData), Math.max(...this.todayData));
    const k = max !== 0 ? (this.chartEnd.y - this.offSetY) / max : 0;

    for (let i = 0; i < this.yesterdayData.length; ++i) {
      if (i % 3 === 0) {
        this.filtered.push({
          value: this.dataType !== 'Conversions' ? this.currencyFormat.format(this.todayData[i]) : this.todayData[i].toFixed(0),
          timeLabel: moment(i.toFixed(2), ['HH.mm']).format('h a')
        });
      }
    }

    this.timeIntervalWidth = (this.width - 2 * this.offSetX) / (this.filtered.length - 1);
    this.dataTimeIntervalWidth = (this.width - 2 * this.offSetX) / (this.yesterdayData.length - 1);

    for (let i = 0; i < this.yesterdayData.length; ++i) {
      this.yesterdayChartCoords.push({
        x: this.offSetX + i * this.dataTimeIntervalWidth,
        y: this.chartEnd.y - k * this.yesterdayData[i]
      });
      this.todayChartCoords.push({
        x: this.offSetX + i * this.dataTimeIntervalWidth,
        y: this.chartEnd.y - k * this.todayData[i]
      });
    }
    // this.todayValue = currencyFormat.format(this.todayData[this.todayHour]);
    this.yesterdayChartPath = `M ${this.chartStart.x}, ${this.chartEnd.y} `;
    this.todayChartPath = `M ${this.chartStart.x}, ${this.chartEnd.y}`;

    for (const item of this.yesterdayChartCoords) {
      this.yesterdayChartPath += `L ${item.x} ${item.y} `;
    }

    for (const item of this.todayChartCoords) {
      this.todayChartPath += ` L ${item.x} ${item.y} `;
    }
  }

  drawTodayHours(): void {
    this.todayValue = this.dataType !== 'Conversions' ? this.currencyFormat.format(this.todayData[this.todayHour]) :
                      this.todayData[this.todayHour].toFixed(1);
      // this.currencyFormat.format(this.todayData[this.todayHour]);
    this.todayVLine = {
      x: this.offSetX + this.todayHour * this.dataTimeIntervalWidth,
      y: this.chartEnd.y + this.len10 / 2
    };
    this.todayChartPathFilled = `M ${this.chartStart.x}, ${this.chartEnd.y}`;

    if (this.todayChartCoords.length > 0) {
      for (let i = 0; i <= this.todayHour; ++i) {
        this.todayChartPathFilled += ` L ${this.todayChartCoords[i].x} ${this.todayChartCoords[i].y} `
      }
    }

    this.todayChartPathFilled += `L ${this.todayVLine.x} ${this.chartEnd.y}`;
    this.todayChartPathFilled += `L ${this.chartStart.x} ${this.chartEnd.y}`;

    this.todayHourPath =
      `M ${this.todayVLine.x} ${this.todayVLine.y} ` +
      `L ${this.todayVLine.x} ${this.len10 * 2} ` +
      `M ${this.todayVLine.x} ${this.chartEnd.y + this.len10 / 2} ` +
      `L ${this.todayVLine.x + this.len10 / 2} ${this.todayVLine.y + this.len10 / 2} ` +
      `M ${this.todayVLine.x} ${this.chartEnd.y + this.len10 / 2} ` +
      `L ${this.todayVLine.x - this.len10 / 2} ${this.todayVLine.y + this.len10 / 2} `;

    const todayValueTitleWidth = 18;
    const todayValueTitleHeight = 15;
    const todayValueTitle = {
      x: this.todayVLine.x - todayValueTitleWidth,
      y: this.len10 * 4
    };
    this.todayValueTitleCoords = {
      x: this.todayVLine.x,
      y: todayValueTitle.y + this.len10 * 1.1
    };
    this.todayValueTitlePath =
      `M ${todayValueTitle.x} ${todayValueTitle.y} ` +
      `L ${this.todayVLine.x + todayValueTitleWidth} ${todayValueTitle.y} ` +
      `A 1 1, 0, 0 1, ${this.todayVLine.x + todayValueTitleWidth} ${todayValueTitle.y + todayValueTitleHeight} ` +
      `L ${this.todayVLine.x - todayValueTitleWidth} ${todayValueTitle.y + todayValueTitleHeight} ` +
      `A 1 1, 0, 0 1, ${this.todayVLine.x - todayValueTitleWidth} ${todayValueTitle.y}`;

  }

  constructor(private el: ElementRef,  private cdr: ChangeDetectorRef) {}


  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(200)) // Adjust the debounce time as needed
      .subscribe(() => {
        this.cdr.detectChanges(); // Manually trigger change detection
      });
  }

  private updateChart(): void {
    this.width = this.getParentWidth();
    this.chartStart = { x: this.offSetX, y: this.height - this.offSetY - this.len10 };
    this.chartEnd = { x: this.width - this.offSetX, y: this.height - this.offSetY - this.len10 };
    this.drawCharts();
    this.drawTodayHours();
  }

  ngAfterContentInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.width = this.getParentWidth();
    this.currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    this.chartStart = {
      x: this.offSetX,
      y: this.height - this.offSetY - this.len10
    };
    this.chartEnd = {
      x: this.width - this.offSetX,
      y: this.height - this.offSetY - this.len10
    };
    this.drawCharts();
    this.drawTodayHours();
  }

  ngAfterContentChecked(): void {
    this.updateChart();
  }

  private getParentWidth(): number {
    return this.el.nativeElement.parentElement.offsetWidth;
  }
}
