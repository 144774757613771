import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DialogBaseComponent} from '../dialog-base.component';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent extends DialogBaseComponent implements OnInit {
  @Input() classStyle = '';

  modes: any[] = [];
  accounts: any[] = [];
  selectedAccounts: any[] = [];
  selectedMode: any = {};
  selectOnly = false;

  ngOnInit(): void {
    this.dialogConfig?.data.accounts
      .forEach((acc: any) => this.accounts.push({name: acc.name, id: acc.id, imported: acc.imported, type: acc.type}));
    this.selectedAccounts = this.accounts.filter(account => account.imported === true);
    this.modes = [
      {name: this.translate('IMPORT_ALL'), code: 0}, // Import all
      {name: this.translate('SELECTED_ACCOUNTS'), code: 1}  // Selected accounts
    ];
    this.selectOnly = this.dialogConfig?.data.selectOnly;
    if (this.selectOnly) {
      this.selectedMode = this.modes[1];
    }
    if (this.dialogConfig?.styleClass) { this.classStyle = this.dialogConfig.styleClass; }
  }

  close(): void {
    this.cancel();
  }

  import(): void {
    if (this.selectOnly) {
      this.dialogRef?.close({selectedAccounts: this.selectedAccounts});
      // this.dialogConfig?.data.getAccounts(this.selectedAccounts);
    } else if (this.selectedMode.code === 0) {
      this.selectedAccounts = this.accounts;
      this.dialogRef?.close({selectedAccounts: this.selectedAccounts, importAll: true});
      // this.dialogConfig?.data.getAccounts(this.selectedAccounts, true);
    } else {
      this.dialogRef?.close({selectedAccounts: this.selectedAccounts.filter(account => account.imported === false), importAll: false});
      // this.dialogConfig?.data.getAccounts(this.selectedAccounts.filter(account => account.imported === false), false);
    }
  }

  get isImportDisabled(): boolean {
    return this.selectedAccounts.length === 0 && this.selectedMode.code === 1;
  }
}
