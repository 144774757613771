import {EventEmitter, Injectable} from '@angular/core';
import * as models from './models';
import {MediaService} from './media.service';
import * as cfg from './config';
import * as aux from 'adcore-auxiliary';

@Injectable({
  providedIn: 'root'
})
export class UsageService {
  changingPlan: EventEmitter<models.IPlanSettings> = new EventEmitter<models.IPlanSettings>(false);
  usageChange: EventEmitter<void> = new EventEmitter<void>(false);
  statsChange: EventEmitter<void> = new EventEmitter<void>(false);
  isBillingInEditMode = false;
  pricingConfig: models.IPricingConfig = {} as models.IPricingConfig;
  currentUsage: models.IUsage | undefined;
  private _currentStats: models.IUsageStats | undefined;
  constructor(private mediaService: MediaService) { }

  get isTrial(): boolean {
    return this.currentUsage?.isTrial || false;
  }
  get currentStats(): models.IUsageStats | undefined {
    return this._currentStats;
  }

  set currentStats(value: models.IUsageStats | undefined ) {
    this._currentStats = value;
    this.statsChange.emit();
  }
  get totalTasks(): number {
    return this.currentUsage?.calculatedTotalTasks || 0;
  }
  get statsTotalTasks(): number {
    return this.currentStats?.calculatedTotalTasks || 0;
  }

  get effortlessPoints(): number {
    return this.currentStats?.effortlessPoints || 0;
  }

  get planTasks(): number {
    if (this.isTrial) {
      return this.licenseOwnerPlanSettings.freeTasks || 100;
    }
    return this.licenseOwnerPlanSettings.tasks || 0;
  }

  get currentAdvertiser(): aux.IMediaRow | null {
    return this.mediaService.currentAdvertiser;
  }
  get licenseOwnerPlanSettings(): models.IPlanSettings {
    if ( this.licenseOwner?.planSettings && Object.keys(this.licenseOwner.planSettings).length) {
      return this.currentUsage?.licenseOwner?.planSettings;
    }
    return cfg.DEFAULT_PLAN_SETTINGS;
  }

  get selectedTotalTasks(): number {
    return this.currentUsage?.selectedTotalTasks || 0;
  }

  get usedTaskCalculatedPercent(): number {
    return this.currentUsage?.usedTaskCalculatedPercent || 0;
  }

  get isMultiClientAlready(): boolean {
    return this.currentAdvertiser?.subType === 'agency';
  }

  get isLicenseOwnerIsSelected(): boolean {
    return this.currentUsage?.licenseOwner?.key === this.currentAdvertiser?.key;
  }
  get licenseOwnerId(): string {
    return this.licenseOwner?.key || this.mediaService.currentAdvertiser?.key || '';
  }

  get advertiserType(): string {
    return this.currentAdvertiser?.subType  || 'client';
  }
  get licenseOwner(): aux.IMediaRow | undefined {
    return this.currentUsage?.licenseOwner;
  }

  set licenseOwner(value: aux.IMediaRow | undefined){
    if (value && this.currentUsage) {
      this.currentUsage.licenseOwner = value;
    }
  }

  get stats(): any {
    return this.currentUsage?.stats || {};
  }
}
