<div class="field" *ngIf="!isAccountsExist; else content">
  <div class="col input-row" style="width: 100%;">
    <p-button *ngIf="!isLoading" type="button" styleClass="btn-primary" (onClick)="connectNewMerchant()">
      <span  class="p-button-label ng-star-inserted">{{translate('CONNECT_GOOGLE_MERCHANT_ACCOUNT')}}</span>
    </p-button>
    <span *ngIf="isLoading" class="pi pi-spin pi-spinner" style="font-size: 1.4em;  margin-left:0.2em; margin-bottom: 0.3em;"></span>
  </div>
</div>
<ng-template #content>
  <div class="field datafeed">
    <label for="accounts" class="col-fixed input-title title-row">{{translate('SELECT_AN_ACCOUNT')}}</label>
    <div class="col input-row with-info">
      <p-autoComplete  id="accounts" #acc [completeOnFocus]="false"  appendTo="body" [forceSelection]="true" [delay]="400" [minLength]="3"
                       [ngModel]="selectedAccount" (onSelect)="onSelectAccount($event)" [suggestions]="filteredAccounts" (completeMethod)="accountSearch($event)"
                       [dropdown]="true"  [placeholder]="translate('SELECT_ACCOUNT')" [disabled]="isLoading" [lazy]="false"
                       dropdownMode="current" [styleClass]="getClasses('selectedAccount') + ' autoComplete-with-btn-menu'" [panelStyleClass]="classStyle">
<!--        <ng-template pTemplate="selectedItem">-->
<!--          <span *ngIf="selectedAccount">{{selectedAccount.name || selectedAccount.label}}</span>-->
<!--        </ng-template>-->
<!--        <ng-template let-account pTemplate="item">-->
<!--          {{account.label}}-->
<!--        </ng-template>-->
      </p-autoComplete>
      <p-button type="button" [label]="translate('ADD_MERCHANT_ACCOUNT')"
                styleClass="btn-secondary-link" [disabled]="isLoading" (onClick)="connectNewMerchant()">
      </p-button>
      <span *ngIf="isSearching" class="pi pi-spin pi-spinner" style="padding:5px;"></span>
    </div>
    <div class="col info-row">
      <aux-info [content]="getToolTip('selectedAccount')"></aux-info>
    </div>
  </div>
  <div class="field">
    <label for="columns" class="col-fixed title-row">{{translate('PRIMARY_KEY')}}</label>
    <div class="col input-row with-info">
      <p-dropdown inputId="columns" id="columns"
                  optionLabel="columnDisplay"
                  optionValue="columnName"
                  [filter]="false" [showClear]="false" [options]="feedColumnsList" [(ngModel)]="columnId"
                  appendTo="body"  [disabled]="isLoading"
                  [autoDisplayFirst]="false"
                  [autofocusFilter]="false"
                  [placeholder]="translate('SELECT_COLUMN')"
                  [styleClass]="getClasses('columnId')"
                  [panelStyleClass]="classStyle">
        <ng-template pTemplate="selectedItem">
          {{selectedColumnLabel}}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col info-row">
      <aux-info [content]="getToolTip('primaryKey')"></aux-info>
    </div>
  </div>
  <div class="field">
    <label for="countries" class="col-fixed title-row">{{translate('TARGET_COUNTRY')}}</label>
    <div class="col input-row with-info">
      <p-dropdown id="countries" [panelStyleClass]="classStyle" optionLabel="name" optionValue="code"
                  [showClear]="false" [options]="countries" [(ngModel)]="targetCountry" [filter]="true"
                  appendTo="body" [disabled]="isLoading" [autoDisplayFirst]="false" [autofocusFilter]="false"
                  [placeholder]="translate('SELECT_COUNTRY')"
                  [styleClass]="getClasses('targetCountry')">
        <ng-template pTemplate="selectedItem">
          {{selectedCountryLabel}}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col info-row">
      <aux-info [content]="getToolTip('targetCountry')"></aux-info>
    </div>
  </div>
  <div class="field">
    <label for="languages" class="col-fixed title-row">{{translate('LANGUAGE')}}</label>
    <div class="col input-row with-info">
      <p-dropdown id="languages" [panelStyleClass]="classStyle"  optionLabel="name" optionValue="code" [filter]="true"
                  [showClear]="false" [options]="languages" [(ngModel)]="language" [disabled]="isLoading"
                  appendTo="body" [autoDisplayFirst]="false" [autofocusFilter]="false"
                  [placeholder]="translate('SELECT_LANGUAGE')"
                  [styleClass]="getClasses('language')">
        <ng-template pTemplate="selectedItem">
          {{selectedLanguageLabel}}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col info-row">
      <aux-info [content]="getToolTip('language')"></aux-info>
    </div>
  </div>
  <div class="field">
    <label for="channels" class="col-fixed title-row">{{translate('CHANNEL')}}</label>
    <div class="col input-row with-info">
      <p-dropdown id="channels" [panelStyleClass]="classStyle"  optionLabel="name" optionValue="code" [filter]="false"
                  [showClear]="false" [options]="channels" [(ngModel)]="channel" [disabled]="isLoading"
                  appendTo="body" [autoDisplayFirst]="false" [autofocusFilter]="false"
                  [placeholder]="translate('SELECT_CHANNEL')"
                  [styleClass]="getClasses('channel')">
        <ng-template pTemplate="selectedItem">
          {{selectedChannelLabel}}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col info-row">
      <aux-info [content]="getToolTip('channel')"></aux-info>
    </div>
  </div>
</ng-template>
