<div #actualTarget (click)="toggleOverlayPanel($event, actualTarget)">
  <p-button icon="header-icon" styleClass="p-button-rounded p-button-secondary p-button-text header-icon-btn"
            [pTooltip]="translate('ng-tooltip-help')" [tooltipStyleClass]="classStyle" tooltipPosition="left" [rounded]="true" [text]="true" [plain]="true"></p-button>
</div>
<p-overlayPanel #op [dismissable]="true" [focusOnShow]="true" styleClass="no-arrow-panel help-panel" [appendTo]="componentTemplate">
  <ng-template pTemplate>
    <span class="p-input-icon-left mb-2">
      <i class="pi pi-search pointer" (click)="search()"></i>
      <input type="text"  (keydown.enter)="search()" pInputText [style]="{width: '280px'}" [placeholder]="translate(placeholder || 'search_help')" [(ngModel)]="searchQuery" />
    </span>
    <p-menu [model]="menuItems" [appendTo]="op"></p-menu>
  </ng-template>
</p-overlayPanel>
