import {ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as aux from 'adcore-auxiliary';
import {AuthenticationService} from './helpers/authentication.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MediaService} from './helpers/media.service';
import {MessagingService} from './helpers/messaging.service';
import {TranslationService} from './helpers/translation.service';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import {Userpilot} from 'userpilot';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  private authenticationInProcess = true;

  @HostListener('window:beforeunload', ['$event'])
  handleClose(event: any): void {
    if (this.mediaService.currentAppName === 'effortless') {
      this.authenticationService.signOut();
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any): void {
    if (['http://localhost:3000', 'http://localhost:4200', 'https://apps.adcore.com',
      'https://cloud.adcore.com',
      'https://views-test.adcore.com', 'https://amctest.adcore.com'].indexOf(event.origin) === -1){
      return;
    }
    this.messagingService.retrieveMessage(event.data || null, this);
  }

  constructor(private windowRefService: aux.WindowRefService,
              private authenticationService: AuthenticationService,
              private mediaService: MediaService,
              private messagingService: MessagingService,
              private translationService: TranslationService,
              private cdr: ChangeDetectorRef, private router: Router,
              private renderer2: Renderer2,
              private messageService: MessageService,
              @Inject(DOCUMENT) private _document: any) {
    this.translationService.languageChange
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((language) => {
        if (this.mediaService.currentUser) {
          this.mediaService.currentUser.language = language;
        }
        this.messagingService.sendMessage('translation', this.mediaService.currentAppName || '', '');
      });
    this.authenticationService.onAuthenticationStart
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        // console.log('authentication start on app');
        this.mediaService.isAuthenticationInProcess = true;
      });
    this.authenticationService.onAuthenticationEnd
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        // const url = decodeURIComponent(this.router.url || '');
        const url = decodeURIComponent(this.windowRefService.href || '');
        if (this.mediaService.currentUser) {
          const userId = this.mediaService.currentUser._id || this.mediaService.currentUser.id || '';
          const parameters = {
            name: this.mediaService.currentUser.name,
            email: this.mediaService.currentUser.email,
            company:  // optional
              {
                id: this.mediaService.userAdvertiserId
              }
          };
          Userpilot.identify(
            userId,
            parameters
          );
        }
        this.mediaService.isAuthenticationInProcess = false;
        // console.log('authentication end on app', this.router.url);
        const modules = this.mediaService.modules;
        const isProfileRoute = url.includes('/mypass') || url.includes('/join');
        const isMediaBlastRoute = url.includes('/media-blast');
        const publicRoutes = ['/media-blast-kyc', '/unsubscribeMe', '/alerter-ignore', '/notDesktop',
            '/promote', '/audit-request'];
        if (!isProfileRoute && !publicRoutes.some(p => url.includes(p)) && this.mediaService?.currentUser &&
          !this.mediaService?.currentUser?.upgradedLogin ) {
          setTimeout(() => {
            this.router.navigateByUrl('/upgrade-login');
          });
        }
        if (!isProfileRoute && isMediaBlastRoute && !!modules.mediablast) {
          if (!this.mediaService.isMediaBlastSubscriptionActive) {
            this.router.navigateByUrl('/media-blast/subscription');
          }
        }
        if (!isProfileRoute && !this.mediaService.currentUser?.licenseAvailable && !this.mediaService.isAdminRole) {
          if (!publicRoutes.some(p => url.includes(p))){
            if (this.mediaService.isFeeditorPlanSelected) {
              this.router.navigateByUrl('/feeditor');
            }
            else if (this.mediaService.isFeeditorPlusPlanSelected) {
                this.router.navigateByUrl('/feeditor-plus');
            } else {
              // console.log('feeeditor or plus');
              this.router.navigateByUrl('/my-cloud');
            }
          }
        }
        // if (url.includes('/effortless') && this.mediaService.allowedEffortless) {
        //   this.router.navigateByUrl('feeditor');
        // }
        // if (!url.includes('/effortless') && this.mediaService.pureEffortless) {
        //   this.router.navigateByUrl('login');
        // }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  private renderHubspotScript(): void {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = '//js.hs-scripts.com/6962858.js';
    s.text = ``;
    s.async = true;
    s.defer = true;
    s.id = 'hs-script-loader';
    this.renderer2.appendChild(this._document.body, s);
  }

  ngOnInit(): void {
    const nativeGeoIp = this.windowRefService.nativeGeoIp;
    if (nativeGeoIp) {
      nativeGeoIp.country((geoipResponse: any) => {
          this.mediaService.currentCountryCode = geoipResponse?.country?.iso_code || '';
        },
        (err: any) => {
          console.log('nativeGeoIp Error', err);
        });
    }
    this.authenticationService.authenticate();
    if (environment.production) {
      this.renderHubspotScript();
    }
  }
}
