<div class="field">
  <label for="ftpUser" class="col-fixed title-row">{{translate("FTPUSER")}}</label>
  <div class="col input-row with-info">
    <input id="ftpUser" type="text" autocomplete="off" pInputText [required]="true" [(ngModel)]="ftpUser" [placeholder]="translate('FTPUSER')">
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('ftpUser')"></aux-info>
  </div>
</div>
<div class="field">
  <label for="ftpPassword" class="col-fixed title-row">{{translate("FTPPASSWORD")}}</label>
  <div class="col input-row with-info">
       <span class="p-input-icon-right">
          <i class="pi pi-eye pointer" *ngIf="pass.type === 'password'" (click)="pass.type = 'text'"></i>
          <i class="pi pi-eye-slash pointer" *ngIf="pass.type === 'text'" (click)="pass.type = 'password'"></i>
          <input type="password" class="stealthy" tabindex="-1">
          <input #pass id="ftpPassword" name="ftpPassword" type="password" pInputText
                 [placeholder]="translate('FTPPASSWORD')"
                 [(ngModel)]="ftpPassword" autocomplete="new-password" [required]="true">
          <input type="password" class="stealthy" tabindex="-1">
        </span>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('ftpPassword')"></aux-info>
  </div>
</div>
<div>
  <label for="feedFileName" class="col-fixed title-row">{{translate("FEED_FILE_NAME")}}</label>
  <div class="col input-row with-info">
    <input id="feedFileName" type="text"  pInputText [(ngModel)]="feedName"  [pKeyFilter]="fileNamePattern"  [required]="true"
           [placeholder]="translate('FEED_FILE_NAME_PLACEHOLDER')"
           [autofocus]="true">
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('feedFileName', true)"></aux-info>
  </div>
</div>
<div class="field">
  <label class="col-fixed title-row"></label>
  <div class="col input-row with-info">
    <small>{{translate('NG_NOTE_FOR_FILE_FEED_NAME')}}</small>
  </div>
</div>
<div class="field">
  <label for="exportFormat" class="col-fixed title-row">{{translate("EXPORT_FORMAT")}}</label>
  <div class="col input-row with-info">
    <select id="exportFormat" [(ngModel)]="exportFormat" class="p-component p-dropdown">
      <option *ngFor="let f of exportFormats; first as isFirst" [value]="f" [label]="translate(f)"
              [selected]="(ftpInfo.export_format && ftpInfo.export_format === f) || isFirst"></option>
    </select>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('exportFormat', true)"></aux-info>
  </div>
</div>


