import {Injectable} from '@angular/core';
import {MediaService} from './media.service';
import * as _ from 'lodash';
import { values } from 'lodash';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  private sideBarValue = 0;
  private oldSideBarValue = 0;
  private toolbarValues: any = {};
  // cleanup:
  // clear html, trim spacing, fix url encoding
  //
  // filter:
  // out of stock items
  //
  // label:
  // price range
  //
  // protection:
  // feed failover strategy
  private recommended: string[] = ['cleanup-html-tags', 'cleanup-trim-spacing',
    'filter-out-of-stock', 'label-price-range', 'protect-failover-strategy'];
  sidebarValueChange: Subject<number> = new Subject<number>();
  constructor(private mediaService: MediaService) {
  }

  isRecommended(key: string): boolean {
    return this.recommended.includes(key);
  }

  getLabels(selectedValues?: any[]): any {
    if (!selectedValues) {
      selectedValues = this.recommended;
    }
    const _labels =  {
      isTopSeller: false,
      isRoasRange: false,
      isPriceRange: false,
    };
    selectedValues.forEach((a: any) => {
      switch (a) {
        case 'label-top-seller':
          _labels.isTopSeller = true;
          break;
        case 'label-roas-range':
          _labels.isRoasRange = true;
          break;
        case 'label-price-range':
          _labels.isPriceRange = true;
          break;
      }
    });
    return _labels;
  }

  getFilters(selectedValues?: any[], lowPriceThreshold?: number): any {
    if (!selectedValues) {
      selectedValues = this.recommended;
    }
    const f = {
      isClearOutOfStock: false,
      isClearUnderPerforming: false,
      isClearLowPrice: false,
      lowPriceThreshold: 0,
    };
    selectedValues.forEach((a: any) => {
      switch (a) {
        case 'filter-out-of-stock':
          f.isClearOutOfStock = true;
          break;
        case 'filter-under-performing':
          f.isClearUnderPerforming = true;
          break;
        case 'filter-low-priced-items':
          f.isClearLowPrice = true;
          f.lowPriceThreshold = lowPriceThreshold || 0;
          break;
      }
    });
    return f;
  }

  getCleanup(selectedValues?: any[]): any {
    if (!selectedValues) {
      selectedValues = this.recommended;
    }
    const clp = {
      isClearHtml: false,
      isTrimSpacing: false,
      isFixUrlEncoding: false,
      isModifyLetterCase: false,
      isFixShipping: false,
    };
    selectedValues.forEach((a: any) => {
      switch (a) {
        case 'cleanup-html-tags':
          clp.isClearHtml = true;
          break;
        case 'cleanup-trim-spacing':
          clp.isTrimSpacing = true;
          break;
        case 'cleanup-fix-url-encoding':
          clp.isFixUrlEncoding = true;
          break;
        case 'cleanup-fix-shipping-format':
          clp.isFixShipping = true;
          break;
        case 'cleanup-modify-letter-case':
          clp.isModifyLetterCase = true;
          break;
      }
    });
    return clp;
  }

  applyVersionRecommendations(): void {
    this.feeditorCleanup = ['cleanup-html-tags', 'cleanup-trim-spacing']; // 'cleanup-fix-url-encoding'
    this.feeditorFilter = {'filter-out-of-stock': {checked: true}};
    this.feeditorLabels = ['label-price-range'];
    this.feeditorProtect = ['protect-failover-strategy'];
    this.loadFromVersion();
  }

  get recommendedToolBar(): any {
    this.toolbarCleanup = ['cleanup-html-tags', 'cleanup-trim-spacing'];
    this.toolbarFilter = {'filter-out-of-stock': {checked: true}};
    this.toolbarLabels = ['label-price-range'];
    this.toolbarProtect = ['protect-failover-strategy'];
    return this.toolbarValues;
  }

  get toolbar(): any {
    return this.toolbarValues;
  }

  set toolbar(value: any) {
    this.toolbarValues = value;
  }

  loadFromVersion(key?: string): void {
    if (!key) {
      this.toolbarValues = _.cloneDeep(this.feeditorToolBar) || {};
    } else {
      this.toolbarValues[key] = _.cloneDeep(this.feeditorToolBar[key]);
    }
  }

  get toolbarProtectIsChanged(): boolean {
    return !_.isEqual(this.toolbarProtect, this.feeditorProtect);
  }

  get toolbarLabelsIsChanged(): boolean {
    return !_.isEqual(this.toolbarLabels, this.feeditorLabels);
  }

  get toolbarEnrichIsChanged(): boolean {
    return !_.isEqual(this.toolbarEnrich, this.feeditorEnrich);
  }

  get toolbarFilterIsChanged(): boolean {
    return !_.isEqual(this.toolbarFilter, this.feeditorFilter);
  }

  get toolbarCleanupIsChanged(): boolean {
    return !_.isEqual(this.toolbarCleanup, this.feeditorCleanup);
  }

  get toolbarCleanup(): any[] {
    if (!this.toolbarValues['cleanup-feed']) {
      this.toolbarValues['cleanup-feed'] = [];
    }
    return _.get(this.toolbarValues, 'cleanup-feed', []);
  }

  set toolbarCleanup(value: any[]) {
    _.set(this.toolbarValues, 'cleanup-feed', value);
  }

  get feeditorCleanup(): any[] {
    if (!this.feeditorToolBar['cleanup-feed']) {
      // 'cleanup-html-tags', 'cleanup-trim-spacing', 'cleanup-fix-url-encoding', 'cleanup-fix-shipping-format'
      _.set(this.feeditorToolBar, 'cleanup-feed', []);
    }
    return _.get(this.feeditorToolBar, 'cleanup-feed') || [];
  }

  set feeditorCleanup(value: any[]) {
    if (this.feeditorToolBar) {
      _.set(this.feeditorToolBar, 'cleanup-feed', value);
    }
  }

  get toolbarFilter(): any {
    if (!this.toolbarValues['filter-feed']) {
      this.toolbarValues['filter-feed'] = {};
    }
    return this.toolbarValues['filter-feed'] || {};
  }

  set toolbarFilter(value: any) {
    if (this.toolbarValues) {
      this.toolbarValues['filter-feed'] = value;
    }
  }

  get feeditorFilter(): any {
    if (!this.feeditorToolBar['filter-feed']) {
      this.feeditorToolBar['filter-feed'] = {};
    }
    return this.feeditorToolBar['filter-feed'] || {};
  }

  set feeditorFilter(value: any) {
    if (this.feeditorToolBar) {
      this.feeditorToolBar['filter-feed'] = value;
    }
  }

  get feeditorLabels(): any[] {
    if (!this.feeditorToolBar['labels-feed']) {
      this.feeditorToolBar['labels-feed'] = [];
    }
    return this.feeditorToolBar['labels-feed'] || [];
  }

  set feeditorLabels(value: any[]) {
    if (this.feeditorToolBar) {
      this.feeditorToolBar['labels-feed'] = value;
    }
  }

  get toolbarLabels(): any[] {
    if (!this.toolbarValues['labels-feed']) {
      this.toolbarValues['labels-feed'] = [];
    }
    return this.toolbarValues['labels-feed'] || [];
  }

  set toolbarLabels(value: any[]) {
    if (this.toolbarValues) {
      this.toolbarValues['labels-feed'] = value;
    }
  }

  get feeditorEnrichGoogleShoppingStats(): boolean {
    return _.get(this.feeditorToolBar, 'enrich-feed', []).includes('enrich-google-shopping-statistics');
  }

  get feeditorToolBar(): any {
    return this.mediaService.feeditorToolBar;
  }

  set feeditorToolBar(value: any) {
    this.mediaService.feeditorToolBar = value;
  }

  get feeditorEnrich(): string[] {
    if (!this.feeditorToolBar['enrich-feed']) {
      this.feeditorToolBar['enrich-feed'] = [];
    }
    return this.feeditorToolBar['enrich-feed'] || [];
  }

  set feeditorEnrich(value: string[]) {
    if (this.feeditorToolBar) {
      this.feeditorToolBar['enrich-feed'] = value;
    }
  }

  get toolbarEnrich(): string[] {
    if (!this.toolbarValues['enrich-feed']) {
      this.toolbarValues['enrich-feed'] = [];
    }
    return this.toolbarValues['enrich-feed'] || [];
  }

  set toolbarEnrich(value: string[]) {
    if (this.toolbarValues) {
      this.toolbarValues['enrich-feed'] = value;
    }
  }

  get toolbarProtect(): string[] {
    if (!this.toolbarValues['protect-feed']) {
      this.toolbarValues['protect-feed'] = [];
    }
    return this.toolbarValues['protect-feed'] || [];
  }

  set toolbarProtect(value: string[]) {
    if (this.toolbarValues) {
      this.toolbarValues['protect-feed'] = value;
    }
  }

  get feeditorProtect(): string[] {
    if (!this.feeditorToolBar['protect-feed']) {
      this.feeditorToolBar['protect-feed'] = [];
    }
    return this.feeditorToolBar['protect-feed'] || [];
  }

  set feeditorProtect(value: string[]) {
    if (this.feeditorToolBar) {
      this.feeditorToolBar['protect-feed'] = value;
    }
  }

  applyIntoVersion(key?: string): void {
    if (!key) {
      this.feeditorToolBar = _.cloneDeep(this.toolbarValues) || null;
    } else {
      this.feeditorToolBar[key] = _.cloneDeep(this.toolbarValues[key]);
    }
  }

  get currentSideBarValue(): number {
    this.oldSideBarValue = this.sideBarValue;
    this.sideBarValue = 0;
    Object.entries(this.toolbarValues || {}).forEach(([toolBarKey, value]) => {
      switch (toolBarKey) {
        case 'cleanup-feed':
          this.sideBarValue += (value as [] || []).reduce((curr: number, actionKey: string) => {
            if (['cleanup-html-tags', 'cleanup-trim-spacing', 'cleanup-fix-url-encoding',
              'cleanup-modify-letter-case'].includes(actionKey)) {
              curr += 5;
            }
            return curr;
          }, 0);
          break;
        case 'enrich-feed':
          this.sideBarValue += (value as [] || []).reduce((curr: number, actionKey: string) => {
            if ('enrich-google-product-category' === actionKey) {
              curr += 5;
            } else if ('enrich-google-shopping-statistics' === actionKey) {
              curr += 20;
            }
            return curr;
          }, 0);
          break;
        case 'filter-feed':
          this.sideBarValue += Object.keys(value as {} || {}).reduce((curr: number, actionKey: string) => {
            if (['filter-out-of-stock'].includes(actionKey)) {
              curr += 10;
            }
            else if (['filter-under-performing', 'filter-low-priced-items'].includes(actionKey)) {
              curr += 5;
            }
            return curr;
          }, 0);
          break;
        case 'labels-feed':
          this.sideBarValue += (value as [] || []).reduce((curr: number, actionKey: string) => {
            if (['label-top-seller', 'label-roas-range', 'label-price-range'].includes(actionKey)) {
              curr += 5;
            }
            return curr;
          }, 0);
          break;
        case 'protect-feed':
          this.sideBarValue += (value as [] || []).reduce((curr: number, actionKey: string) => {
            if (['protect-failover-strategy'].includes(actionKey)) {
              curr += 10;
            }
            return curr;
          }, 0);
          break;
      }
    });
    if (this.mediaService.currentFeed) {
      this.sideBarValue += 10;
    }
    if (this.oldSideBarValue !== this.sideBarValue) {
      this.sidebarValueChange.next(this.sideBarValue);
    }
    return this.sideBarValue;
  }
}
