import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import _ from 'lodash';
import {Inplace} from 'primeng/inplace';
import {ClosePropService} from '../shared/close-prop.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'aux-edit-prop',
  templateUrl: './edit-prop.component.html',
  styleUrls: ['./edit-prop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditPropComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('pi') inPlace: Inplace | undefined;
  @Input() translateService: any | undefined;
  @Input() disabled = false;
  @Input() activated = false;
  @Input() hide = false;
  @Input() readonly = false;
  @Input() inputType = 'text';
  @Input() editObject: any = {};
  @Input() editLabel: string | undefined;
  @Input() editPlaceholder: string | undefined;
  @Input() editProp = 'value';
  @Input() listOfValues: any[] = [];
  @Input() sensitiveFor: string[] = [];
  @Output() changingProp = new EventEmitter<any>(false);
  localObject: any = {};
  private isOpened = false;
  private componentDestroyed: Subject<boolean> = new Subject();

  constructor(private closePropService: ClosePropService) {
    this.closePropService.openOtherProp
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((event: any) => {
        if (this.editProp !== event.key && !this.activated) {
          this.close(event.originalEvent);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localObject = _.cloneDeep(this.editObject);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  ngAfterViewInit(): void {
  }

  get selectedOptionFromList(): any {
    return this.listOfValues.find((v: any) => v.value === this.localObject[this.editProp]) || {};
  }
  ngOnInit(): void {
  }

  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }

  changeProp(event: any): void {
    this.changingProp.emit({
      originalValue: this.editObject[this.editProp],
      changedValue: this.localObject[this.editProp],
      propName: this.editProp,
      event,
      sensitiveFor: this.sensitiveFor
    });
    if (!this.activated) {
      this.inPlace?.deactivate(event);
    }
  }

  close(event: any): void {
    if (this.inPlace?.active) {
      this.localObject = _.cloneDeep(this.editObject);
      this.inPlace?.deactivate(event);
    }
  }

  onOutsideClick(event: any): void {
    // TODO if it will be required by users
    // console.log({event, inPlace: this.inPlace?.active, isOpened: this.isOpened});
    if (this.isOpened) {
      this.close(event);
    }
  }

  open(event: any): void {
    if (!this.inPlace?.active && !this.disabled) {
      this.closePropService.openOtherProp.emit({key: this.editProp, originalEvent: event});
      this.localObject = _.cloneDeep(this.editObject);
      this.inPlace?.activate(event);
    }
  }

  onActivate(event: any): void {
    this.isOpened = true;
    // console.log('onActivate');
  }

  onDeactivate(event: any): void {
    this.isOpened = false;
    // console.log('onDeactivate');
  }
}
