import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import * as helper from '../shared/helper';
import * as _ from 'lodash';
import {MenuItem} from 'primeng/api';
import {Inplace} from 'primeng/inplace';
import {Menu} from 'primeng/menu';
import {MediaInternalService} from '../shared/media-internal.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WindowRefService} from '../shared/windowref.service';
import {Button} from 'primeng/button';

@Component({
  selector: 'aux-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit, AfterViewChecked, DoCheck, OnDestroy {
  @ViewChild('menu') mediaMenu: Menu | undefined;
  @ViewChild('op') overlayPanel: OverlayPanel | undefined;
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @ViewChild('labelEdit') labelEdit: ElementRef | undefined;
  @ViewChild('inplaceEdit') inplaceEdit: Inplace | undefined;
  @ViewChild('dropDownButton') dropDownButton: ElementRef | undefined;
  @ViewChild('actualTarget') actualTarget: ElementRef | undefined;
  editObject: helper.IMediaEditEvent = {
    mode: helper.EMediaMode.NONE,
    row: null,
    changedProp: '',
    originValue: null,
    changedValue: null
  };
  selectionInProcess = false;
  isSearchEnabled = false;
  searchParam: helper.ISearchParam = {mode: helper.EMediaMode.NONE, searchQuery: '', inProgress: false, skip: 0};
  loadParam: helper.ILoadMoreEvent = {mode: helper.EMediaMode.NONE, inProgress: false, skip: 0};
  searchResults: helper.IMediaRow[] = [];
  loadResults: helper.IMediaRow[] = [];
  private timeoutHandle: any = null;
  additionalMenu: MenuItem[] = [];
  defaultLabel = '';
  totalMediaRows = 0;
  isPanelOpen = false;
  isGreen = false;
  private oldTotalMediaRows = 0;
  @Output() selectingMedia: EventEmitter<helper.IMediaSelectedEvent> = new EventEmitter<helper.IMediaSelectedEvent>(false);
  @Output() goTo: EventEmitter<helper.IGoTo> = new EventEmitter<helper.IGoTo>(false);
  @Output() searching: EventEmitter<helper.ISearchParam> = new EventEmitter<helper.ISearchParam>(false);
  @Output() loadingMore: EventEmitter<helper.ILoadMoreEvent> = new EventEmitter<helper.ILoadMoreEvent>(false);
  @Output() editing: EventEmitter<helper.IMediaEditEvent> = new EventEmitter<helper.IMediaEditEvent>(false);
  @Output() trackTotalRows: EventEmitter<number> = new EventEmitter<number>(false);
  @Input() editableProperty: string | undefined;
  @Input() configuration: helper.IMediaConfiguration = {} as helper.IMediaConfiguration;
  @Input() disabled = false;
  @Input() autoload = false;
  @Input() selectedMedia: helper.IMediaRow | null = null;
  @Input() recentMedia: helper.IMediaRow[] = [];
  @Input() encrypt = false;
  @Input() mediaConnector: helper.IMediaConnector | undefined;
  @Input() hideSelected: boolean | undefined;
  @Input() notEmitSelected: boolean | undefined;
  @Input() classStyle = '';
  private componentDestroyed: Subject<boolean> = new Subject();
  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef, private internalService: MediaInternalService,
              private winRef: WindowRefService) {
    this.searchParam.searchEnd = this.searchCompleted.bind(this);
    this.loadParam.loadEnd = this.loadCompleted.bind(this);
  }
  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }
  ngDoCheck(): void {
    if (this.additionalMenu?.length && this.configuration) {
      if (this.configuration.isMenuItemVisible) {
        this.additionalMenu.forEach((item: MenuItem) => {
          // @ts-ignore
          item.visible = this.configuration.isMenuItemVisible(item) || false;
        });
      }
      if (this.configuration.isAdditionalMenuGreen) {
        this.isGreen = this.configuration.isAdditionalMenuGreen() || false;
      }
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  get isRecentDefined(): boolean {
    return !!this.recentMedia?.length;
  }

  encryptName(label: string, index: number): string {
    if (!this.encrypt) {
      return label;
    }
    return `Demo ${this.configuration.mode} ${index + 1}`;
  }

  encryptId(id: string, index: number): string {
    if (!this.encrypt) {
      return Number(id).toFixed(0);
    }
    let newId = parseFloat(id);
    newId =  ((newId / 793) + (index + 1)) * 1000;
    return `${newId.toFixed()}`;
  }
  search(): void {
    if (this.searchParam.searchQuery && !this.searchParam.inProgress) {
      this.searchResults = [];
      this.searchParam.inProgress = true;
      this.searchParam.skip = 0;
      this.searching.emit(this.searchParam);
    }
  }

  searchWithTimer(): void {
    if (this.searchParam.searchQuery && !this.searchParam.inProgress) {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle);
      }
      this.timeoutHandle = setTimeout(() => this.search(), 500);
    }
  }

  showHideSearch(): void {
    this.isSearchEnabled = !this.isSearchEnabled;
    this.searchParam.inProgress = false;
    this.searchParam.searchQuery = '';
    this.searchResults = [];
    this.totalMediaRows =  (!this.isSearchEnabled) ? this.oldTotalMediaRows : 0;
    if (this.isSearchEnabled) {
      setTimeout(() => {
        // console.log({i:  this.searchInput});
        this.searchInput?.nativeElement?.focus();
      }, 10);
    }
  }

  private searchCompleted(searchResults: helper.IMediaRow[] = [], total?: number): void {
    this.searchParam.inProgress = false;
    this.searchResults.push(...searchResults);
    this.totalMediaRows = (total) ? total : 0;
  }

  private loadCompleted(loadResults: helper.IMediaRow[] = [], total?: number): void {
    this.loadParam.inProgress = false;
    this.loadResults.push(...loadResults);
    this.oldTotalMediaRows = this.totalMediaRows = (total) ? total : 0;
    this.trackTotalRows.emit(this.oldTotalMediaRows);
  }

  openVersionMenu(event: any): void {
    this.hidePanel();
    if (this.mediaMenu) {
      this.mediaMenu.toggle(event);
    }
  }
  onShowVersionMenu(event: any): void {
    setTimeout(() => {
      // solution to fix the issue with selected menu item when open the menu
      if (this.mediaMenu) {
        this.winRef.nativeDocument?.activeElement?.blur();
      }
    });
  }

  onShowPanel(): void {
    this.internalService.openOtherPanel.emit(this.actualTarget);
  }


  public openOverlayPanelFromOutside(): void {
    // if (!this.editableProperty) {
    //   this.actualTarget?.nativeElement?.click();
    // } else {
    //   this.dropDownButton?.nativeElement?.click();
    // }
    this.dropDownButton?.nativeElement?.click();
  }

  toggleOverlayPanel(event: any, target: any): void {
    if (this.overlayPanel && !this.disabled) {
      this.inplaceEdit?.deactivate(event);
      this.isPanelOpen = !this.isPanelOpen;
      this.overlayPanel.toggle(event, target);
      this.mediaMenu?.hide();
    }
  }

  onRowClick(event: MouseEvent, row: helper.IMediaRow): void {
    // console.log('onRowClick: ', {event, row, total: this.totalMediaRows});
    this.selectingMedia.emit({
      mode: this.configuration?.mode,
      row,
      originEvent: event
    });
    this.hidePanel();
  }

  onEditMedia(event: MouseEvent): void {
    if (this.configuration?.editMedia) {
      this.configuration?.editMedia(event, this.configuration.mode);
    }
    this.hidePanel();
  }

  goToManagement(event: MouseEvent, ): void {
    switch (this.configuration.mode) {
      case helper.EMediaMode.FEED_PLUS:
      case helper.EMediaMode.FEED:
        this.goTo.emit({
          originalEvent: event,
          whereToGo: helper.EGoTo.FEED_MANAGEMENT,
          mode: this.configuration?.mode
        });
        break;
      case helper.EMediaMode.ACCOUNT:
        this.goTo.emit({
          originalEvent: event,
          whereToGo: helper.EGoTo.MEDIA_MANAGEMENT,
          mode: this.configuration?.mode
        });
        break;
      case helper.EMediaMode.ADVERTISER:
        this.goTo.emit({
          originalEvent: event,
          whereToGo: helper.EGoTo.ADD_ADVERTISER,
          mode: this.configuration?.mode
        });
        break;
      case helper.EMediaMode.VERSION:
        this.goTo.emit({
          originalEvent: event,
          whereToGo: helper.EGoTo.NEW_VERSION,
          mode: this.configuration?.mode,
          total:  (!this.isSearchEnabled) ? this.totalMediaRows : this.oldTotalMediaRows
        });
        break;
    }
    this.hidePanel();
  }
  getSubTypeIconClass(subType: helper.EMediaSubType): string {
    let cls: string;
    switch (subType) {
      case helper.EMediaSubType.FILE:
        cls = 'bg-icons pi pi-file';
        break;
      case helper.EMediaSubType.LINK:
        cls = 'bg-icons pi pi-external-link';
        break;
      case helper.EMediaSubType.TEXT:
        cls = 'pi pi-comment';
        break;
      case helper.EMediaSubType.SHOPIFY:
        cls = 'pi pi-money-bill';
        break;
      case helper.EMediaSubType.WIX:
        cls = 'pi pi-compass';
        break;
      case helper.EMediaSubType.SYSTEM:
        cls = 'pi pi-share-alt';
        break;
      case helper.EMediaSubType.AGENCY:
        cls = 'pi pi-users';
        break;
      case helper.EMediaSubType.CLIENT:
        cls = 'pi pi-user';
        break;
      case helper.EMediaSubType.MICROSOFT:
        cls = 'pi pi-microsoft';
        break;
      case helper.EMediaSubType.GOOGLE:
        cls = 'pi pi-google';
        break;
      case helper.EMediaSubType.FACEBOOK:
        cls = 'pi pi-facebook';
        break;
      default:
        cls = 'bg-icons pi pi-external-link';
        break;
    }
    return `${cls}`.trim();
  }

  getTypeIconClass(mediaType: helper.EMediaType): string {
    let cls: string;
    switch (mediaType) {
      case helper.EMediaType.MCC:
        cls = 'pi pi-share-alt';
        break;
      default:
        cls = 'empty-icon';
        break;
    }
    return `${cls}`.trim();
  }


  addTooltipToSelectedMedia(text?: string, el?: any): string {
    if (helper.isOverflowing(el)) {
      return text || '';
    }
    return '';
  }
  trackByMethod(index: any, row: any): any {
    return row.key;
  }
  ngOnInit(): void {
    this.internalService.openOtherPanel
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((event: any) => {
        if (this.overlayPanel &&  this.isPanelOpen && this.actualTarget !== event) {
          this.hidePanel();
        }
      });
    // console.log({cfg: this.configuration});
    this.defaultLabel = this.configuration?.defaultLabel || '';
    this.additionalMenu = _.clone(this.configuration?.additionalMenu) || [];
    this.editObject.mode = this.configuration?.mode || helper.EMediaMode.NONE;
    this.editObject.row = this.selectedMedia;
    this.editObject.changedProp = this.editableProperty || '';
    this.searchParam.mode = this.configuration?.mode || helper.EMediaMode.NONE;
    this.loadParam.mode = this.configuration?.mode || helper.EMediaMode.NONE;
    this.searchResults = [];
    this.loadResults = [];
    if (this.autoload) {
      this.onLoadMore(true);
      if (this.selectedMedia && !this.notEmitSelected) {
        this.selectingMedia.emit({
          mode: this.configuration?.mode || helper.EMediaMode.NONE,
          row: this.selectedMedia,
          autoload: this.autoload
        });
      }
    }
  }

  onLoadMore(clean: boolean = false): void {
    if (!this.isSearchEnabled) {
      if (clean) {
        this.loadResults = [];
        this.loadParam.skip = 0;
      }
      this.loadParam.inProgress = true;
      this.loadParam.skip = this.loadResults.length;
      this.loadingMore.emit(this.loadParam);
    } else {
      this.searchParam.inProgress = true;
      this.searchParam.skip = this.searchResults.length;
      this.searching.emit(this.searchParam);
    }
  }


  private hidePanel(): void {
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }

  onHide(): void {
    this.isSearchEnabled = false;
    this.searchParam.inProgress = false;
    this.searchParam.searchQuery = '';
    this.searchResults = [];
    this.totalMediaRows =  this.oldTotalMediaRows;
    this.isPanelOpen = false;
  }

  onPanelOutsideClick(event: any): void {
    // function to close the panel when click outside, in order to fix auto hide issue
    // we can add more ids or classes to ignore the click
    const targetId = (event.target.id || '').toString();
    const innerText = (event.target.innerText || '').toString();
    const targetClass = (event.target.className || '').toString();
    const ignoreInnerTexts = ['load more'];
    const ignoreIds = ['panel-load-more-link', 'search-enabler-icon', 'search-enabler-input',
      'search-progress-icon', 'search-disabler-button', 'media-search-input'];
    const ignoreClasses = ['panel-load-more-link', 'search-disabler-button'];
    // console.log('onPanelOutsideClick: ', {targetId, targetClass, innerText});
    if (!ignoreInnerTexts.includes(innerText.toLowerCase()) &&
        !ignoreIds.includes(targetId) &&
        (!targetClass || !ignoreClasses.some(v => targetClass.indexOf(v) > -1))) {
      this.hidePanel();
    }
  }
  onClickOnLabel(event: any): void {
    this.editObject.row = this.selectedMedia || null;
    this.editObject.originValue = this.selectedMedia?.label || this.defaultLabel;
    this.editObject.changedValue = this.selectedMedia?.label || this.defaultLabel;
    // this.inplaceEdit?.activate(event);
    setTimeout(() => {this.labelEdit?.nativeElement?.focus();}, 10);
  }

  onEditFinished(event: any): void {
    // console.log('onEditFinished: ', {event});
    if (this.editObject?.changedValue === '' && this.editObject?.originValue) {
      this.editObject.changedValue = this.editObject?.originValue;
      this.inplaceEdit?.deactivate(event);
      return;
    }
    if (this.selectedMedia) {
      this.selectedMedia.label = this.editObject.changedValue;
      this.editObject.createNew = false;
    } else {
      this.editObject.createNew = true;
    }
    if (this.editObject?.originValue !== this.editObject?.changedValue ) {
      this.editing.emit(this.editObject);
    }
    this.inplaceEdit?.deactivate(event);
  }

  onEditCancel(event: Event): void {
    // console.log('Clicked outside:', event);
    this.editObject.changedValue = this.editObject.originValue;
    if (this.selectedMedia) {
      this.selectedMedia.label = this.editObject.originValue;
    }
    this.inplaceEdit?.deactivate(event);
  }
  get componentTemplate(): any {
    return this.elementRef?.nativeElement;
  }

  editFeedFromMenu(row: helper.IMediaRow): void {
    if (this.configuration?.editFeedCommand) {
      this.configuration?.editFeedCommand(row);
    }
  }
}
