import {Component, Inject, OnInit} from '@angular/core';
import {SchedulerComponent} from '../scheduler.component';
import * as helper from '../../shared/helper';

@Component({
  selector: 'aux-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss']
})
export class MonthlyComponent implements OnInit {

  param1: number | undefined;
  param2: number | undefined;
  constructor(@Inject(SchedulerComponent) public parent: SchedulerComponent) { }

  ngOnInit(): void {
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l > 1) {
      this.param1 = helper.safeListAt(this.parent.schedule?.repeaterOptions, 0);
      this.param2 = helper.safeListAt(this.parent.schedule?.repeaterOptions, 1);
    }else {
      this.param1 = 1;
      this.param2 = 1;
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions',  [this.param1, this.param2]);
    }
  }
  onInputParamChanged2(event: any): void {
    helper.safeToObjProp(this.parent.schedule, 'repeaterOptions',  [this.param1, event.value]);
  }

  onInputParamChanged1(event: any): void {
    helper.safeToObjProp(this.parent.schedule, 'repeaterOptions',  [event.value, this.param2]);
  }
  get suffix2(): string {
    return ` ${this.parent.translate('months')}`.toLowerCase();
  }
  get suffix1(): string {
    return ` ${this.parent.translate('day')}`.toLowerCase();
  }
}
