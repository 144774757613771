import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as helper from '../shared/helper';
import * as _ from 'lodash';

@Component({
  selector: 'aux-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss']
})
export class MerchantComponent implements OnInit {
  fileNamePattern: RegExp = /^\S+$/;
  @Input() disabled = false;
  @Input() toolBarActionKey: string | undefined;
  @Input() translateService: any | undefined;
  @Input() channel: string | undefined;
  @Input() ftpInfo: helper.IMerchantFtpInfo = {} as helper.IMerchantFtpInfo;
  @Output() ftpInfoChange = new EventEmitter<helper.IMerchantFtpInfo>(false);
  @Input()  exportFormats: string[] = [
    'tsv',
    'xml'
  ];
  private formErrors: any = null;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.ftpInfo) {
      throw new Error('merchant details not defined');
    }
    this.ftpInfo.isDirectUpload = false;
  }

  get exportFormat(): string {
    return this.ftpInfo.export_format === 'csv' && !this.exportFormats.includes('csv')  ? 'tsv' : this.ftpInfo.export_format;
  }

  set exportFormat(value: string) {
    this.ftpInfo.export_format = value.trim();
  }

  get ftpUser(): string {
    return this.ftpInfo.ftpUser?.trim();
  }

  set ftpUser(value: string) {
    this.ftpInfo.ftpUser = value.trim();
  }

  get ftpPassword(): string {
    return this.ftpInfo.ftpPassword?.trim();
  }

  set ftpPassword(value: string) {
    this.ftpInfo.ftpPassword = value.trim();
  }

  get feedName(): string {
    return this.ftpInfo.feedName?.trim();
  }

  set feedName(value: string) {
    this.ftpInfo.feedName = value.trim();
  }

  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }


  getToolTip(key: string, same?: boolean): string {
    if (this.toolBarActionKey && !same) {
      // console.log(this.toolBarActionKey, ' translations ', ` ng-${this.toolBarActionKey}-${key}`.toUpperCase());
      return this.translate(`ng-tooltip-${this.toolBarActionKey}-${key}`);
    }
    if (same) {
      return this.translate(`ng-merchant-tooltip-${key}`);
    }
    return '';
  }
  cleanErrors(): void {
    this.formErrors = null;
  }

  addError(errKey: string, errMsg: string): void {
    if (!this.formErrors) {
      this.formErrors = {};
    }
    if (!this.formErrors[errKey]) {
      this.formErrors[errKey] = [];
    }
    this.formErrors[errKey].push(errMsg);
  }

  getError(errKey: string): string[] {
    if (this.formErrors) {
      return this.formErrors[errKey] || [];
    }
    return [];
  }

  public validate(): void {
    this.formErrors = null;
    if (_.isEmpty(this.ftpInfo?.feedName)) {
      this.addError('feedName', 'REQUIRED');
    }
    if (!_.isEmpty(this.ftpInfo?.feedName) && !this.fileNamePattern.test(this.ftpInfo?.feedName)) {
      this.addError('feedName', 'FEED_FILE_NAME_EXPLANATION');
    }
    if (_.isEmpty(this.ftpInfo?.ftpPassword)) {
      this.addError('ftpPassword', 'REQUIRED');
    }

    if (_.isEmpty(this.ftpInfo?.ftpUser)) {
      this.addError('ftpUser', 'REQUIRED');
    }

    if (_.isEmpty(this.ftpInfo?.export_format)) {
      this.addError('export_format', 'REQUIRED');
    }
  }

  public get isValid(): boolean {
    return !this.formErrors || Object.keys(this.formErrors).length === 0;
  }
}
