import {Component, Inject, Input, OnInit} from '@angular/core';
import {SchedulerComponent} from '../scheduler.component';
import * as _ from 'lodash';
import * as helper from '../../shared/helper';

@Component({
  selector: 'aux-hourly',
  templateUrl: './hourly.component.html',
  styleUrls: ['./hourly.component.scss']
})
export class HourlyComponent implements OnInit {
  @Input() classStyle = '';
  param: number | undefined;
  params: any[] = [];
  constructor(@Inject(SchedulerComponent) public parent: SchedulerComponent) { }

  ngOnInit(): void {
    this.params = [
      {
        key: 1,
        label: '1h',
        inactive: !this.parent.isAdmin
      },
      {
        key: 3,
        label: '3h',
        inactive: !this.parent.isAdmin
      },
      {
        key: 6,
        label: '6 hours (recommended)'
      },
      {
        key: 12,
        label: '12h'
      },
      {
        key: 24,
        label: '24h'
      }
    ];
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l) {
      this.param = this.backCompatibility;
    }else {
      this.param = 12;
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param]);
    }
  }

  private get backCompatibility(): number {
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l) {
      const v = helper.safeListAt(this.parent.schedule?.repeaterOptions, 0);
      if (_.inRange(v, 1, 2)) {
        return 1;
      } else if (_.inRange(v, 2, 4)) {
        return 3;
      } else if (_.inRange(v, 4, 8)) {
        return 6;
      } else if (_.inRange(v, 8, 16)) {
        return 12;
      } else {
        return 24;
      }
    }

    return 12;
  }
  onParamValueChange(event: any): void  {
    helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [event.value]);
  }

  get selectedLabel(): string {
    const p = this.param || 12;
    const item = _.find(this.params, {key: p});
    return item?.label || '';
  }
}

