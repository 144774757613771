import {Component, Input, AfterContentChecked, OnInit} from '@angular/core';

@Component({
  selector: 'aux-svg-gauge',
  templateUrl: './svg-gauge.component.html',
  styleUrls: ['./svg-gauge.component.scss']
})
export class SvgGaugeComponent implements AfterContentChecked, OnInit {
  @Input() gradId = 'gauge-grad1';
  @Input() width = 0;
  @Input() height = 0;
  @Input() offSet = 15;
  @Input() rotate = 0;
  @Input() fontSize = 20;
  @Input() isDigitsScale = false;
  @Input() reverseGradient = false;
  @Input() scaleMin = 0;
  @Input() scaleMax = 10;

  stopColors = [
    {color: '#f98989', offset: '0'},
    {color: '#f3cd71', offset: '0.163'},
    {color: '#9ddc6d', offset: '0.489'},
    {color: '#87d6af', offset: '0.784'},
    {color: '#38dcdc', offset: '1'},
  ];

  needlePath =  '';
  needleRadius = 0;
  needleLength = 0;
  needleCenter: any = {};
  arcD = '';
  digits: any[] = [];
  needRotation = '';
  base = 0;
  ngOnInit(): void {
    if (this.reverseGradient) {
      this.stopColors = [
        {color: '#38dcdc', offset: '0'},
        {color: '#87d6af', offset: '0.163'},
        {color: '#9ddc6d', offset: '0.489'},
        {color: '#f3cd71', offset: '0.784'},
        {color: '#f98989', offset: '1'},
      ];
    }
    this.base = this.width / 2 > this.height ? this.height : this.width / 2;
    this.needleRadius = this.base * 0.055;
    this.needleLength = this.base / 2.7;
    this.needleCenter = {
      x: this.width / 2,
      y: this.height - this.needleRadius
    };
    const needlePeak = {
      x: this.needleCenter.x - this.needleLength,
      y: this.height - this.needleRadius
    };
    const angle = Math.atan(this.needleRadius / this.needleLength);
    const needlePointOne = {
      x: this.needleCenter.x - this.needleRadius * Math.sin(angle),
      y: this.needleCenter.y - this.needleRadius * Math.cos(angle)
    };
    const needlePointTwo = {
      x: this.needleCenter.x - this.needleRadius * Math.sin(angle),
      y: this.needleCenter.y + this.needleRadius * Math.cos(angle)
    };
    this.needlePath = `M${needlePeak.x} ${needlePeak.y} ` +
      `L${needlePointOne.x} ${needlePointOne.y} ` +
      `L${needlePointTwo.x} ${needlePointTwo.y}`;

    this.needRotation = `rotate(${this.rotate} ${this.needleCenter.x} ${this.needleCenter.y})`;
    if (this.isDigitsScale && this.scaleMin && this.scaleMax) {
      const minValue = this.scaleMin.toFixed(1);
      const middleValue = ((this.scaleMin + this.scaleMax) / 2).toFixed(1);
      const fontWidth = 2;
      this.digits = [
        {
          value: this.isTodayCTR() ? `${minValue}%` : minValue,
          x: this.width / 2 - this.base - minValue.length * fontWidth - 5, y: this.height - 2
        },
        {
          value: this.isTodayCTR() ? `${middleValue}%` : middleValue,
          x: this.width / 2 - middleValue.length * fontWidth - 5, y: 10
        },
        {
          value: this.isTodayCTR() ? `${(this.scaleMax).toFixed(1)}%` : (this.scaleMax).toFixed(1),
          x: this.width / 2 + this.base - this.offSet, y: this.height - 2},
      ];
    }
  }

  getAngle(min: number, max: number, value: number): number {
    if (value < min) {
      return 0;
    }
    if (value > max) {
      return 180;
    }
    return 180 * (value - min) / (max - min);
  }

  ngAfterContentChecked(): void {
    const arcWidth = this.base * 0.099;
    const x1 =  this.width / 2 - (this.base - this.offSet * 1.5);
    const x2 = this.width / 2 + this.base - this.offSet * 1.5;
    const y = this.height - arcWidth / 2;
    this.arcD = `M ${x1} ${y} ` +
      `A 1 1, 0, 0 1, ${x2} ${y} ` +
      `A 1 1, 0, 0 1, ${x2 - arcWidth} ${y} ` +
      `A 1 1, 0, 0 0, ${x1 + arcWidth} ${y} ` +
      `A 1 1, 0, 0 1, ${x1} ${y} `;

  }

  isTodayCTR(): boolean {
    return this.gradId === 'svgGaugeTodayCTR';
  }
}
