import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {MenuItem} from 'primeng/api';
import * as helper from '../shared/helper';
import moment from 'moment';
import * as _ from 'lodash';
import {InputNumber} from 'primeng/inputnumber';

// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
  selector: 'aux-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss']
})
export class TimePeriodComponent implements OnInit, DoCheck, OnChanges {
  @ViewChild('op') overlayPanel: OverlayPanel | undefined;
  @ViewChild('deltaToday') deltaToday: InputNumber | undefined;
  @ViewChild('deltaYesterday') deltaYesterday: InputNumber | undefined;
  @Output() changingTimePeriod: EventEmitter<helper.ITimePeriod> = new EventEmitter<helper.ITimePeriod>(false);
  @Input() disabled = false;
  @Input() includeLastRun = false;
  @Input() includeLast90Days = false;
  @Input() translateService: any;
  @Input() allTimeYears = 20;
  @Input() selectedTimePeriod: helper.ITimePeriod | undefined;
  @Input() toDateFlag: 'yesterday' | 'today' = 'yesterday';
  @Input() lastScanTime: string | undefined;
  timePeriod: helper.ETimePeriod | undefined;
  customDate: helper.ICustomDate = {};
  private prevCustomDate: helper.ICustomDate = {};
  @Input() appendTo: string | null = null;
  @Input() changeOnInit = false;
  periods: MenuItem[] = [
    {
      id: helper.ETimePeriod.TODAY,
      command: this.menuItemClick.bind(this)
    },
    {
      id: helper.ETimePeriod.YESTERDAY,
      command: this.menuItemClick.bind(this)
    },
    {
      id: 'THIS_WEEK',
      // fragment:  helper.ETimePeriod.THIS_WEEK_SUN_TODAY,
      items: [
        {id: helper.ETimePeriod.THIS_WEEK_SUN_TODAY, command: this.menuItemClick.bind(this)},
        {id: helper.ETimePeriod.THIS_WEEK_MON_TODAY, command: this.menuItemClick.bind(this)}
      ]
    },
    {
      id: helper.ETimePeriod.LAST_7_DAYS,
      command: this.menuItemClick.bind(this)
    },
    {
      id: 'PREV_WEEK',
      // fragment:  helper.ETimePeriod.LAST_WEEK_SUN_SAT,
      items: [
        {id: helper.ETimePeriod.LAST_WEEK_SUN_SAT, command: this.menuItemClick.bind(this)},
        {id: helper.ETimePeriod.LAST_WEEK_MON_SUN, command: this.menuItemClick.bind(this)},
      ]
    },
    {
      id: helper.ETimePeriod.LAST_14_DAYS,
      command: this.menuItemClick.bind(this)
    },
    {
      id: helper.ETimePeriod.THIS_MONTH,
      command: this.menuItemClick.bind(this)
    },
    {
      id: helper.ETimePeriod.LAST_30_DAYS,
      command: this.menuItemClick.bind(this)
    },
    {
      id: helper.ETimePeriod.LAST_MONTH,
      command: this.menuItemClick.bind(this)
    },
    {
      id: helper.ETimePeriod.ALL_TIME,
      command: this.menuItemClick.bind(this)
    }];
  isPanelOpen = false;
  private isCalendarShown = false;
  datePickerFormat = 'dd-mm-yy';
  private datePickerConvertFormat = 'DD-MM-YYYY';
  today = new Date();
  private dateReportFormat = 'YYYYMMDD';
  isShowCustomDate = false;

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
  }

  private isEqualTimePeriod(currentValue: helper.ITimePeriod | undefined): boolean {
    return _.isEqual(currentValue?.type, this.timePeriod) && _.isEqual(this.customDate, currentValue?.customDate);
  }

  onShowDatePicker(event: any): void {
    this.isCalendarShown = true;
  }

  onClose(event: any): void {
    this.isCalendarShown = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log({changes});
    if (changes.selectedTimePeriod && !this.isEqualTimePeriod(changes.selectedTimePeriod.currentValue) && !this.isPanelOpen) {
      this.initTimePeriod();
    }
  }

  ngDoCheck(): void {
    this.periods.forEach((child: MenuItem) => this.updateMenuItems(child));
  }

  get isShowFooterButtons(): boolean {
    // console.log(this.isCustomDateChosen, this.isCustomDateUpTodayChosen , this.isCustomDateUpYesterdayChosen);
    return Boolean((this.customDate.from && this.customDate.to) || this.customDate.deltaToday || this.customDate.deltaYesterday);
  }

  onShow(): void {
    this.prevCustomDate = _.cloneDeep(this.customDate);
  }

  clickedOutside(): void {
    // console.log('clickedOutside');
    if (this.isPanelOpen && !this.isCalendarShown) {
      this.customDate = _.cloneDeep(this.prevCustomDate);
    }
  }

  private updateMenuItems(item: MenuItem): void {
    if (item && item.id) {
      if (!item.items) {
        item.label = this.translate(item.id);
        item.styleClass = (this.timePeriod === item.id) ? 'time-period-active' : undefined;
      } else {
        const kid: MenuItem | undefined = item.items.find(i => this.timePeriod === i.id);
        item.styleClass = (kid) ? 'time-period-active' : undefined;
        item.label = this.translate(kid?.id || item.items[0].id);
      }
      item.items?.forEach((child: MenuItem) => this.updateMenuItems(child));
    }
  }

  get isCustomDateChosen(): boolean {
    return this.isShowCustomDate ||
      Boolean(this.customDate.from && this.customDate.to && !this.customDate.deltaToday && !this.customDate.deltaYesterday);
  }

  get isCustomDateUpTodayChosen(): boolean {
    return Boolean(!this.customDate.from && !this.customDate.to && !this.customDate.deltaYesterday && this.customDate.deltaToday);
  }

  get isCustomDateUpYesterdayChosen(): boolean {
    return Boolean(!this.customDate.from && !this.customDate.to && !this.customDate.deltaToday && this.customDate.deltaYesterday);
  }

  customDateClicked(event: any): void {
    this.stopPropagation(event);
    this.prevCustomDate = _.cloneDeep(this.customDate);
    this.isShowCustomDate = true;
    this.customDate.deltaToday = null;
    this.customDate.deltaYesterday = null;
    if (this.deltaYesterday) {
      this.deltaYesterday.writeValue(null);
    }
    if (this.deltaToday) {
      this.deltaToday.writeValue(null);
    }
  }

  deltaTodayFocus(event: any): void {
    // console.log('deltaTodayFocus', {event});
    this.stopPropagation(event);
    const delta = event.value || this.customDate.deltaToday || 30;
    this.prevCustomDate = _.cloneDeep(this.customDate);
    this.customDate.from = undefined;
    this.customDate.to = undefined;
    this.isShowCustomDate = false;
    this.customDate.deltaToday = delta;
    this.customDate.deltaYesterday = null;
    if (this.deltaToday) {
      this.deltaToday.writeValue(delta);
    }
    if (this.deltaYesterday) {
      this.deltaYesterday.writeValue(null);
    }
  }

  deltaYesterdayFocus(event: any): void {
    // console.log('deltaYesterdayFocus', {event});
    this.stopPropagation(event);
    const delta = event.value || this.customDate.deltaYesterday || 30;
    this.prevCustomDate = _.cloneDeep(this.customDate);
    this.customDate.from = undefined;
    this.customDate.to = undefined;
    this.isShowCustomDate = false;
    if (this.deltaToday) {
      this.deltaToday.writeValue(null);
    }
    if (this.deltaYesterday) {
      this.deltaYesterday.writeValue(delta);
    }
    this.customDate.deltaToday = null;
    this.customDate.deltaYesterday = delta;
  }

  menuItemClick(event: any): void {
    // console.log({event});
    if (this.timePeriod) {
      const item: MenuItem = event.item;
      this.timePeriod = item.id as helper.ETimePeriod;
      this.customDate = {};
      this.prevCustomDate = {};
      this.isShowCustomDate = false;
      this.changingTimePeriod.emit({
        type: this.timePeriod,
      });
    }
    this.hidePanel();
  }

  private convertCustomDate(dt?: string): string | undefined {
    if (dt) {
      const m = moment(dt, this.dateReportFormat);
      return m.format(this.datePickerConvertFormat);
    }
    return undefined;
  }

  private initTimePeriod(): void {
    this.customDate = {
      from: this.convertCustomDate(this.selectedTimePeriod?.customDate?.from),
      to: this.convertCustomDate(this.selectedTimePeriod?.customDate?.to),
      deltaYesterday: this.selectedTimePeriod?.customDate?.deltaYesterday,
      deltaToday: this.selectedTimePeriod?.customDate?.deltaToday
    }; // _.cloneDeep(this.selectedTimePeriod?.customDate || {});
    this.timePeriod = this.selectedTimePeriod?.type;
    this.isShowCustomDate = false;
  }

  ngOnInit(): void {
    if (this.includeLastRun) {
      this.periods.unshift(
        {
          id: helper.ETimePeriod.LAST_RUN,
          command: this.menuItemClick.bind(this)
        }
      );
    }
    if (this.includeLast90Days) {
      this.periods.unshift(
        {
          id: helper.ETimePeriod.LAST_90_DAYS,
          command: this.menuItemClick.bind(this)
        }
      );
    }
    if (this.changeOnInit && this.selectedTimePeriod) {
      this.initTimePeriod();
      const e: any = {type: this.timePeriod || helper.ETimePeriod.NONE};
      if ([helper.ETimePeriod.CUSTOM_DATE, helper.ETimePeriod.CUSTOM_DATE_UP_TO_TODAY,
        helper.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY].includes(e.type)) {
        e.customDate = this.customDate;
      }
      this.changingTimePeriod.emit(e);
    }
  }

  private hidePanel(): void {
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }

  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }

  toggleOverlayPanel(event: any, target: any): void {
    if (this.overlayPanel && !this.disabled) {
      this.isPanelOpen = true;
      this.overlayPanel.toggle(event, target);
    }
  }

  onHide(): void {
    this.isPanelOpen = false;
  }

  get periodLabel(): helper.ETimePeriod {
    if ([helper.ETimePeriod.CUSTOM_DATE,
      helper.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY,
      helper.ETimePeriod.CUSTOM_DATE_UP_TO_TODAY].includes(this.timePeriod || helper.ETimePeriod.NONE)) {
      return helper.ETimePeriod.CUSTOM_DATE;
    }
    return this.timePeriod || helper.ETimePeriod.NONE;
  }

  get selectionDisplay(): string {
    if (this.fromDate && this.toDate) {
      // console.log(this.selectedTimePeriod.type, ' days :', this.toDate.diff(this.fromDate, 'days'));
      if ([helper.ETimePeriod.TODAY, helper.ETimePeriod.YESTERDAY].includes(this.timePeriod || helper.ETimePeriod.NONE)
        || this.toDate.diff(this.fromDate, 'days') === 0) {
        return `${this.fromDate.format('DD.MM.YY')}`;
      }
      return (this.fromDate.year() === this.toDate.year()) ?
        `${this.fromDate.format('DD.MM')} — ${this.toDate.format('DD.MM.YY')}` :
        `${this.fromDate.format('DD.MM.YY')} — ${this.toDate.format('DD.MM.YY')}`;
    }
    return '';
  }
  private stopPropagation(event: any): void {
    try{
      event?.preventDefault();
      event?.stopPropagation();
    }catch (e) {
    }
  }
  applyCustomDate(event: any): void {
    this.stopPropagation(event);
    let customDate: helper.ICustomDate | undefined;
    if (this.isCustomDateUpTodayChosen) {
      this.timePeriod = helper.ETimePeriod.CUSTOM_DATE_UP_TO_TODAY;
      customDate = _.cloneDeep(this.customDate);
    } else if (this.isCustomDateUpYesterdayChosen) {
      this.timePeriod = helper.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY;
      customDate = _.cloneDeep(this.customDate);
    } else if (this.isCustomDateChosen) {
      this.timePeriod = helper.ETimePeriod.CUSTOM_DATE;
      customDate = {
        from: this.fromDate.format(this.dateReportFormat),
        to: this.toDate.format(this.dateReportFormat)
      };
    }

    this.changingTimePeriod.emit({
      type: this.timePeriod || helper.ETimePeriod.NONE,
      customDate
    });
    this.hidePanel();
  }

  private get fromDate(): moment.Moment {
    let resDate = moment().startOf('day');
    const yesterday = moment().subtract(1, 'd').endOf('day');
    switch (this.timePeriod) {
      case helper.ETimePeriod.CUSTOM_DATE:
        resDate = moment(this.customDate.from, this.datePickerConvertFormat);
        break;
      case helper.ETimePeriod.CUSTOM_DATE_UP_TO_TODAY:
        resDate = moment().subtract(this.customDate.deltaToday, 'd');
        break;
      case helper.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY:
        resDate = yesterday.subtract(this.customDate.deltaYesterday, 'd');
        break;
      case helper.ETimePeriod.LAST_RUN:
        resDate = moment(this.convertCustomDate(this.lastScanTime), this.datePickerConvertFormat);
        break;
      case helper.ETimePeriod.TODAY:
        break;
      case helper.ETimePeriod.YESTERDAY:
        resDate = moment().subtract(1, 'd');
        break;
      case helper.ETimePeriod.THIS_WEEK_SUN_TODAY:
        resDate = moment().day('Sunday');
        break;
      case helper.ETimePeriod.THIS_WEEK_MON_TODAY:
        resDate = moment().day('Monday');
        break;
      case  helper.ETimePeriod.LAST_7_DAYS:
        resDate = moment().subtract(7, 'd');
        break;
      case  helper.ETimePeriod.LAST_90_DAYS:
        resDate = moment().subtract(90, 'd');
        break;
      case  helper.ETimePeriod.LAST_WEEK:
        resDate = moment().day(-6);
        break;
      case  helper.ETimePeriod.LAST_BUSINESS_WEEK:
        resDate = moment().day('Monday').subtract(5, 'd');
        break;
      case  helper.ETimePeriod.LAST_WEEK_SUN_SAT:
        resDate = moment().day(-7);
        break;
      case  helper.ETimePeriod.LAST_WEEK_MON_SUN:
        resDate = moment().day(-6);
        break;
      case  helper.ETimePeriod.LAST_14_DAYS:
        resDate = moment().subtract(14, 'd');
        break;
      case  helper.ETimePeriod.THIS_MONTH:
        resDate = moment().startOf('month');
        break;
      case helper.ETimePeriod.LAST_30_DAYS:
        resDate = moment().subtract(30, 'd');
        break;
      case helper.ETimePeriod.LAST_MONTH:
        resDate = moment().startOf('month').subtract(1, 'M');
        break;
      case helper.ETimePeriod.ALL_TIME:
        resDate = moment().startOf('year').subtract(this.allTimeYears, 'years');
        break;
    }
    return resDate;
  }

  private get toDate(): moment.Moment {
    let resDate = moment().endOf('day');
    const yesterday = moment().subtract(1, 'd').endOf('day');
    switch (this.timePeriod) {
      case helper.ETimePeriod.CUSTOM_DATE:
        resDate = moment(this.customDate.to, this.datePickerConvertFormat);
        break;
      case helper.ETimePeriod.CUSTOM_DATE_UP_TO_TODAY:
        break;
      case helper.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY:
        resDate = yesterday;
        break;
      case helper.ETimePeriod.LAST_RUN:
        resDate = moment(this.convertCustomDate(this.lastScanTime), this.datePickerConvertFormat);
        break;
      case helper.ETimePeriod.TODAY:
        // resDate = moment().endOf('day');
        break;
      case helper.ETimePeriod.YESTERDAY:
        resDate = yesterday;
        break;
      case helper.ETimePeriod.THIS_WEEK_SUN_TODAY:
        break;
      case helper.ETimePeriod.THIS_WEEK_MON_TODAY:
        break;
      case  helper.ETimePeriod.LAST_7_DAYS:
        if (this.toDateFlag === 'yesterday') {
          resDate = yesterday;
        }
        break;
      case  helper.ETimePeriod.LAST_90_DAYS:
        if (this.toDateFlag === 'yesterday') {
          resDate = yesterday;
        }
        break;
      case  helper.ETimePeriod.LAST_WEEK:
        resDate = moment().day(0).endOf('day');
        break;
      case helper.ETimePeriod.LAST_BUSINESS_WEEK:
        resDate = moment().day(-2).endOf('day');
        break;
      case helper.ETimePeriod.LAST_WEEK_SUN_SAT:
        resDate = moment().day(-1);
        break;
      case  helper.ETimePeriod.LAST_WEEK_MON_SUN:
        resDate = moment().day(0).endOf('day');
        break;
      case helper.ETimePeriod.LAST_14_DAYS:
        if (this.toDateFlag === 'yesterday') {
          resDate = yesterday;
        }
        break;
      case helper.ETimePeriod.THIS_MONTH:
        // resDate = moment().endOf('month');
        break;
      case helper.ETimePeriod.LAST_30_DAYS:
        if (this.toDateFlag === 'yesterday') {
          resDate = yesterday;
        }
        break;
      case helper.ETimePeriod.LAST_MONTH:
        resDate = moment().subtract(1, 'M').endOf('month');
        break;
      case helper.ETimePeriod.ALL_TIME:
        break;
    }
    return resDate;
  }

  cancel(): void {
    this.customDate = _.cloneDeep(this.prevCustomDate);
    this.hidePanel();
  }

  get componentTemplate(): any {
    return (!this.appendTo) ? this.elementRef?.nativeElement : this.appendTo;
  }
}
