import {Injectable} from '@angular/core';
import {TourAnchorDirective} from './tour-anchor.directive';
import {Subject, Observable, merge as mergeStatic} from 'rxjs';
import {first, map, filter} from 'rxjs/operators';

export interface IStepOption {
  stepId?: string;
  anchorId?: string;
  title?: string;
  content?: string;
  stepIndex?: number;
  nextStep?: number | string;
  prevStep?: number | string;
  placement?: any; // for the future support
  preventScrolling?: boolean;
  prevBtnTitle?: string;
  nextBtnTitle?: string;
  nextBtnClass?: string;
  endBtnTitle?: string;
  category?: string;
  subCategory?: string;
  iconClass?: string;
}

export enum TourState {
  OFF,
  ON,
  PAUSED
}

@Injectable({
    providedIn: 'any'
  }
)
export class TourService<T extends IStepOption = IStepOption> {
  public stepShow$: Subject<T> = new Subject();
  public stepHide$: Subject<T> = new Subject();
  public initialize$: Subject<T[]> = new Subject();
  public start$: Subject<T> = new Subject();
  public end$: Subject<any> = new Subject();
  public pause$: Subject<T> = new Subject();
  public resume$: Subject<T> = new Subject();
  public anchorRegister$: Subject<string> = new Subject();
  public anchorUnregister$: Subject<string> = new Subject();
  public events$: Observable<{ name: string; value: any }> = mergeStatic(
    this.stepShow$.pipe(map(value => ({name: 'stepShow', value}))),
    this.stepHide$.pipe(map(value => ({name: 'stepHide', value}))),
    this.initialize$.pipe(map(value => ({name: 'initialize', value}))),
    this.start$.pipe(map(value => ({name: 'start', value}))),
    this.end$.pipe(map(value => ({name: 'end', value}))),
    this.pause$.pipe(map(value => ({name: 'pause', value}))),
    this.resume$.pipe(map(value => ({name: 'resume', value}))),
    this.anchorRegister$.pipe(
      map(value => ({
        name: 'anchorRegister',
        value
      }))
    ),
    this.anchorUnregister$.pipe(
      map(value => ({
        name: 'anchorUnregister',
        value
      }))
    )
  );

  private steps: T[] = [];
  public currentStep?: T;

  public anchors: { [anchorId: string]: TourAnchorDirective } = {};
  private status: TourState = TourState.OFF;
  private isHotKeysEnabled = false;

  constructor() {
  }

  public initialize(steps: T[], stepDefaults?: T): void {
    if (steps && steps.length > 0) {
      this.status = TourState.OFF;
      this.steps = steps.map((step: T) => Object.assign({}, stepDefaults, step));
      this.initialize$.next(this.steps);
    }
  }

  public get isInitialized(): boolean {
    return this.steps && this.steps.length > 0;
  }

  public disableHotkeys(): void {
    this.isHotKeysEnabled = false;
  }

  public enableHotkeys(): void {
    this.isHotKeysEnabled = true;
  }

  public start(): void {
    this.startAt(0);
  }

  public startAt(stepId: number | string): void {
    this.status = TourState.ON;
    this.goToStep(this.loadStep(stepId));
    // @ts-ignore
    this.start$.next();
  }

  public end(): void {
    this.status = TourState.OFF;
    this.hideStep(this.currentStep);
    this.currentStep = undefined;
    // @ts-ignore
    this.end$.next();
  }

  public stop(): void {
    const step = this.currentStep;
    // @ts-ignore
    const anchor = this.anchors[step && step.anchorId];
    if (anchor) {
      anchor.temporaryConceal();
    }
    this.status = TourState.OFF;
    this.currentStep = undefined;
    this.steps = [];
  }

  public pause(): void {
    this.status = TourState.PAUSED;
    this.hideStep(this.currentStep);
    // @ts-ignore
    this.pause$.next();
  }

  public resume(): void {
    this.status = TourState.ON;
    this.showStep(this.currentStep);
    // @ts-ignore
    this.resume$.next();
  }

  public toggle(pause?: boolean): void {
    if (pause) {
      if (this.currentStep) {
        this.pause();
      } else {
        this.resume();
      }
    } else {
      if (this.currentStep) {
        this.end();
      } else {
        this.start();
      }
    }
  }

  public next(): void {
    if (this.hasNext(this.currentStep)) {
      this.goToStep(
        this.loadStep(
          // @ts-ignore
          this.currentStep.nextStep || this.steps.indexOf(this.currentStep) + 1
        )
      );
    }else{
      this.end();
    }
  }

  public hasNext(step: T | undefined): boolean {
    if (!step) {
      console.warn('Can\'t get next step. No currentStep.');
      return false;
    }
    return (
      step.nextStep !== undefined ||
      this.steps.indexOf(step) < this.steps.length - 1
    );
  }

  public prev(): void {
    if (this.hasPrev(this.currentStep)) {
      this.goToStep(
        this.loadStep(
          // @ts-ignore
          this.currentStep?.prevStep || this.steps.indexOf(this.currentStep) - 1
        )
      );
    } else {
      this.end();
    }
  }

  public hasPrev(step: T | undefined): boolean {
    if (!step) {
      console.warn('Can\'t get previous step. No currentStep.');
      return false;
    }
    return step.prevStep !== undefined || this.steps.indexOf(step) > 0;
  }

  public goto(stepId: number | string): void {
    this.goToStep(this.loadStep(stepId));
  }

  private goToStep(step: T | undefined): void {
    if (!step) {
      console.warn('Can\'t go to non-existent step');
      this.end();
      return;
    }
    setTimeout(() => this.setCurrentStep(step));
  }

  public register(anchorId: string, anchor: TourAnchorDirective): void {
    if (!anchorId) {
      return;
    }
    if (!this.anchors[anchorId]) {
      // throw new Error('anchorId ' + anchorId + ' already registered!');
      // console.warn(`anchorId ${anchorId} already registered!`);
      // } else {
      this.anchors[anchorId] = anchor;
      this.anchorRegister$.next(anchorId);
    }
  }

  public unregister(anchorId: string): void {
    if (!anchorId) {
      return;
    }
    delete this.anchors[anchorId];
    this.anchorUnregister$.next(anchorId);
  }

  public getStatus(): TourState {
    return this.status;
  }

  public isHotkeysEnabled(): boolean {
    return this.isHotKeysEnabled;
  }

  private loadStep(stepId: number | string): T | undefined {
    if (typeof stepId === 'number') {
      return this.steps[stepId];
    } else {
      return this.steps.find(step => step.stepId === stepId);
    }
  }

  private setCurrentStep(step: T): void {
    if (this.currentStep) {
      this.hideStep(this.currentStep);
    }
    this.currentStep = step;
    this.showStep(this.currentStep);
  }

  private showStep(step: T | undefined): void {
    // @ts-ignore
    const anchor = this.anchors[step && step.anchorId];
    if (!anchor) {
      console.warn(
        'Can\'t attach to unregistered anchor with id ' + step?.anchorId
      );
      this.end();
      return;
    }
    anchor.showTourStep(step);
    // @ts-ignore
    this.stepShow$.next(step);
  }

  private hideStep(step: T | undefined): void {
    // @ts-ignore
    const anchor = this.anchors[step && step.anchorId];
    if (!anchor) {
      return;
    }
    anchor.hideTourStep(step);
    // @ts-ignore
    this.stepHide$.next(step);
  }

  public get totalSteps(): number {
    // console.log({steps: this.steps});
    return (this.steps) ? Math.max(...this.steps.map((s: any) => s.stepIndex)) + 1 : 0;
  }
  public getStepIndex(step: T): number {
    if (!step || !this.steps || !this.steps.length) {
      return -1;
    }
    return this.steps.indexOf(step);
  }
}
