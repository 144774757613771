import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'aux-digit-code',
  templateUrl: './digit-code.component.html',
  styleUrls: ['./digit-code.component.scss']
})
export class DigitCodeComponent implements OnInit {
  @Input() amount = 4; // default
  @Input() disabled = false;
  readonly classPrefixForInputFields = 'cCx-i';
  readonly idPrefixForInputFields = 'iXx-i';
  @Output() applyingCode: EventEmitter<string> = new EventEmitter<string>();
  codeParams: any[] = [];
  constructor(private elementRef: ElementRef) {
  }
  ngOnInit(): void {
    this.codeParams = Array(this.amount).fill('');
  }
  public get accessCode(): string {
    return this.codeParams.join('').trim();
  }
  onKeyUp(event: any, index: number, last: boolean): void {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46 ||    // backspace or delete
      (key === 8 && 17) ||          // backspace + ctrl
      (key === 8 && 16) ||          // backspace + shift
      (key === 46 && 17))     // delete + ctrl
    {
      return;
    }
    // console.log({index, event});
    if (!last) {
      const nextIndex = index + 1;
      if (this.codeParams.slice(0, nextIndex).join('').length === nextIndex) {
        this.codeParams[nextIndex] = '';
        this.elementRef.nativeElement.querySelector(`.${this.classPrefixForInputFields}${nextIndex}`).focus();
      }
    }else {
      if (this.accessCode.length === this.amount) {
        this.applyingCode.emit(this.accessCode);
      }
    }
  }

  trackByFn(index: number, item: any): number {
    return index;
  }
}
