import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Panel} from 'primeng/panel';
import {Subject} from 'rxjs';
import {ClosePropService} from '../shared/close-prop.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'aux-password-prop',
  templateUrl: './password-prop.component.html',
  styleUrls: ['./password-prop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordPropComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pp') panel: Panel | undefined;
  @Input() translateService: any | undefined;
  @Input() disabled = false;
  @Input() notResettable = false;
  @Input() notChangeable = false;
  @Input() showCurrentPwd = false;
  @Output() changingPassword = new EventEmitter<any>(false);
  @Output() changePwdDone = new EventEmitter<any>(false);
  @Output() resettingPassword = new EventEmitter<void>(false);
  pwdError: string | null = null;
  showPasswordFeedback = false;
  passwordCriteria = {
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialOrNumber: false
  };
  pass0 = '';
  pass1: string | undefined;
  pass2: string | undefined;
  private componentDestroyed: Subject<boolean> = new Subject();
  panelCollapsed = true;
  saving = false;
  constructor(private closePropService: ClosePropService, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }
  ngOnInit(): void {
    this.closePropService.openOtherProp
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((event: any) => {
        if ( event.key !== 'password') {
          if (!this.panel?.collapsed) {
            this.panelCollapsed = true;
          }
        }
      });
  }
  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }

  isValidPassword(): boolean {
    this.pwdError = null;
    if (!this.pass1) {
      this.pwdError = 'ENTER_VALID_PWD';
      return false;
    }
    if (this.pass1 && this.showPasswordFeedback) {
      this.pwdError = 'ENTER_STRONG_PWD';
      return false;
    }
    if (this.pass1 && this.pass1 !== this.pass2) {
      this.pwdError = 'PWD_NOT_MATCH';
      return false;
    }
    if (this.pass0 === this.pass1) {
      this.pwdError = 'ERROR_SAME_PWD';
      return false;
    }
    return true;
  }

  checkPasswordStrength(password: string): void {
    this.passwordCriteria.minLength = password.length >= 8;
    this.passwordCriteria.uppercase = /[A-Z]/.test(password);
    this.passwordCriteria.lowercase = /[a-z]/.test(password);
    this.passwordCriteria.specialOrNumber = /[0-9\W]/.test(password);
    // Hide the feedback if all criteria are met
    this.showPasswordFeedback = password.length > 0 && !Object.values(this.passwordCriteria).every(criteria => criteria);
    console.log(password, this.showPasswordFeedback);
  }

  resetPassword(event: any): void {
    if (!this.disabled) {
      this.closePropService.openOtherProp.emit({key: 'password', originalEvent: event});
      this.panel?.collapse();
      this.resettingPassword.emit();
    }
  }
  changeState(event: any): void {
    this.closePropService.openOtherProp.emit({key: 'password', originalEvent: event});
    if (!this.panel?.collapsed) {
      this.panel?.collapse();
    } else {
      this.panel?.toggle(event);
    }
  }
  changePassword(event: any): void {
    if (this.isValidPassword()) {
      this.saving = true;
      this.changingPassword.emit({
        currentPassword: this.pass0,
        newPassword_f: this.pass1,
        newPassword_s: this.pass2,
        callback: (res: any) => {
          if (res.result === 'success') {
            this.panel?.collapse();
            this.changePwdDone.emit();
          } else {
            this.pwdError = res.error;
          }
          this.saving = false;
        }
      });
    }
  }

  onAfterToggle(event: any): void {
    this.pass0 = '';
    this.pass1 = '';
    this.pass2 = '';
  }
}
