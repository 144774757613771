import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import * as helper from '../shared/helper';
import {EMediaMode} from '../shared/helper';

@Component({
  selector: 'aux-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('op') overlayPanel: OverlayPanel | undefined;
  @Input() iconization: boolean | undefined;
  @Input() data: helper.IProfile = {} as helper.IProfile;
  @Input() disabled = false;
  @Output() goTo: EventEmitter<helper.IGoTo> = new EventEmitter<helper.IGoTo>(false);

  get isShowProfileIcon(): boolean {
    if (this.iconization !== undefined) {
      return this.iconization;
    }
    return true;
  }

  constructor(private elementRef: ElementRef) {
  }

  private hidePanel(): void {
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }

  goToMyProfile(): void {
    this.goTo.emit({
      whereToGo: helper.EGoTo.MY_PROFILE,
      mode: EMediaMode.NONE
    });
    this.hidePanel();
  }

  goToPrivacy(): void {
    this.goTo.emit({
      whereToGo: helper.EGoTo.PRIVACY_POLICY,
      mode: EMediaMode.NONE
    });
    this.hidePanel();
  }

  goToSignOut(): void {
    this.goTo.emit({
      whereToGo: helper.EGoTo.SIGN_OUT,
      mode: EMediaMode.NONE
    });
    this.hidePanel();
  }

  toggleOverlayPanel(event: any, target: any): void {
    if (this.overlayPanel && !this.disabled) {
      this.overlayPanel.toggle(event, target);
    }
  }
  ngOnInit(): void {
  }

  get componentTemplate(): any {
    return this.elementRef?.nativeElement;
  }

}


