import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {map} from 'rxjs/operators';
import {MediaService} from './media.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
class AuthenticationGuardService  {
  constructor(private authService: AuthenticationService, private router: Router, private mediaService: MediaService,
              private deviceService: DeviceDetectorService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // const path = route.routeConfig?.path;
    // console.log({path});
    const urlTree = this.router.parseUrl(state.url);
    urlTree.queryParams = {};
    if (!this.mediaService.accessToken) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    return this.authService.validate(urlTree.toString()).pipe(map(valid => {
      if (!valid) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      }
      if (!this.deviceService.isDesktop() && !this.deviceService.isTablet()) {
        this.router.navigate(['/notDesktop']);
      }
      return valid;
    }));
  }
}

export const AuthenticationGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(AuthenticationGuardService).canActivate(next, state);
};
