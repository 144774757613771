<!--style="background-color: rgb(241, 241, 240);"-->
<svg [attr.width]="width" [attr.height]="height">
  <g>
    <path   [attr.transform]="needRotation"
            [attr.d]="needlePath" fill="var(--needlecolor)"/>
    <circle [attr.cx]="needleCenter.x" [attr.cy]="needleCenter.y"
            [attr.r]="needleRadius" fill="var(--needlecolor)" />
  </g>
  <g>
    <linearGradient [attr.id]="gradId"  x1="0.022" x2="1" gradientUnits="objectBoundingBox">
      <stop *ngFor="let stopColor of stopColors" [attr.offset]="stopColor.offset" [attr.stop-color]="stopColor.color"/>
    </linearGradient>
    <!-- Drawing an arc -->
    <path   [attr.d]="arcD" attr.fill="url(#{{gradId}})"></path>
  </g>
  <g *ngIf="isDigitsScale">
    <text *ngFor="let digit of digits" [attr.font-size]="fontSize"
          [attr.x]="digit.x" [attr.y]="digit.y">{{digit.value}}</text>
  </g>
</svg>
