import {Component, Inject, OnInit} from '@angular/core';
import {SchedulerComponent} from '../scheduler.component';
import * as helper from '../../shared/helper';
import moment from 'moment';

@Component({
  selector: 'aux-one-time',
  templateUrl: './one-time.component.html',
  styleUrls: ['./one-time.component.scss']
})
export class OneTimeComponent implements OnInit {
  minDate = new Date();
  param: any | undefined;
  constructor(@Inject(SchedulerComponent) public parent: SchedulerComponent) { }

  ngOnInit(): void {
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l) {
      this.param =  moment((l > 1) ?  helper.safeListAt(this.parent.schedule?.repeaterOptions, 1) :
        helper.safeListAt(this.parent.schedule?.repeaterOptions, 0)).toDate();
    }else {
      this.param = new Date();
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param]);
    }
  }

  onClose(event: any): void {
    if (this.param < new Date()) {
      // console.log('lower date time', {event, param: this.param});
      this.param = new Date();
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param]);
    }
  }
  onSelectCalendarValue(event: any): void {
    // console.log({event});
    helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [event]);
  }
}
