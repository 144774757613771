import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, Type} from '@angular/core';
import {TranslationService} from '../../helpers/translation.service';
import {MediaService} from '../../helpers/media.service';
import {ApiCallService} from '../../helpers/api-call.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import {Subject} from 'rxjs';
import {MessagingService} from '../../helpers/messaging.service';
import {Clipboard} from '@angular/cdk/clipboard';
import * as aux from 'adcore-auxiliary';
import {takeUntil} from 'rxjs/operators';
import * as cfg from '../../helpers/config';
import * as models from '../../helpers/models';
import {ToolbarService} from '../../helpers/toolbar.service';
import {Router} from '@angular/router';


// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({template: ''})
export class DialogBaseComponent implements AfterViewInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  protected cdr: ChangeDetectorRef = {} as ChangeDetectorRef;
  public appName: string | undefined | null = null;
  public translationService: TranslationService = {} as TranslationService;
  protected mediaService: MediaService  = {} as MediaService;
  protected messagingService: MessagingService  = {} as MessagingService;
  protected apiCallService: ApiCallService  = {} as ApiCallService;
  protected dialogRef: DynamicDialogRef = {} as DynamicDialogRef;
  protected dialogConfig: DynamicDialogConfig = {} as DynamicDialogConfig;
  protected confirmationService: ConfirmationService = {} as ConfirmationService;
  protected windowRefService: aux.WindowRefService = {} as aux.WindowRefService;
  protected toolbarService: ToolbarService = {} as ToolbarService;
  protected imgService: aux.ImageResizerService  = {} as aux.ImageResizerService;
  public users: any[] = [];
  saving = false;
  constructor(protected injector: Injector, public clipboard: Clipboard,  private elementRef: ElementRef, public router: Router) {
    this.cdr = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
    this.dialogRef = injector.get<DynamicDialogRef>(DynamicDialogRef as Type<DynamicDialogRef>);
    this.dialogConfig = injector.get<DynamicDialogConfig>(DynamicDialogConfig as Type<DynamicDialogConfig>);
    this.translationService = injector.get<TranslationService>(TranslationService as Type<TranslationService>);
    this.mediaService = injector.get<MediaService>(MediaService as Type<MediaService>);
    this.messagingService = injector.get<MessagingService>(MessagingService as Type<MessagingService>);
    this.apiCallService = injector.get<ApiCallService>(ApiCallService as Type<ApiCallService>);
    this.imgService = injector.get<aux.ImageResizerService>(aux.ImageResizerService as Type<aux.ImageResizerService>);
    this.windowRefService = injector.get< aux.WindowRefService>(aux.WindowRefService  as Type<aux.WindowRefService>);
    this.toolbarService = injector.get<ToolbarService>(ToolbarService  as Type<ToolbarService>);
    this.confirmationService = injector.get<ConfirmationService>(ConfirmationService as Type<ConfirmationService>);
    this.messagingService?.waitingCommandResults?.pipe(takeUntil(this.componentDestroyed))
      .subscribe((cr: models.ICommandResult) => {
        switch (cr.command) {
          case cfg.ECommandsFromNewUI.UpdateVersion:
          case cfg.ECommandsFromNewUI.CreateVersion:
            // console.log('DialogBaseComponent waitingCommandResults:', {cr});
            this.saving = !cr.completed;
            if (!cr.notToCloseDialog) {
              this.cancel();
            }
        }
      });
  }
 get isFeedBase(): boolean {
    return cfg.APPS_WITH_FEED.includes(this.appName || '');
 }
  ngAfterViewInit(): void {
    this.cdr?.detectChanges();
  }

  translate(key: string): string {
    return this.translationService?.translate(key) || '';
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  emailSearch(event: any): void {
    this.apiCallService?.autoCompleteUsersEmails({str: event.query})
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((response) => {
        this.users = response?.map((u: any) => {
          return u.email;
        });
        if (!this.users.length && cfg.EMAIL_REGEXP.test(event.query)) {
          this.users.push(event.query);
        }
      }, (reason) => {
        console.error({reason});
        this.users = [];
      });
  }

  get isAdminRole(): boolean {
    return this.mediaService?.isAdminRole || false;
  }
  get isUsersAdmin(): boolean {
    return this.mediaService.isUsersAdminRole;
  }
  get anyAdmin(): boolean {
    return this.isUsersAdmin || this.isAdminRole;
  }
  cancel(): void {
    this.dialogRef?.close();
  }
  public get colorApp(): string {
    return this.dialogConfig?.styleClass || '';
  }

  get componentTemplate(): any {
    return this.elementRef?.nativeElement;
  }

}
