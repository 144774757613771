<div class="p-inputgroup w-12 field">
  <p-inputNumber id="every" (onInput)="onInputParamChanged2($event)" styleClass="" [(ngModel)]="param2" [min]="1" [max]="12"
               [showButtons]="false" buttonLayout="horizontal" incrementButtonIcon="pi pi-plus"
               decrementButtonIcon="pi pi-minus"></p-inputNumber>
  <span class="p-inputgroup-addon">{{suffix2}}</span>
</div>
<div class="p-inputgroup w-12 mt-2 field">
  <span class="p-inputgroup-addon">{{parent.translate('ON_THE')}}</span>
  <p-inputNumber id="onDay" (onInput)="onInputParamChanged1($event)"  styleClass="" [(ngModel)]="param1" [min]="1" [max]="31"
               [showButtons]="false" buttonLayout="horizontal" incrementButtonIcon="pi pi-plus"
               decrementButtonIcon="pi pi-minus"></p-inputNumber>
  <span class="p-inputgroup-addon">{{suffix1}}</span>
</div>

