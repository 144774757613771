<!--style="background-color: rgb(241, 241, 240);"-->
<svg [attr.width]="width" [attr.height]="height">
  <g #horizontalAxis>
    <line [attr.x1]="chartStart.x" [attr.y1]="chartStart.y"
          [attr.x2]="chartEnd.x" [attr.y2]="chartEnd.y"
          style="stroke:var(--axiscolor)" />
  </g>
  <g #verticalAxis>
    <path [attr.d]="todayChartPathFilled" fill="#f2e9e4" stroke="none" />

    <g *ngFor="let item of filtered; index as i">
      <ng-container *ngIf="!excludeTimesIndexes.includes(i)">
      <line [attr.x1]="offSetX + i * timeIntervalWidth" [attr.y1]="offSetY"
            [attr.x2]="offSetX + i * timeIntervalWidth" [attr.y2]="height - offSetY"
            style="stroke: var(--axiscolor)" />

      <text [attr.x]="offSetX + i * timeIntervalWidth" [attr.y]="height - len10 / 2"
            text-anchor="middle" fill="var(--axiscolor)">{{item.timeLabel}}</text>

      <text [attr.x]="offSetX + i * timeIntervalWidth" [attr.y]="len10 * 1.4"
            text-anchor="middle" fill="var(--axiscolor)" style="font-size: 1.2em;">{{item.value}}</text>
      </ng-container>
    </g>

    <path *ngIf="showYesterdayData" [attr.d]="yesterdayChartPath" fill="none" stroke="#777" stroke-width="2" />
    <path [attr.d]="todayChartPath" fill="none" stroke="#64dfde" stroke-width="2" />

    <path [attr.d]="todayHourPath" stroke="#777"
          stroke-linecap="round" stroke-width="1" stroke-dasharray="5,5" fill="none"/>
    <!-- stroke="#64dfde" stroke-width="2" -->
<!--    *ngIf="todayHour % timeInterval != 0"-->
    <path [attr.d]="todayValueTitlePath" fill="#f2e9e4">
    </path>

<!--    *ngIf="todayHour % timeInterval != 0"-->
    <text
          [attr.x]="todayValueTitleCoords.x" [attr.y]="todayValueTitleCoords.y" text-anchor="middle"
          fill="var(--axiscolor)">{{todayValue}}</text>

  </g>
</svg>
