<div class="field">
  <label for="ftpHost" class="col-fixed title-row">{{translate("FTP_HOST")}}</label>
  <div class="col input-row with-info">
    <input id="ftpHost" type="text" autocomplete="off" pInputText [required]="true" [(ngModel)]="ftpHost" [placeholder]="translate('FTP_HOST')">
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('ftpHost')"></aux-info>
  </div>
</div>
<div class="field">
  <label for="ftpProtocol" class="col-fixed title-row">{{translate("FTP_TYPE")}}</label>
  <div class="col input-row with-info">
    <select id="ftpProtocol" [(ngModel)]="ftpProtocol" class="p-component p-dropdown">
      <option *ngFor="let f of ftpTypes; first as isFirst" [value]="f" [label]="translate(f)"
              [selected]="(ftpDetails.protocol && ftpDetails.protocol === f) || isFirst"></option>
    </select>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('ftpProtocol')"></aux-info>
  </div>
</div>
<div class="field">
  <label for="ftpPort" class="col-fixed title-row">{{translate("FTP_PORT")}}</label>
  <div class="col input-row with-info">
    <p-inputNumber id="ftpPort" [required]="true" [(ngModel)]="ftpPort" [min]="1" [placeholder]="translate('FTP_PORT')"></p-inputNumber>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('ftpPort')"></aux-info>
  </div>
</div>
<div class="field">
  <label for="ftpRemotePath" class="col-fixed title-row">{{translate("FTP_REMOTE_ROOT")}}</label>
  <div class="col input-row with-info">
    <input id="ftpRemotePath" type="text" autocomplete="off" pInputText [required]="true" [(ngModel)]="ftpRemotePath" placeholder="/root/folder/">
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('ftpRemotePath')"></aux-info>
  </div>
</div>



