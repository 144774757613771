<div *ngIf="column===1">
  <div class="password-feedback">
    <small [ngClass]="{'valid': passwordCriteria.minLength, 'invalid': !passwordCriteria.minLength}">
      <i class="pi" [ngClass]="passwordCriteria.minLength ? 'pi-check' : 'pi-times'"></i> At least 8 characters
    </small>
  </div>
  <div class="password-feedback">
    <small [ngClass]="{'valid': passwordCriteria.lowercase, 'invalid': !passwordCriteria.lowercase}">
      <i class="pi" [ngClass]="passwordCriteria.lowercase ? 'pi-check' : 'pi-times'"></i> At least 1 lowercase letter
    </small>
  </div>
  <div class="password-feedback">
    <small [ngClass]="{'valid': passwordCriteria.uppercase, 'invalid': !passwordCriteria.uppercase}">
      <i class="pi" [ngClass]="passwordCriteria.uppercase ? 'pi-check' : 'pi-times'"></i> At least 1 uppercase letter
    </small>
  </div>
  <div class="password-feedback">
    <small [ngClass]="{'valid': passwordCriteria.specialOrNumber, 'invalid': !passwordCriteria.specialOrNumber}">
      <i class="pi" [ngClass]="passwordCriteria.specialOrNumber ? 'pi-check' : 'pi-times'"></i> At least 1 special character or number
    </small>
  </div>
</div>
<div *ngIf="column===2" class="password-feedback-double">
  <div class="pwd-feedback-row">
    <small [ngClass]="{'valid': passwordCriteria.minLength, 'invalid': !passwordCriteria.minLength}">
      <i class="pi" [ngClass]="passwordCriteria.minLength ? 'pi-check' : 'pi-times'"></i> At least 8 characters
    </small>
    <small [ngClass]="{'valid': passwordCriteria.lowercase, 'invalid': !passwordCriteria.lowercase}">
      <i class="pi" [ngClass]="passwordCriteria.lowercase ? 'pi-check' : 'pi-times'"></i> At least 1 lowercase letter
    </small>
  </div>
  <div class="pwd-feedback-row">
    <small [ngClass]="{'valid': passwordCriteria.uppercase, 'invalid': !passwordCriteria.uppercase}">
      <i class="pi" [ngClass]="passwordCriteria.uppercase ? 'pi-check' : 'pi-times'"></i> At least 1 uppercase letter
    </small>
    <small [ngClass]="{'valid': passwordCriteria.specialOrNumber, 'invalid': !passwordCriteria.specialOrNumber}">
      <i class="pi" [ngClass]="passwordCriteria.specialOrNumber ? 'pi-check' : 'pi-times'"></i> At least 1 special character or number
    </small>
  </div>
</div>
