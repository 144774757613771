import {EventEmitter, Injectable} from '@angular/core';
import * as models from './models';
import {MediaService} from './media.service';
import {TranslationService} from './translation.service';
import * as aux from 'adcore-auxiliary';
import * as utils from './utils';
import * as cfg from './config';
import {ApiCallService} from './api-call.service';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  queryParams: any = null;
  targetWindow: any = null;
  sendingMessage = false;
  mediaComponent: aux.MediaComponent | undefined;
  openingManagementPage: EventEmitter<void> = new EventEmitter<void>(false);
  waitingCommandResults: EventEmitter<models.ICommandResult> = new EventEmitter<models.ICommandResult>(false);
  waitingVersionSavedAndOpened: EventEmitter<aux.IMediaRow | null | undefined> = new EventEmitter<aux.IMediaRow | null | undefined>(false);
  collapsibleStatuses: any = {feeditManageColumns: true};
  parent: any;

  constructor(private mediaService: MediaService, private translationService: TranslationService,
              private windowRefService: aux.WindowRefService, private apiCallService: ApiCallService) {
  }
  retrieveMessage(event: models.IBackMessage | null, parent?: any): void {
    // console.log('retrieveMessage', {event});
    if (event?.key === 'translation' && event?.message) {
      this.translationService.initTranslationByLanguage(event?.message?.language);
    }
    if (event?.key?.toLowerCase() === 'actions_report_isexistaction' && event?.message) {
       this.mediaService.isExistAction =  event?.message?.isExistAction || false;
    }
    if (event?.key?.toLowerCase() === 'actions_report_isdynamicactionexist' && event?.message) {
      this.mediaService.isDynamicActionExist =  event?.message?.isDynamicActionExist || false;
      this.mediaService.isShoppingActionExist =  event?.message?.isShoppingActionExist || false;
    }
    if (event?.key?.toLowerCase() === 'collapsible_status_ngcollapsiblechange' && event?.message) {
      if (event.message.collapsibleName) {
        this.collapsibleStatuses[event.message.collapsibleName] = event?.message?.collapsed;
      }
    }
    if (event?.key?.toLowerCase().startsWith('progress_report_') && event?.message && parent) {
     if (event.message.command === cfg.ECommandsFromNewUI.UIInProgress) {
       this.mediaService.isOldUIInProcessReported = event.message.inProcess;
       if (event.mode === 'feeditor') {
         this.mediaService.feeditorMergedColumns = event.message.mergedColumns;
         this.mediaService.feeditorCurrentTotalRows = event.message.rowsCount;
         this.mediaService.feeditorRenderException = event.message.error;
         this.mediaService.feeditorCacheBuildDate = event.message.cacheBuildDate;
       }
     }
    }

    if (event?.key?.toLowerCase().startsWith('command_call_') && event?.message && parent) {
      this.waitingCommandResults.emit({
        command: event.message?.command || '',
        mode: event.mode,
        completed: event.message?.completed || false,
        result: event.message,
        notToCloseDialog: event.message?.notToCloseDialog
      });
      // console.log('command_call_', {event});
      if (event.message?.completed && event.message?.command === cfg.ECommandsFromNewUI.UIFeeditorGridRendered) {
        if (event.message?.isTrialFeedSizePlanUpdated) {
          this.apiCallService.updateSelectedAdvertiser().then((r) => {
            // console.log('updateSelectedAdvertiser', {event, r});
          });
        }
        if (event.message?.cleanImportLogFlag) {
            if (this.mediaService.currentFeed && this.mediaService.currentFeed.value?.data?.importLogEnabled) {
              this.mediaService.currentFeed.value.data.importLogEnabled = false;
              this.windowRefService.reportGtagEventNew('successful_feed_import', {});
            }
        }
        if (event.message.error) {
          this.mediaService.clearCachedFeed();
        }
      }
    }
    if (event?.key?.toLowerCase().startsWith('media_change_') && event?.message && parent && this.mediaComponent) {
      parent.reloadMedia('views', this.mediaComponent,   parent.reSelectAccount.bind(parent), undefined, true);
    }

    if (event?.key?.toLowerCase().startsWith('navigate_in_browser')) {
       this.openInSameBrowser(event.message, parent);
       return;
    }
    if (event?.key?.toLowerCase().startsWith('navigate_to_the_plan_page')) {
      this.windowRefService.reloadLocation(`settings/billing/plan?feedSizeChange=${event?.message?.feedSizeInfo?.license}`);
      return;
    }
    if (event?.key?.toLowerCase().startsWith('import_error_open_demo_feed')) {
      // console.log('import_error_open_demo_feed', {event, parent: this.parent, mediaComponent: this.mediaComponent});
      if (event?.message?.demoFeedId && this.parent && this.mediaComponent) {
        this.mediaService.clearCachedFeed();
        this.parent?.reloadMedia('feeditor', this.mediaComponent, this.parent?.reSelectFeed?.bind(this.parent),
          event?.message?.demoFeedId, false);
        return;
      }
    }
    if (event?.key?.toLowerCase().startsWith('import_error_open_import_sidebar_menu')) {
      const row = {
        value: null,
        visible: true,
        disabled: false,
        key: 'import-feed',
        label: this.parent.translate('import-feed'),
        type: aux.EMediaType.NONE,
        subType: aux.EMediaSubType.NONE,
        icon: 'menu-icon import',
        alwaysEnabled: true,
        firstTime: true,
        // tooltip: this.parent.translate('ng-tooltip-import-feed')
      };
      this.mediaService.feeditorMenuOutsideClick.emit({
        mode: aux.EMediaMode.PAGE,
        row
      });
      return;
    }
    if (event?.key?.toLowerCase().startsWith('navigate_to_feeditor_plus_for_import')) {
      this.openFeeditorPlusForImport(event?.message);
      return;
    }
    if (event?.key?.toLowerCase() === 'feedit' && event?.message) {
      const feedId = event.message?.feedId;
      const isSelectFeed = event.message?.isSelectFeed;
      const isDeleteFeed = event.message?.isDeleteFeed;
      if ( feedId && isSelectFeed) {
          event.message.appName = 'feeditor';
          this.openInSameBrowser(event?.message);
      }
    }
    if (event?.key?.toLowerCase() === 'companyname' && event?.message) {
      const advertiserToUpdate =  Object.assign({}, this.mediaService.currentAdvertiser);

      if (advertiserToUpdate && advertiserToUpdate.value) {
        advertiserToUpdate.label = event.message.value;
        advertiserToUpdate.value.companyName = event.message.value;
        this.mediaService.currentAdvertiser = advertiserToUpdate;

        const recent: aux.IMediaRow[] = this.mediaService.recentAdvertisers;
        utils.addToRecentMedia(advertiserToUpdate, recent);
        this.mediaService.recentAdvertisers = recent;
      }
    }
  }

  sendMessage(key: string, app: string, mode: string, route?: string | null, message?: any, timeout?: number): void {
    if (!timeout) {
      timeout = 600;
    }
    if (key === cfg.ECommandsFromNewUI.CreateVersion.toUpperCase()) {
      Userpilot.track('Created version', {appName: app});
    }
    const data: models.IThereMessage = {
      key,
      app,
      mode,
      message,
      route,
      advertiser: this.mediaService.selectedAdvertiser || {} as models.IAdvertiser,
      userAdvertiser: this.mediaService.userAdvertiser || {} as models.IAdvertiser,
      token: this.mediaService.accessToken || '',
      user: this.mediaService.currentUser || {} as models.IUser,
      translations: this.translationService.translations,
      page: this.mediaService.currentPage?.value,
      userAdvertiserAndKids: this.mediaService.userAdvertiserAndKids,
      allPages: this.mediaService.allPages.reduce((o, p) => {
        // @ts-ignore
        o[p.label.toLowerCase()] = p.key;
        return o;
      }, {}),
      channel: this.mediaService.currentAccountChannel?.value || null,
      mcc: this.mediaService.currentAccountParent?.value || null,
      queryParams: this.queryParams,
      viewId: this.mediaService.selectedVersionId,
      timePeriod: this.mediaService.emitTimePeriod,
      feeditorWorkMode: this.mediaService.feeditorModeConfig?.workMode
    };
    if (this.targetWindow) {
      this.sendingMessage = true;
      setTimeout(() => {
        if (route) {
          this.mediaService.managementPage = route;
          // this.openingManagementPage.emit();
        }
        this.targetWindow.postMessage(data, '*');
        this.sendingMessage = false;
      }, timeout);
    }
  }

  private openFeeditorPlusForImport(message: any): void {
    if (!message) {
      return;
    }
    this.windowRefService.setSessionData(cfg.ELocalStorageTokens.FEEDITOR_PLUS_IMPORT_INFO_TOKEN, message);
    this.windowRefService.reloadLocation('feeditor-plus');
  }
  private async openInSameBrowser(message: any, parent?: any): Promise<void> {
      // current_advertiser_id=kwargs.get('advertiser_id'),
      // target_advertiser_id=kwargs.get('targetAdvertiserId'),
      // account_media_id=kwargs.get('accountId'),
      // feed_media_id=kwargs.get('feedId'),
      // page_id=kwargs.get('pageId'),
      // version_id=kwargs.get('versionId'),
      // page_name=kwargs.get('pageName'))
    if (message && message.appName) {
      const options: models.IOptions = {
        module_fn: `mongo.dbsystem.view.view.get_info_to_open_app`,
        advertiser_id: this.mediaService?.currentAdvertiser?.key,
        targetAdvertiserId: message.targetAdvertiserId,
        accountId: message.accountId,
        feedId: message.feedId,
        pageId: message.pageId,
        versionId: message.versionId,
        pageName: message.pageName
      };
      const response = await this.apiCallService.pyGettGenericShort(options).pipe().toPromise();
      const {advertiser, version, feed, account, page} = await response;
      const media = account || feed || null;
      if (media) {
        setTimeout(() => {
          this.mediaService.saveCachedFeed(feed, advertiser?.key, message.appName);
          this.mediaService.saveCachedAccount(account, advertiser?.key);
          this.mediaService.saveCachedVersion(version, `${page?.subType || 'noPage'}|${media?.key || 'noMedia'}`);
          this.mediaService.saveCachedAdvertiser(advertiser);
          this.mediaService.saveCachedPage(page, cfg.moduleNamePatch(message.appName));
          this.windowRefService.reloadLocation(message.appName);
        }, 1);
      }else {
        parent?.showMainAlert({
          severity: 'error',
          summary: this.translationService.translate('NAVIGATION_FAILED'),
          detail: this.translationService.translate('MEDIA_NOT_FOUND')
        });
      }
    }
  }
}
