import {ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {ApiCallService} from '../helpers/api-call.service';
import {MediaService} from '../helpers/media.service';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {FacebookLoginProvider} from '@abacritt/angularx-social-login';
import {WindowRefService} from 'adcore-auxiliary';
import {DOCUMENT} from '@angular/common';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as planTextInfos from './plan-text-infos.json';
import {AuthenticationService} from '../helpers/authentication.service';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit, OnDestroy {

  componentDestroyed: Subject<boolean> = new Subject();
  routeUrl = '';
  selectedPlan = 'all';
  selectedStage = 'stage1';
  isBottomSticky = false;
  readonly jsonData: any = planTextInfos;
  @Output() changePlan: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeStage: EventEmitter<string> = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, private titleService: Title,
              public apiCallService: ApiCallService, public mediaService: MediaService,
              public authService: SocialAuthService, public winRef: WindowRefService,
              public appAuthService: AuthenticationService,
              public deviceService: DeviceDetectorService,
              @Inject(DOCUMENT) private _document: Document,
              private cdr: ChangeDetectorRef,
              private router: Router) {
    this.titleService.setTitle('Adcore Marketing Cloud (AMC)');
  }

  get isDesktopDevice(): boolean {
    return this.deviceService.isDesktop() || this.deviceService.isTablet();
  }

  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  ngOnInit(): void {
    // utils.setAppFavIcon(this._document, 'profile');
    this.route.url
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((url) => {
        // console.log({url});
        this.routeUrl = (url.length) ? url[0].path : '';
      });
    this.changePlan.pipe(takeUntil(this.componentDestroyed)).subscribe((plan) => {
      this.selectedPlan = plan;
      this.cdr.detectChanges();
    });
    this.changeStage.pipe(takeUntil(this.componentDestroyed)).subscribe((stage) => {
      this.selectedStage = stage;
      this.cdr.detectChanges();
    });
    this.checkBottomMobileSticky();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  get planClass(): string {
    return `col plan-section plan-${this.selectedPlan}`;
  }

  get planText(): string {
    return this.jsonData[this.selectedPlan][this.selectedStage].text;
  }

  get reviews(): string[] {
    return this.jsonData[this.selectedPlan][this.selectedStage].reviews || [];
  }

  get rating(): any {
    return this.jsonData[this.selectedPlan][this.selectedStage].rate;
  }

  get planCheckMarks(): string[] {
    return this.jsonData[this.selectedPlan][this.selectedStage].options;
  }

  get planTitleNormal(): string {
    return this.jsonData[this.selectedPlan][this.selectedStage].title.normal;
  }

  get planTitleStrong(): string {
    return this.jsonData[this.selectedPlan][this.selectedStage].title.strong;
  }

  get planLogo(): string {
    return this.jsonData[this.selectedPlan][this.selectedStage].logo;
  }

  get planLogoClass(): string {
    return `plan-logo ${this.selectedStage}`;
  }
  navigateToMyCloud(timeOut: number = 0): void {
    setTimeout(() => {
      this.router.navigate(['/my-cloud']);
    }, timeOut);
  }

  private checkBottomMobileSticky(): void {
    setTimeout(() => {
      const screenMobileHeight = this.winRef.nativeWindow.innerHeight;
      const currentSectionH = this._document.getElementById('loginPageBox');
      const appBottomH = this._document.getElementById('bottomMobile');
      if (currentSectionH && currentSectionH.offsetHeight &&
        appBottomH && appBottomH.offsetHeight &&
        screenMobileHeight > currentSectionH.offsetHeight) {
        this.isBottomSticky = !(this.isBottomSticky && screenMobileHeight <= (currentSectionH.offsetHeight + 233));
      } else {
        this.isBottomSticky = false;
      }
    }, 300);
  }
}
