import {Component, DoCheck, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ProfileLayoutComponent} from '../profile-layout.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {finalize, takeUntil} from 'rxjs/operators';
import * as utils from '../../helpers/utils';
import * as aux from 'adcore-auxiliary';
import * as cfg from '../../helpers/config';
import {lastValueFrom, timer} from 'rxjs';
import {MessageService} from 'primeng/api';
declare var grecaptcha: any;
@Component({
  selector: 'app-invitation',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit, DoCheck {
  constructor(private router: Router, private route: ActivatedRoute, private elementRef: ElementRef,
              @Inject(ProfileLayoutComponent) public parent: ProfileLayoutComponent, private cookieService: CookieService,
              private messageService: MessageService) {
  }

  // readonly strongPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
  // readonly mediumPassword = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
  private reCaptchaScoreV3: number | undefined;
  private disableScoreThreshold = 0.7;
  passwordRequirement = '<div>Password Requirements:</div>' +
    '<div>- At least 8 characters</div>' +
    '<div>- At least 1 uppercase letter</div>' +
    '<div>- At least 1 lowercase letter</div>' +
    '<div>- At least 1 number or special character (e.g., @, !, #)</div>';

  passwordCriteria = {
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialOrNumber: false
  };

  inProcess = false;
  uit: string | undefined;
  currentStage = 'stage1';
  stage2 = false;
  changeEmailStage = false;
  changePhoneStage = false;
  responseError: string | null = null;
  invitationSent = false;
  emailError: string | null = null;
  passwordError: string | null = null;
  fullNameError: string | null = null;
  phoneError: string | null = null;
  newPhoneError: string | null = null;
  websiteError: string | null = null;
  reSendCounter = 0;
  @ViewChild('phone') phone: aux.PhoneComponent | undefined;
  @ViewChild('newPhone') newPhone: aux.PhoneComponent | undefined;
  loginInfo: any = {
    token: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    pass1: '',
    pass2: '',
    email: '',
    socialLogin: '',
    newEmail: '',
    accessCode: '',
    websiteUrl: ''
  };
  currentCountryCode = 'US';
  verifyInfo: any = null;
  resendTimes = 0;
  emailResendTimes = 0;
  countdown = cfg.VERIFY_RESEND_SECONDS;
  isResendDisabled = false;
  codeError = false;
  phoneCodeCorrect = false;
  emailCodeCorrect = false;
  showPasswordFeedback = false;
  phoneCode: any = null;
  emailCode: any = null;


  ngDoCheck(): void {
    this.elementRef.nativeElement.querySelectorAll(`input[type='password']`).forEach((el: any) => {
      el.setAttribute('autocomplete', 'new-password');
    });
  }

  logIn(): void {
    this.parent.winRef.reloadLocation();
  }

  get isValidEmail(): boolean {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    return re.test(this.loginInfo.email || '');
  }

  get isValidNewEmail(): boolean {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    return re.test(this.loginInfo.newEmail || '') && this.loginInfo.newEmail !== this.loginInfo.email;
  }

  close(): void {
    this.parent.winRef.nativeWindow?.open('', '_self', '');
    this.parent.winRef.nativeWindow?.close();
  }

  checkPasswordStrength(password: string): void {
    this.passwordError = null;
    this.passwordCriteria.minLength = password.length >= 8;
    this.passwordCriteria.uppercase = /[A-Z]/.test(password);
    this.passwordCriteria.lowercase = /[a-z]/.test(password);
    this.passwordCriteria.specialOrNumber = /[0-9\W]/.test(password);
    // console.log(password, password.length);
    // Hide the feedback if all criteria are met
    this.showPasswordFeedback = password.length > 0 && !Object.values(this.passwordCriteria).every(criteria => criteria);
  }

  get planImgClass(): string {
    return `plan-img ${this.loginInfo.signUpPlan}`;
  }

  ngOnInit(): void {
    this.validateReCaptchaV3();
    this.currentCountryCode = this.parent.mediaService.currentCountryCode;
    if (!this.currentCountryCode) {
      const nativeGeoIp = this.parent.winRef.nativeGeoIp;
      if (nativeGeoIp) {
        nativeGeoIp.country((geoipResponse: any) => {
            this.currentCountryCode = geoipResponse.country.iso_code;
          },
          (err: any) => {
            console.log('Error', err);
          });
      }
    }
    this.route.queryParams
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe(params => {
          this.uit = params.uit;
          const newParams = this.checkCookie(params);
          this.loginInfo.signUpSource = newParams.utm_source;
          this.loginInfo.signUpMedium = newParams.utm_medium;
          this.loginInfo.signUpCampaignName = newParams.utm_campaign;
          this.loginInfo.signUpgclid = newParams.gclid;
          this.loginInfo.signUpfbcid = newParams.fbcid;
          this.loginInfo.signUpmsclkid = newParams.msclkid;
          this.loginInfo.signUpPage = newParams.last_page;
          this.loginInfo.signUpPlan = newParams.selected_plan || 'all';
          this.loginInfo.referral_code = newParams.ref || '';
          this.loginInfo.affiliate = newParams.affiliate || '';
          this.parent.changePlan.emit(newParams.selected_plan || 'all');
          if (this.uit) {
            this.currentStage = 'stage2';
          }
          this.parent.changeStage.emit(this.currentStage);
          // console.log({li: this.loginInfo, params});
        }
      );
    this.parent?.authService.authState.subscribe(async (user) => {
      if (user) {
        let existingUser = null;
        if (!this.failedReCaptchaV3) {
          this.loginInfo.socialLogin = user.provider;
          this.loginInfo.firstName = user.firstName;
          this.loginInfo.lastName = user.lastName;
          this.loginInfo.email = user.email;
          existingUser = await this.checkUser();
          this.goToStageAfterCheckingUser(existingUser);
        }
      }
    });
  }

  goToStageAfterCheckingUser(existingUser: any): void {
    this.stage2 = !this.emailError;
    if (this.stage2 && !existingUser) {
      this.currentStage = 'stage2';
      this.parent.changeStage.emit(this.currentStage);
    }
    if (this.stage2 && existingUser) {
      this.loginInfo = existingUser;
      this.loginInfo.phoneNumber = existingUser.phone;
      if (!this.verifyInfo) {
        this.verifyInfo = {
          identifier: this.loginInfo.phoneNumber,
          channel: 'sms'
        };
      }
      this.verifyPhone();
      this.currentStage = 'stage2-verifyPhone';
      this.parent.changePlan.emit(this.loginInfo.signUpPlan);
      this.parent.changeStage.emit(this.currentStage);
    }
  }

  checkCookie(params: any): any {
    if (!(params.utm_source || params.utm_medium || params.utm_campaign || params.gclid || params.fbcid ||
      params.msclkid || params.last_page || params.selected_plan || params.ref || params.affiliate)) {
      const joinCookie = this.cookieService.get(cfg.ECookieTokens.JOIN_PARAMS);
      if (joinCookie) {
        return JSON.parse(joinCookie);
      }
    } else {
      const joinCookieObj = {
        utm_source: params.utm_source,
        utm_medium: params.utm_medium,
        utm_campaign: params.utm_campaign,
        gclid: params.gclid,
        fbcid: params.fbcid,
        msclkid: params.msclkid,
        last_page: params.last_page,
        selected_plan: params.selected_plan,
        ref: params.ref,
        affiliate: params.affiliate
      };
      const joinCookie = JSON.stringify(joinCookieObj);
      this.cookieService.set(cfg.ECookieTokens.JOIN_PARAMS, joinCookie);
    }
    return params;
  }

  get isValidPhone(): boolean {
    return !aux.phoneNumberValidator(this.phone);
  }

  get fullName(): string {
    return `${this.loginInfo.firstName || ''} ${this.loginInfo.lastName || ''}`.trim();
  }

  private get isPasswordStrengthEnough(): boolean {
    const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9\\W])(?=.{8,})');
    return re.test(this.loginInfo.pass1 || '');
  }

  validate(): boolean {
    this.phoneError = null;
    this.passwordError = null;
    this.fullNameError = null;
    this.websiteError = null;
    if (aux.phoneNumberValidator(this.phone)) {
      this.phoneError = 'Please enter valid phone number.';
    }

    const re2 = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$');
    if (!re2.test(this.loginInfo.websiteUrl || '')) {
      this.websiteError = 'PLease enter valid website url.';
    }

    if (!this.loginInfo.socialLogin) {
      if (!this.loginInfo.pass1 || !this.loginInfo.pass2) {
        this.passwordError = 'Please enter valid password';
      }
      if (this.loginInfo.pass1 && !this.isPasswordStrengthEnough) {
        this.passwordError = 'Please enter strong password';
      }
      if (this.loginInfo.pass1 && this.loginInfo.pass2 && this.loginInfo.pass1 !== this.loginInfo.pass2) {
        this.passwordError = 'Passwords not identical';
      }
      if (!this.fullName.length) {
        this.fullNameError = 'Please enter valid full name';
      }
    }
    return Boolean(!this.passwordError && !this.fullNameError && !this.phoneError && !this.websiteError);
  }

  removeError(event: string): void {
    if (event === 'website') {
      this.websiteError = null;
    }
    if (event === 'email') {
      this.emailError = null;
    }
  }

  get translatedTitle(): string {
    if (this.loginInfo?.signUpPlan?.toLowerCase() === 'all') {
      return 'Adcore Marketing Cloud';
    } else if (this.loginInfo?.signUpPlan?.toLowerCase() === 'feeditor_plus') {
      return 'Feeditor PLUS+';
    } else {
      return this.loginInfo?.signUpPlan;
    }
  }

  // private finalizeEmailValidation(): void {
  //   // this.loginInfo.token = this.auit || '';
  //   this.parent.apiCallService
  //     .joinFinalization(this.loginInfo)
  //     .pipe(takeUntil(this.parent.componentDestroyed))
  //     .subscribe((response) => {
  //       // console.log({response});
  //       const r = response || {};
  //       if (r.result && r.token) {
  //         this.parent.mediaService.accessToken = r.token;
  //         this.parent.navigateToMyCloud();
  //         return;
  //       }
  //       this.passwordError = r.err.toString();
  //     }, (reason) => {
  //       // console.log({reason});
  //       this.parent.mediaService.accessToken = null;
  //       // this.parent.winRef.reloadLocation();
  //       this.passwordError = reason.toString();
  //     });
  // }

  /*
  reCAPTCHA
  site key: 6Lc5fGYaAAAAAIkVOv4vqGpanM4Ba-zNUqaTqeZC
  server key: 6Lc5fGYaAAAAABCxkerJiB3eMm96LUjFsfcpM1b2
  */
  private finalizeAdminInvitation(): void {
    this.inProcess = true;
    this.parent.apiCallService
      .adminInvitationFinalization(this.uit || '', this.loginInfo.firstName || '', this.loginInfo.lastName || '',
        this.loginInfo.pass1 || '', this.loginInfo.pass2 || '',
        this.loginInfo.socialLogin || '', this.loginInfo.phoneNumber || '')
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        const r = response || {};
        if (r.result && r.token) {
          this.parent.mediaService.accessToken = r.token;
          // this.parent.winRef.reloadLocation();
          this.parent.appAuthService.authenticate();
          this.parent.navigateToMyCloud();
          return;
        }
        this.responseError = r.error.toString();
        console.error(this.responseError);
        this.inProcess = false;
      }, (reason) => {
        this.inProcess = false;
        this.parent.mediaService.accessToken = null;
        this.responseError = reason.toString();
        console.error(this.responseError);
        // this.parent.winRef.reloadLocation();
      });
  }

  async validateEmail(): Promise<void> {
    let user = null;
    if (!this.failedReCaptchaV3) {
      this.emailError = (!this.isValidEmail) ? 'Please enter a valid email address.' : null;
      if (this.isValidEmail) {
        user = await this.checkUser();
      }

      this.parent.winRef.reportGtagEventNew('sign-up-step-1-email', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
      if (this.loginInfo.signUpPlan === 'feeditor') {
        this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1-email', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
      }
      this.goToStageAfterCheckingUser(user);
    }
  }

  async checkUser(newEmail: boolean = false): Promise<any> {
    this.inProcess = true;
    const options = {
      email: (!newEmail) ? this.loginInfo.email : this.loginInfo.newEmail,
      socialLogin: this.loginInfo.socialLogin
    };
    const res = await this.parent.apiCallService.isUserExists(options).toPromise();
    if (res) {
      this.emailError = res.error;
    }
    this.inProcess = false;
    return res;
  }

  join(): void {
    this.responseError = null;
    if (this.validate()) {
      this.loginInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || '';
      if (this.loginInfo.socialLogin) {
        this.joinSocial();
      } else {
        if (this.uit) {
          this.verifyPhone();
          this.currentStage = 'stage2-verifyPhone';
        } else {
          this.joinEmail();
        }
      }
    }
  }

  private joinSocial(): void {
    this.inProcess = true;
    this.loginInfo.signUpType = utils.capitalize(this.loginInfo.socialLogin);
    this.loginInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || '';
    this.loginInfo.reCaptchaScoreV3 = this.reCaptchaScoreV3;
    this.parent.winRef.reportGtagEventNew('sign-up-step-1', {
      method: this.loginInfo.signUpType,
      selected_plan: this.loginInfo.signUpPlan
    });
    if (this.loginInfo.signUpPlan === 'feeditor') {
      this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1', {
        method: this.loginInfo.signUpType,
        selected_plan: this.loginInfo.signUpPlan
      });
    }
    this.parent.apiCallService
      .joinSocialUserStage2(this.loginInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
          if (response && response.error) {
            this.responseError = response.error.toString();
          } else {
            this.parent.winRef.reportGtagEventNew('sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
            if (this.loginInfo.signUpPlan === 'feeditor') {
              this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
            }
            this.invitationSent = true;
            this.verifyPhone();
            this.currentStage = 'stage2-verifyPhone';
            this.startCountdown(cfg.VERIFY_RESEND_SECONDS);
            this.parent.changeStage.emit(this.currentStage);
          }
          this.inProcess = false;
      }, (reason) => {
        // console.log({reason});
        this.inProcess = false;
        this.responseError = reason.toString();
        // this.parent.mediaService.accessToken = null;
      });
  }

  private joinEmail(): void {
    this.inProcess = true;
    this.invitationSent = false;
    this.loginInfo.signUpType = 'Email';
    this.loginInfo.reCaptchaScoreV3 = this.reCaptchaScoreV3;
    this.parent.apiCallService
      .createEmailUserStage2(this.loginInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response});
        if (response && response.error) {
          this.responseError = response.error.toString();
        } else {
          this.parent.winRef.reportGtagEventNew('sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
          if (this.loginInfo.signUpPlan === 'feeditor') {
            this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
          }
          this.invitationSent = true;
          this.verifyPhone();
          this.currentStage = 'stage2-verifyPhone';
          this.startCountdown(cfg.VERIFY_RESEND_SECONDS);
          this.parent.changeStage.emit(this.currentStage);
        }
        this.inProcess = false;
      }, (reason) => {
        this.inProcess = false;
        this.responseError = reason.toString();
      });
  }

finalizeAutoEmailInvitation(): void {
    if (!this.emailCode || this.emailCode?.length < 6) {
      this.codeError = true;
      this.inProcess = false;
      return;
    }
    if (this.emailCode.length === 6 && !this.inProcess) {
      this.inProcess = true;
      this.verifyInfo.code = this.emailCode;
      this.verifyInfo.userId = this.loginInfo._id;
      this.loginInfo.verifyInfo = this.verifyInfo;
      this.parent.apiCallService
        .activateInvitedUserStage2(this.loginInfo)
        .pipe(takeUntil(this.parent.componentDestroyed))
        .subscribe((response) => {
          const r = response || {};
          if (r.result && r.token) {
            this.codeError = false;
            this.emailCodeCorrect = true;
            setTimeout(() => {
              this.parent.winRef.reportGtagEventNew('sign-up-email-verified', {method: 'Email', selected_plan: r.selectedPlan});
              this.parent.winRef.reportGtagEventNew('signup-complete-email', {method: 'Email', selected_plan: r.selectedPlan});
              if (r.selectedPlan === 'feeditor') {
                this.parent.winRef.reportGtagEventNew('feeditor-signup-complete-email',
                  {method: 'Email', selected_plan: r.selectedPlan});
                this.parent.winRef.reportGtagEventNew('feeditor-sign-up-email-verified',
                  {method: 'Email', selected_plan:  r.selectedPlan});
              }
              this.parent.appAuthService.authenticate();
              // this.parent.winRef.reloadLocation(undefined, 1000);
              this.parent.navigateToMyCloud(1000);
            }, 1000);
            this.parent.mediaService.accessToken = r.token;
            return;
          }
          this.inProcess = false;
          // tslint:disable-next-line:no-unused-expression
          r.error && (this.responseError = r.error.toString());
          if (this.responseError === 'Invalid verification code.') {
            this.codeError = true;
            this.emailCodeCorrect = false;
          }
        }, (reason) => {
          // console.log({reason});
          this.inProcess = false;
          this.parent.mediaService.accessToken = null;
          this.responseError = reason.toString();
        });
    }
  }


  async onCodeChange(event: any, channel: string): Promise<void> {
    this.codeError = false;
    this.phoneCodeCorrect = false;
    this.emailCodeCorrect = false;
    this.responseError = null;
    if (channel === 'email' && this.emailCode.length === 6) {
      this.finalizeAutoEmailInvitation();
    }
    if (channel === 'phone' && this.phoneCode.length === 6) {
      await this.onApplyingPhoneCode();
    }
  }

  changePhone(): void {
    this.changePhoneStage = !this.changePhoneStage;
  }

  updatePhone(): void {
    this.newPhoneError = null;
    if (aux.phoneNumberValidator(this.newPhone)) {
      this.newPhoneError = 'Please enter valid phone number.';
    } else {
      this.loginInfo.phoneNumber = this.newPhone?.fullPhoneNumber?.internationalNumber || '';
      this.verifyInfo = {
        identifier: this.newPhone?.fullPhoneNumber.internationalNumber,
        channel: 'sms'
      };
      this.send2faRequest('update');
      this.changePhoneStage = false;
    }
  }

  async onApplyingPhoneCode(): Promise<void> {
    this.codeError = false;
    this.phoneCodeCorrect = false;
    this.responseError = null;
    this.inProcess = true;
    if (!this.phoneCode || this.phoneCode?.length < 6) {
      this.codeError = true;
      this.inProcess = false;
      return;
    }
    this.verifyInfo.code = this.phoneCode;

    this.parent.apiCallService.apiCheck2faVerification(this.verifyInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        if (response.status === 'approved') {
          this.phoneCodeCorrect = true;
          this.loginInfo.phone = this.loginInfo.phoneNumber;
          setTimeout(() => {
            if (this.uit) {
              this.finalizeAdminInvitation();
            } else {
              this.verifyInfo = {
                identifier: this.loginInfo.email,
                channel: 'email'
              };
              this.reSendVerificationEmail();
              this.currentStage = 'stage3';
            }
            }, 1000);
        } else {
          this.codeError = true;
          this.inProcess = false;
        }
      });

  }

  reSendVerificationEmail(): void {
    this.inProcess = true;
    this.responseError = null;
    this.codeError = false;
    this.emailCodeCorrect = false;
    this.emailCode = null;
    const userInfo = {
      verifyInfo: this.verifyInfo,
      user: this.loginInfo,
      twilioAction: 'join'
    };
    this.parent.apiCallService.apiSend2faVerification(userInfo)
      .pipe(takeUntil(this.parent.componentDestroyed),
        finalize(() => {
          this.inProcess = false;
        })
      )
      .subscribe((response) => {
        if (!response.error) {
          this.verifyInfo = response;
          this.messageService.add({
            key: '2faMessage',
            severity: 'success',
            closable: false,
            detail: 'A code has been sent to your email!'});
          this.emailResendTimes++;
          this.startCountdown(cfg.VERIFY_RESEND_SECONDS);
        } else {
          this.messageService.add({
            key: '2faMessage',
            severity: 'error',
            closable: false,
            detail: response.error || 'An error occur when sending the verification code!'});
        }
      });
  }

  changeEmailForUserStage(): void {
    this.changeEmailStage = !this.changeEmailStage;
  }

  async continueChangeEmail(): Promise<void> {
    this.emailError = (!this.isValidNewEmail) ? 'Please enter a valid email address.' : null;

    if (this.isValidNewEmail) {
      await this.checkUser(true);
    }
    if (!this.emailError) {
      this.inProcess = true;
      this.responseError = null;
      this.parent.apiCallService
        .changeTrialUserEmail(this.loginInfo)
        .pipe(takeUntil(this.parent.componentDestroyed))
        .subscribe((response) => {
          // console.log({response});
          if (response && response.error) {
            this.emailError = response.error.toString();
          } else {
            this.loginInfo.email = this.loginInfo.newEmail;
            this.emailResendTimes = 0;
            this.verifyInfo = {
              identifier: this.loginInfo.email,
              channel: 'email'
            };
            this.reSendVerificationEmail();
            this.startCountdown(cfg.VERIFY_RESEND_SECONDS);
            this.changeEmailStage = false;
          }
          this.inProcess = false;
        }, (reason) => {
          this.inProcess = false;
          this.emailError = reason.toString();
        });
    }

  }

  get formHeader(): string {
    return this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.header;
  }

  get formSubHeader(): string {
    return (this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.subHeader || '')
      .replace('#email#', this.loginInfo.email);
  }

  get formFooter(): string {
    return this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.footer || '';
  }

  get formButton(): string {
    return (!this.uit) ? this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.button || '' : 'join';
  }

  get formButtonClass(): string {
    return `${this.loginInfo.signUpPlan}-color`;
  }

  private validateReCaptchaV3(): void {
    this.reCaptchaScoreV3 = undefined;
    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise.execute('6LfXPmEpAAAAAAit7aA8xLMOy1nD3ZCcwDTzaqnz', { action: 'JOIN' })
        .then(async (token: string) => {
          const { score } = await lastValueFrom(this.parent.apiCallService.validateCaptcha(token, 'JOIN'));
          this.reCaptchaScoreV3 = score;
        })
        .catch((error: any) => {
          console.error('Error generating token or validating captcha:', error);
        });
    });
  }

  verifyPhone(): void {
    this.verifyInfo = {
      identifier: this.loginInfo.phoneNumber,
      channel: 'sms'
    };
    this.send2faRequest('init');
  }

  send2faRequest(verifyType: string): void {
    this.inProcess = true;
    this.codeError = false;
    this.phoneCodeCorrect = false;
    this.responseError = null;
    this.phoneCode = null;
    const userInfo = {
      verifyInfo: this.verifyInfo,
      user: this.loginInfo,
      twilioAction: 'join'
    };
    this.parent.apiCallService.apiSend2faVerification(userInfo)
      .pipe(takeUntil(this.parent.componentDestroyed),
      finalize(() => {
        this.inProcess = false;
      })
      )
      .subscribe((response) => {
        if (!response.error) {
          this.verifyInfo = response;
          const detail = `We’ve sent a verification code to your ${verifyType === 'update ' ? 'updated' : ''}phone number.`;
          if (verifyType === 'update') {
            this.resendTimes = 0;
          }
          this.messageService.add({
            key: '2faMessage',
            severity: 'success',
            closable: false,
            detail});
          this.resendTimes++;
          this.currentStage = 'stage2-verifyPhone';
          this.startCountdown(cfg.VERIFY_RESEND_SECONDS);
        } else {
          this.messageService.add({
            key: '2faMessage',
            severity: 'error',
            closable: false,
            detail: response.error || 'An error occur when sending the verification code!'});
        }
      });
  }

  startCountdown(seconds: number): void {
    this.isResendDisabled = true;
    this.countdown = seconds;

    const countdownTimer = timer(0, 1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.isResendDisabled = false;
        countdownTimer.unsubscribe(); // Stop the timer
      }
    });
  }

  get isReCaptchaErrorV3(): boolean {
    return this.reCaptchaScoreV3 !== undefined && this.reCaptchaScoreV3 < this.disableScoreThreshold;
  }

  get failedReCaptchaV3(): boolean {
    return !this.reCaptchaScoreV3 || this.reCaptchaScoreV3 < this.disableScoreThreshold;
  }

}
