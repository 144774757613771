import {Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import * as helper from '../shared/helper';


@Component({
  selector: 'aux-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() content: string | undefined;
  @Input() buttonLabel: string | undefined;
  @Input() buttonClass: string | undefined;
  @Input() styleClass = '';
  @ViewChild('op2') op2: OverlayPanel | undefined;
  @ViewChild('i1') i1: ElementRef | undefined;
  @ViewChild('i2') i2: ElementRef | undefined;
  @ViewChild('hoverElement') hoverElement: ElementRef | undefined;
  isHover = false;
  private nativeHoverElement: any;
  private delayInMs = 550;
  private delayOutMs = 450;
  @HostListener('document:mousemove', ['$event'])
  async handleMouseMove(event: MouseEvent): Promise<void> {
    if (this.nativeHoverElement) {
      if (this.nativeHoverElement.parentNode?.matches(':hover')) {
        this.isHover = true;
        // console.log('handleMouseMove still in');
      } else {
        // console.log('handleMouseMove going out');
        this.isHover = false;
        await helper.delay(this.delayOutMs);
        if (!this.isHover) {
          this.op2?.hide();
        }
      }
    }
  }
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  async onMouserEnter(event: any, target: any): Promise<void> {
    this.isHover = true;
    // console.log('onMouserEnter', {isHover: this.isHover});
    await this.handleToggle(event, target);
  }

  async onMouserLeave(event: any, target: any): Promise<void> {
    this.isHover = false;
    await helper.delay(this.delayOutMs);
    // console.log('onMouserLeave', {isHover: this.isHover});
    if (!this.isHover) {
      this.op2?.hide();
    }
  }

  async handleToggle(event: any, target: any): Promise<void> {
    await helper.delay(this.delayInMs);
    // console.log('handleToggle', {isHover: this.isHover, opVisible: this.op2?.overlayVisible});
    if (this.isHover && !this.op2?.overlayVisible && this.content) {
      this.op2?.toggle(event, target);
    }
  }

  get componentTemplate(): any {
    return this.elementRef?.nativeElement;
  }

  onOverlayShow(): void {
    this.nativeHoverElement =  this.hoverElement?.nativeElement || null;
  }
  onOverlayHide(): void {
    this.nativeHoverElement = null;
  }
}
