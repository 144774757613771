import {TourService, IStepOption} from './tour.service';
import {Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
// import withinviewport from '';
import {TourStepTemplateService} from './tour-step-template.service';
import {TourStepTemplateComponent} from './tour-step-template.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tourAnchor]',
})
export class TourAnchorDirective implements OnInit, OnDestroy {
  @Input() public tourAnchor = '';
  @HostBinding('class.touranchor--is-active')
  public isActive = false;

  constructor(private tourService: TourService, private element: ElementRef, private templateService: TourStepTemplateService) {
  }

  public ngOnInit(): void {
    this.tourService.register(this.tourAnchor, this);
  }

  public ngOnDestroy(): void {
    this.tourService.unregister(this.tourAnchor);
  }

  public showTourStep(step: IStepOption): void {
    const opComponent: TourStepTemplateComponent = this.templateService.component as TourStepTemplateComponent;
    // console.group(step.title);
    // console.log(step.content);
    // console.log(`${step.placement || 'above'} ${this.tourAnchor}`);
    // console.groupEnd();
    if (opComponent) {
      opComponent.open(step, this.element.nativeElement);
    }
    // if (!step.preventScrolling) {
    //   if (!withinviewport(this.element.nativeElement, {sides: 'bottom'})) {
    //     (this.element.nativeElement as HTMLElement).scrollIntoView(false);
    //   } else if (!withinviewport(this.element.nativeElement, {sides: 'left top right'})) {
    //     (this.element.nativeElement as HTMLElement).scrollIntoView(true);
    //   }
    // }
  }

  public hideTourStep(step: IStepOption): void {
    setTimeout(() => {
      const opComponent: TourStepTemplateComponent = this.templateService.component as TourStepTemplateComponent;
      if (opComponent) {
        opComponent.endStep.emit(step);
      }
    });
  }

  public temporaryConceal(): void {
    const opComponent: TourStepTemplateComponent = this.templateService.component as TourStepTemplateComponent;
    if (opComponent) {
      opComponent.temporaryConceal();
    }
  }
}
