import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationGuard} from './helpers/authentication.guard';
import {ProfileLayoutComponent} from './profile/profile-layout.component';
import {JoinComponent} from './profile/join/join.component';
import {MypassComponent} from './profile/mypass/mypass.component';
import {LoginComponent} from './profile/login/login.component';
import {ProfileGuard} from './helpers/profile.guard';
import {LogoutComponent} from './profile/logout.component';
import {UnsubscribeComponent} from './profile/unsubscribe/unsubscribe.component';
import {DeviceErrorComponent} from './device-error/device-error.component';
import {IgnoreComponent} from './alerter/ignore/ignore.component';
import {KycComponent} from './media-blast/kyc/kyc.component';
import {PromotionComponent} from './promotion/promotion.component';
import {AuditRequestComponent} from './audit-request/audit-request.component';
import {MobileMyCloudComponent} from './mobile-my-cloud/mobile-my-cloud.component';

const routes: Routes = [
  {
    path: 'feeditor',
    loadChildren: () => import('./feeditor/feeditor.module').then(m => m.FeeditorModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'feeditor', path: '/feeditor'}
  },
  {
    path: 'feeditor-plus',
    loadChildren: () => import('./feeditor-plus/feeditor-plus.module').then(m => m.FeeditorPlusModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'feeditor-plus', path: '/feeditor-plus'}
  },
  {
    path: 'adcore',
    loadChildren: () => import('./views/views.module').then(m => m.ViewsModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'views', path: '/adcore'}
  },
  {
    path: 'semdoctor',
    loadChildren: () => import('./semdoctor/semdoctor.module').then(m => m.SemdoctorModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'semdoctor', path: '/semdoctor'}
  },
  {
    path: 'alerter',
    loadChildren: () => import('./alerter/alerter.module').then(m => m.AlerterModule),
    canActivate: [AuthenticationGuard],
    data: {appName: 'alerter', path: '/alerter'}
  },
  {
    path: 'hurricane',
    loadChildren: () => import('./hurricane/hurricane.module').then(m => m.HurricaneModule),
    canActivate: [AuthenticationGuard],
    data: {appName: 'hurricane', path: '/hurricane'}
  },
  {
    path: 'media-blast',
    loadChildren: () => import('./media-blast/media-blast.module').then(m => m.MediaBlastModule),
    canActivate: [AuthenticationGuard],
    data: {appName: 'mediablast', path: '/media-blast'}
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthenticationGuard],
    // pathMatch: 'full',
    data: {appName: 'settings', path: '/settings'}
  },
  {
    path: 'administration',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthenticationGuard],
    // pathMatch: 'full',
    data: {appName: 'admin', path: '/administration'}
  },
  {
    path: 'my-cloud',
    loadChildren: () => import('./my-cloud/my-cloud.module').then(m => m.MyCloudModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'my-cloud', path: '/my-cloud'}
  },
  // {
  //   path: 'effortless',
  //   loadChildren: () => import('./feeditor/feeditor.module').then(m => m.FeeditorModule),
  //   canActivate: [AuthenticationGuard],
  //   pathMatch: 'full',
  //   data: {appName: 'effortless', path: '/effortless'}
  // },
  {
    path: 'effortless-marketing',
    loadChildren: () => import('./effortless/effortless.module').then(m => m.EffortlessModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'effortless-marketing', path: '/effortless-marketing'}
  },
  {
    path: 'upgrade-login',
    loadChildren: () => import('./upgrade-login/upgrade-login.module').then(m => m.UpgradeLoginModule),
    canActivate: [AuthenticationGuard],
    pathMatch: 'full',
    data: {appName: 'upgrade-login', path: '/upgrade-login'}
  },
  {
    path: 'notDesktop', component: MobileMyCloudComponent, pathMatch: 'full'
  },
  // {
  //   path: 'notDesktop', component: DeviceErrorComponent, pathMatch: 'full'
  // },
  {
    path: 'join', component: ProfileLayoutComponent, children: [
      {path: '', component: JoinComponent}
    ], canActivate: [ProfileGuard]
  },
  {
    path: 'mypass', component: ProfileLayoutComponent, pathMatch: 'full', children: [
      {path: '', component: MypassComponent}
    ], canActivate: [ProfileGuard]
  },
  {
    path: 'unsubscribeMe', component: ProfileLayoutComponent, pathMatch: 'full', children: [
      {path: '', component: UnsubscribeComponent}
    ]
  },
  {
    path: '', component: ProfileLayoutComponent, pathMatch: 'full', children: [
      {path: '', component: LoginComponent}
    ]
  },
  {
    path: 'alerter-ignore', component: IgnoreComponent, pathMatch: 'full', children: [
      {path: '', component: IgnoreComponent}
    ]
  },
  {path: 'media-blast-kyc', component: KycComponent, pathMatch: 'full'},
  {path: 'promote', component: PromotionComponent, pathMatch: 'full'},
  {path: 'audit-request', component: AuditRequestComponent, pathMatch: 'full'},
  {path: 'login', pathMatch: 'full', redirectTo: ''},
  {path: 'semdoc', pathMatch: 'full', redirectTo: 'semdoctor'},
  {path: 'views', pathMatch: 'full', redirectTo: 'adcore'},
  {path: 'logout', pathMatch: 'full', component: LogoutComponent},
  {path: 'invitation', redirectTo: 'join'}, // backward compatibility
  {path: '**', redirectTo: ''}];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
