import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as helper from '../shared/helper';

@Component({
  selector: 'aux-delimiters',
  templateUrl: './delimiters.component.html',
  styleUrls: ['./delimiters.component.scss']
})
export class DelimitersComponent implements OnInit {
  @Input() delimiters: helper.IMediaRow[] = [];
  @Input() horizontal = true;
  @Output() delimiterChange: EventEmitter<helper.IMediaRow> = new EventEmitter<helper.IMediaRow>(false);
  @Input() rbKey = 'tab';
  @Input() translationService: any = null;
  selectedDelimiter: helper.IMediaRow = {} as helper.IMediaRow;


  constructor() {
  }

  ngOnInit(): void {
    this.onClick(null);
  }

  trackByMethod(index: any, row: any): any {
    return row.key;
  }

  translate(key?: string): string {
    return this.translationService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }
  onClick(event: any): void {
    this.activation(this.delimiters.find((d) => d.key === this.rbKey) || null);
  }

  onChange(event: any): void {
     this.delimiterChange.emit(this.selectedDelimiter);
  }

  private activation(delimiter: helper.IMediaRow | null): void {
    if (delimiter) {
      this.selectedDelimiter = delimiter;
      if (this.rbKey !== 'other') {
        this.delimiterChange.emit(delimiter);
      }
    }
  }
}
