import {Component, Input} from '@angular/core';

@Component({
  selector: 'aux-strong-pwd-feedback',
  templateUrl: './strong-pwd-feedback.component.html',
  styleUrl: './strong-pwd-feedback.component.scss'
})
export class StrongPwdFeedbackComponent {
@Input() passwordCriteria: any;
@Input() column = 2;
}
