<ul class="side-menu">
  <li  *ngFor="let tab of tabList;trackBy: trackByMethod; first as isFirst" [attr.isActive]="isTabActive(tab, isFirst)"
       [attr.isFirstTime]="tab.firstTime"
       [attr.disabled]="disabled || (!tab.alwaysEnabled && tab.disabled)"
       [ngStyle]="{'height.%': calcItemHeight()}"
       (click)="onActivate($event, tab)" [pTooltip]="tab.tooltip || ''">
    <div class="iconic" *ngIf="tab.icon && !tab.label">
      <i [class]="tab.icon"></i>
<!--      <label *ngIf="tab.label">{{tab.label}}</label>-->
    </div>
    <div class="iconic-with-text" *ngIf="tab.label">
      <span class="icon-box" *ngIf="tab.subType && !tab.icon"><i class="menu-icon" [class]="tab.subType"></i></span>
      <span class="icon-box" *ngIf="tab.icon"><i [class]="tab.icon"></i></span>
      <label *ngIf="tab.label" [tourAnchor]="tab.tourAnchor">{{tab.label}}</label>
    </div>
  </li>
</ul>

