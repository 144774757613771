<div class="p-inputgroup w-12 field">
  <p-inputNumber id="every" styleClass="mb-1" (onInput)="onInputParamChanged($event)" [(ngModel)]="param1" [min]="1"
               [max]="59" [showButtons]="false" buttonLayout="horizontal" incrementButtonIcon="pi pi-plus"
               decrementButtonIcon="pi pi-minus"></p-inputNumber>
  <span class="p-inputgroup-addon">{{suffix}}</span>
</div>
<div class="p-inputgroup w-12 mt-2 field">
  <span class="p-inputgroup-addon">{{parent.translate('ON_THE')}}</span>
  <p-multiSelect id="onDay" [options]="days" [(ngModel)]="param2" [placeholder]="parent.translate('SELECT_A_WEEKDAY')"
                 selectedItemsLabel="{0} {{parent.translate('ITEMS_SELECTED')}}" styleClass="dropdown-show" [panelStyleClass]="classStyle"
                  optionValue="value" (onChange)="onComboMultiChange($event)" [showHeader]="false"
                 [maxSelectedLabels]="3"  scrollHeight="300px" appendTo="body" [style]="{'width': '190px'}"></p-multiSelect>
</div>
