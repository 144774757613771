import {Injectable} from '@angular/core';
import {WindowRefService} from './windowref.service';

// https://github.com/FBerthelot/angular-images-resizer
@Injectable({
  providedIn: 'root'
})
export class ImageResizerService {
  isCanvasSupported = true;
  isFileReaderSupported = true;

  constructor(private winRef: WindowRefService) {
    this.isCanvasSupported = !!(winRef.nativeDocument.createElement('canvas').getContext
      && winRef.nativeDocument.createElement('canvas').getContext('2d'));
    this.isFileReaderSupported = !!(this.winRef.nativeWindow.File && this.winRef.nativeWindow.FileReader
      && this.winRef.nativeWindow.FileList && this.winRef.nativeWindow.Blob);
  }

  private resizeCanvas(cnv: any, width: number, height: number): any {
    if (!width || !height) {
      return cnv;
    }

    const tmpCanvas = this.winRef.nativeDocument.createElement('canvas');
    tmpCanvas.width = width;
    tmpCanvas.height = height;
    const cnx = tmpCanvas.getContext('2d');
    cnx.drawImage(cnv, 0, 0, tmpCanvas.width, tmpCanvas.height);
    return tmpCanvas;
  }

  private resizeImageWidthHeight(image: any, width: number, height: number, step: number, outputFormat: string): string | null {
    if (!image) {
      return null;
    }
    if (!outputFormat) {
      outputFormat = 'image/jpeg';
    }

    let mainCanvas = this.winRef.nativeDocument.createElement('canvas');
    // Check what width and height the resized image must be !
    if (!width && !height) {
      width = image.width;
      height = image.height;
    } else if (!width && height) {
      width = (height / image.height) * image.width;
    } else if (width && !height) {
      height = (width / image.width) * image.height;
    }

    const pixelStepWidth = (image.width === width) || !step ? 0 : (image.width - width) / step;
    const pixelStepHeight = (image.height === height) || !step ? 0 : (image.height - height) / step;
    mainCanvas.width = image.width;
    mainCanvas.height = image.height;

    mainCanvas.getContext('2d').drawImage(image, 0, 0, mainCanvas.width, mainCanvas.height);
    for (let i = 1; i < step; i++) {
      const newWidth = image.width - (pixelStepWidth * i);
      const newHeight = image.height - (pixelStepHeight * i);
      mainCanvas = this.resizeCanvas(mainCanvas, newWidth, newHeight);
    }
    mainCanvas = this.resizeCanvas(mainCanvas, width, height);

    return mainCanvas.toDataURL(outputFormat);
  }

  private resizeImageBySize(image: any, targetSize: number, outputFormat: string): string | null {
    if (!image) {
      return null;
    }
    if (!outputFormat) {
      outputFormat = 'image/jpeg';
    }

    let mainCanvas = this.winRef.nativeDocument.createElement('canvas');
    mainCanvas.width = image.width;
    mainCanvas.height = image.height;
    mainCanvas.getContext('2d').drawImage(image, 0, 0, mainCanvas.width, mainCanvas.height);

    let tmpResult = mainCanvas.toDataURL(outputFormat);
    let result = tmpResult;

    let sizeOfTheImage = this.calculateImageSize(tmpResult, outputFormat);
    let divideStrategy = Math.max(1, Math.min(sizeOfTheImage / targetSize, 200));

    let iteratorLimit = 20;
    while (sizeOfTheImage > targetSize && iteratorLimit !== 0) {
      iteratorLimit--;

      const newImageSize = {
        width: mainCanvas.width / divideStrategy,
        height: mainCanvas.height / divideStrategy
      };
      const canvas = this.resizeCanvas(mainCanvas, newImageSize.width, newImageSize.height);

      tmpResult = canvas.toDataURL(outputFormat);
      const sizeOfTheImageTmp = this.calculateImageSize(tmpResult, outputFormat);

      // If result is too far away from target, restart dividing with less agressive strategy.
      if (sizeOfTheImageTmp / targetSize < 0.5 || sizeOfTheImageTmp === 0) {
        divideStrategy = divideStrategy / 2;
        // If the divide strategy is below 1, it's mean, that we cannot resize anymore so we stop the loop
        if (divideStrategy < 1) {
          iteratorLimit = 0;
        }
      } else { // next iteration will start with a new canvas
        mainCanvas = canvas;
        result = tmpResult;
        sizeOfTheImage = sizeOfTheImageTmp;
      }

      mainCanvas = canvas;
    }
    return result;
  }

  calculateImageSize(imgString: string, outputFormat: string): number {
    switch (outputFormat) {
      case 'image/jpeg':
        outputFormat = 'image/jpg';
        break;
      default :
        outputFormat = 'image/jpg';
        break;
    }
    return Math.max(0, Math.round((imgString.length - ('data:' + outputFormat + ';base64,').length) * 3 / 4));
  }

  private createImage(src: string, crossOrigin: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      if (crossOrigin) {
        img.crossOrigin = 'anonymous';
      }
      img.onload = () => {
        resolve(img);
      };
      img.onabort = () => {
        reject('image creation was aborted');
      };
      img.onerror = (err) => {
        reject(err);
      };
      img.src = src;

    });
  }

  readFileInput(input: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!input.files || !input.files[0]) {
        reject('No file selected');
      } else {
        if (!this.isFileReaderSupported) {
          reject('Your browser do not support reading file');
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target?.result);
        };
        reader.onabort = (e) => {
          reject('Fail to convert file in base64img, aborted: ' + e.target?.error);
        };
        reader.onerror = (e) => {
          reject('Fail to convert file in base64img, error: ' + e.target?.error);
        };
        reader.readAsDataURL(input.files[0]);
      }
    });
  }

  // height: desired height of the resized image
  // width: desired width of the resized image
  // size: desired size of the resized image (Size are by default in Octet)
  // sizeScale: 'o' || 'ko' || 'mo' || 'go'
  // step: number of step to resize the image, by default 3. Bigger the number,
  // better is the final image. Bigger the number, bigger the time to resize is.
  // outputFormat: specify the image type. Default value is 'image/jpeg'.
  // [Check this page to see what format are supported.] (https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL)
  // crossOrigin: Specify the crossOrigin of the image to resize
  resizeImage(src: string, options: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.isCanvasSupported) {
        reject('Canvas is not supported on your browser');
      }
      if (!options || !src) {
        return reject('Missing argument when calling resizeImage function');
      }
      options = {
        height: options.height ? options.height : options.width ? null : options.size ? null : 1024,
        width: options.width ? options.width : options.height ? null : options.size ? null : 1024,
        size: options.size ? options.size : 500,
        sizeScale: options.sizeScale ? options.sizeScale : 'ko',
        step: options.step ? options.step : 3,
        outputFormat: options.outputFormat ? options.outputFormat : 'image/jpeg',
        crossOrigin: options.crossOrigin ? options.crossOrigin : null
      };
      this.createImage(src, options.crossOrigin).then((img) => {
        if (options.height || options.width) {
          resolve(this.resizeImageWidthHeight(img, options.width, options.height, options.step, options.outputFormat));
        } else if (options.size) {
          if (typeof options.sizeScale === 'string') {
            switch (options.sizeScale.toLowerCase()) {
              case 'ko':
                options.size *= 1024;
                break;
              case 'mo':
                options.size *= 1024 * 1024;
                break;
              case 'go':
                options.size *= 1024 * 1024 * 1024;
                break;
            }
            resolve(this.resizeImageBySize(img, options.size, options.outputFormat));
          }
        } else {
          reject('Missing option to resize the image');
        }
      }).catch(reason => reject(reason));
    });
  }
}
