import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[auxTextFileRead]'
})
export class TextFileReadDirective {
  @Output() fileChoose: EventEmitter<File | null> = new EventEmitter<File | null>();
  constructor(private el: ElementRef) {
  }

  @HostListener('change', ['$event.target.files'])  onChange(event: FileList): void {
    // console.log({event});
    const fileInfo: File | null = (event && event.length ) ? event[0] : null;
    this.fileChoose.emit(fileInfo);
  }

}
