<p-menu #menu [popup]="true" [model]="additionalMenu" (onShow)="onShowVersionMenu($event)"></p-menu>
<ng-template #mediaRow let-caption="caption" let-rows="rows" let-nodata="nodata" let-mode="mode" let-loading="loading" let-showEditIcon="showEditIcon">
  <div class="list">
    <span class="caption" *ngIf="caption"><label>{{caption}}</label>
      <div class="loader" *ngIf="loading">
        <div></div>
      </div>
    </span>
    <br/>
    <ul [attr.isSearch]="isSearchEnabled">
      <li class="nodata" *ngIf="!rows?.length && nodata">
        {{nodata}}
      </li>
      <ng-container *ngIf="rows?.length">
        <li *ngFor="let row of rows;trackBy: trackByMethod;index as i;">
          <div class="flex justify-content-between mb-2" #container>
            <div class="mr-2">
              <span class="account-icons">
                <span class="float-icon" *ngIf="mode === 'account'"><i [class]="getTypeIconClass(row.type)"></i></span>
                <span class="icon-box-align" *ngIf="mode !== 'version' && mode !== 'advertiser'"><i [class]="getSubTypeIconClass(row.subType)"></i></span>
                <span class="icon-box-align" *ngIf="mode === 'advertiser' && getSubTypeIconClass(row.subType) === 'pi pi-users'"><i class="pi pi-users"></i></span>
              </span>
              <span #span class="media-label pointer" [attr.selected]="hideSelected && row.key === selectedMedia?.key"
                    [style.width.px]="mode === 'version' ? 320 : 200"
                    [pTooltip]="addTooltipToSelectedMedia(encryptName(row.label, i), span)" [tooltipStyleClass]="classStyle"
                    (click) ="onRowClick($event, row)"
                    tooltipPosition="top">{{encryptName(row.label, i)}}</span>
            </div>
            <span *ngIf="mode === 'feed' || mode === 'feed_plus' && showEditIcon" class="edit-feed pointer" (click)="editFeedFromMenu(row)"></span>
            <div *ngIf="mode === 'account'" class="customer-id"><span>{{encryptId(row.customerId, i)}}&nbsp;</span></div>
          </div>
        </li>
      </ng-container>
    </ul>
    <div class="list-divider" *ngIf="rows?.length"><p-divider></p-divider></div>
  </div>
</ng-template>


<ng-container *ngIf="(!mediaConnector || !mediaConnector.hide || mediaConnector.total > 0) && (!mediaConnector || !mediaConnector.forceHide)">
  <div class="actual-target" [ngClass]="{'media-focus-btn':this.isPanelOpen}">
    <div *ngIf="configuration?.title" class="media-component-title">{{configuration.title}}</div>
    <div #actualTarget class="inline-flex align-items-center relative" [attr.disabled]="disabled" *ngIf="!editableProperty">
      <span *ngIf="!selectionInProcess && selectedMedia && additionalMenu.length"  class="additional-menu-btn" [attr.isGreen]="isGreen" [attr.disabled]="disabled" (click)="openVersionMenu($event)"></span>
      <div #label class="media-title selected-media"  [attr.isDemo]="selectedMedia?.label?.toLowerCase() === 'demo feed'" [pTooltip]="addTooltipToSelectedMedia(selectedMedia?.label, label)" [tooltipStyleClass]="classStyle" *ngIf="!selectionInProcess"  (click)="toggleOverlayPanel($event, actualTarget)">
        {{encryptName(selectedMedia?.label || '', 0) || defaultLabel}}
      </div>
      <div class="media-title" *ngIf="selectionInProcess">
        <div class="loader">
          <div></div>
        </div>
      </div>
      <div #dropDownButton style="display:none; width: 1px;height: 1px" (click)="toggleOverlayPanel($event, actualTarget)"></div>
      <p-button icon="down-icon" styleClass="p-button-rounded p-button-text down-btn" [disabled]="disabled" [rounded]="true" [text]="true"  (click)="toggleOverlayPanel($event, actualTarget)"></p-button>
    </div>
    <div #actualTarget class="inline-flex align-items-center"  [attr.disabled]="disabled" *ngIf="editableProperty">
      <span *ngIf="!selectionInProcess && selectedMedia && additionalMenu.length"  class="additional-menu-btn" [attr.isGreen]="isGreen" [attr.disabled]="disabled" (click)="openVersionMenu($event)"></span>
      <p-inplace #inplaceEdit *ngIf="!selectionInProcess" [closable]="false" [preventClick]="false" [disabled]="disabled" (onActivate)="onClickOnLabel($event)">
        <ng-template pTemplate="display">
          <div #label class="media-title selected-media" [attr.isDemo]="selectedMedia?.label?.toLowerCase() === 'demo feed'"
               [pTooltip]="addTooltipToSelectedMedia(selectedMedia?.label, label)" [tooltipStyleClass]="classStyle">
            {{selectedMedia?.label || defaultLabel}}
          </div>
        </ng-template>
        <ng-template pTemplate="content">
<!--     (clickOutside)="onEditFinished($event)"     -->
<!--          [exclude]="'.media-title'"-->
          <input class="mini-input" #labelEdit   type="text" [(ngModel)]="editObject.changedValue"  pInputText (keyup.enter)="onEditFinished($event)" (keyup.escape)="onEditCancel($event)">
        </ng-template>
      </p-inplace>
      <div class="media-title" *ngIf="selectionInProcess">
        <div class="loader">
          <div></div>
        </div>
      </div>
      <div #dropDownButton style="display:none; width: 1px;height: 1px" (click)="toggleOverlayPanel($event, actualTarget)"></div>
      <p-button icon="down-icon"  styleClass="p-button-rounded p-button-text down-btn" [text]="true" [rounded]="true" [disabled]="disabled" (onClick)="toggleOverlayPanel($event, actualTarget)"></p-button>
    </div>
  </div>
</ng-container>

<p-overlayPanel #op [dismissable]="false" [appendTo]="componentTemplate" [focusOnShow]="true" styleClass="no-arrow-panel media-panel" (onHide)="onHide()" (onShow)="onShowPanel()" (clickOutside)="onPanelOutsideClick($event)">
  <ng-template  pTemplate="content">
    <ng-container *ngIf="isSearchEnabled">
      <div class="p-inputgroup">
          <div class="p-input-icon-left w100 relative">
              <div class="loader" *ngIf="searchParam.inProgress">
                <div></div>
              </div>
              <i class="pi pi-search pointer" *ngIf="!searchParam.inProgress" (click)="search()" id="search-progress-icon"></i>
              <input type="text" #searchInput id="media-search-input"  pInputText autofocus [(ngModel)]="searchParam.searchQuery" [placeholder]="configuration.searchPlaceholder" class="w100 media-search-input" (keydown.enter)="search()" (keyup)="searchWithTimer()"/>
          </div>
          <p-button styleClass="p-button-sm p-button-rounded p-button-text close-btn search-disabler-button" [rounded]="true" [text]="true" size="small" icon="pi pi-times search-disabler-button"  id="search-disabler-button" (onClick)="showHideSearch()"></p-button>
      </div>
      <!--<p-divider></p-divider>-->
      <ng-template *ngTemplateOutlet="mediaRow;context: {rows: searchResults, nodata: configuration.noMatchingLabel, mode: configuration.mode, showEditIcon: false}">
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!isSearchEnabled">
      <span class="p-input-icon-right w100">
        <i class="pi pi-search pointer" (click)="showHideSearch()" id="search-enabler-icon"></i>
        <input type="text" id="search-enabler-input" pInputText class="w100 media-search-input" [value]="(!hideSelected) ? encryptName(selectedMedia?.label || '', 0) : ''" [readOnly]="true" (click)="showHideSearch()" [disabled]="loadParam.inProgress">
      </span>
      <!--<p-divider></p-divider> -->
      <div class="media-lists-container" [attr.isRecentDefined]="isRecentDefined">
        <ng-container *ngIf="isRecentDefined">
          <ng-template *ngTemplateOutlet="mediaRow;context: {caption: configuration.recentLabel, rows: recentMedia, mode: configuration.mode, showEditIcon: false}">
          </ng-template>
        </ng-container>
        <ng-template *ngTemplateOutlet="mediaRow;context: {caption: configuration.mediaLabel, rows: loadResults, nodata: configuration.noDataLabel, showEditIcon: true, mode: configuration.mode, loading: loadParam.inProgress}">
        </ng-template>
      </div>
    </ng-container>
    <div class="flex justify-content-between media-footer-btn">
      <p-button styleClass="panel-load-more-link p-button-link" *ngIf="totalMediaRows > 10 && totalMediaRows > loadResults.length" [label]="configuration.loadMoreLabel" (onClick)="onLoadMore()" [disabled]="loadParam.inProgress || false" [link]="true"></p-button>
      <div  *ngIf="totalMediaRows <= 10 || totalMediaRows <= loadResults.length"></div>
      <div>
        <p-button styleClass="p-button-link mr-3 edit-btn" *ngIf="configuration.isMediaEditVisible && selectedMedia" [label]="configuration.editMediaLabel || ''" [icon]="'pencil-icon'" (onClick)="onEditMedia($event)" [disabled]="loadParam.inProgress || searchParam.inProgress || false" [link]="true"></p-button>
        <p-button styleClass="p-button-link" *ngIf="configuration.isGoToVisible" [label]="configuration.allMediaBrowseLabel" (onClick)="goToManagement($event)" [disabled]="loadParam.inProgress || searchParam.inProgress || false" [link]="true"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
