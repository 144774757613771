import {MenuItem} from 'primeng/api';

export interface IMediaRow {
  key: string;
  label: string | '';
  value: any | {};
  type: EMediaType | EMediaType.NONE;
  subType: EMediaSubType | EMediaSubType.NONE;
  visible: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  tooltip?: string;
  createdOn?: any;
  isNewMedia?: boolean | false;
  switchPlan?: boolean | false;
  [optional: string]: any;
}

export interface IMediaConfiguration {
  mode: EMediaMode;
  recentLabel: string;
  mediaLabel: string;
  allMediaBrowseLabel: string;
  editMediaLabel?: string;
  searchPlaceholder: string;
  noMatchingLabel: string;
  noDataLabel: string;
  loadMoreLabel: string;
  isGoToVisible: boolean;
  isMediaEditVisible?: boolean;
  additionalMenu?: MenuItem[] | undefined;
  defaultLabel?: string | undefined;
  title?: string | undefined;
  editMedia?: (event: MouseEvent, mode: EMediaMode) => void;
  isMenuItemVisible?: (item: MenuItem) => boolean;
  isAdditionalMenuGreen?: () => boolean;
  editFeedCommand?: any;
  isAdmin?: boolean;
  isAnyAdmin?: boolean;
}


export enum EGoTo {
  MY_PROFILE,
  SIGN_OUT,
  PRIVACY_POLICY,
  ADD_ADVERTISER,
  FEED_MANAGEMENT,
  MEDIA_MANAGEMENT,
  USER_MANAGEMENT,
  NEW_VERSION
}

export interface ICustomDate {
  from?: string;
  to?: string;
  deltaToday?: number | null;
  deltaYesterday?: number | null;
}

export interface ITimePeriod {
  type: ETimePeriod;
  customDate?: ICustomDate;

  [optional: string]: any;
}

// Valid DateRange Types	Reports are generated for...
// TODAY	Today only.
// YESTERDAY	Yesterday only.
// LAST_7_DAYS	The last 7 days not including today.
// LAST_WEEK	The seven-day period starting with previous Monday.
// LAST_BUSINESS_WEEK	The 5 day business week, Monday through Friday, of the previous business week.
// THIS_MONTH	All days in the current month.
// LAST_MONTH	All days in the previous month.
// ALL_TIME	The entire available time range.
// CUSTOM_DATE	A custom date range. See Custom date ranges below for more information.
// LAST_14_DAYS	The last 14 days not including today.
// LAST_30_DAYS	The last 30 days not including today.
// LAST_60_DAYS	The last 60 days not including today.
// THIS_WEEK_SUN_TODAY	The period between the previous Sunday and the current day.
// THIS_WEEK_MON_TODAY	The period between the previous Monday and the current day.
// LAST_WEEK_SUN_SAT	The seven-day period starting with the previous Sunday.
export enum ETimePeriod {
  NONE = 'NONE',
  LAST_RUN = 'LAST_RUN',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_WEEK = 'LAST_WEEK',
  LAST_WEEK_SUN_SAT = 'LAST_WEEK_SUN_SAT',
  LAST_WEEK_MON_SUN = 'LAST_WEEK_MON_SUN',
  LAST_BUSINESS_WEEK = 'LAST_BUSINESS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  ALL_TIME = 'ALL_TIME',
  CUSTOM_DATE = 'CUSTOM_DATE',
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_60_DAYS = 'LAST_60_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  THIS_WEEK_SUN_TODAY = 'THIS_WEEK_SUN_TODAY',
  THIS_WEEK_MON_TODAY = 'THIS_WEEK_MON_TODAY',
  CUSTOM_DATE_UP_TO_TODAY = 'CUSTOM_DATE_UP_TO_TODAY',
  CUSTOM_DATE_UP_TO_YESTERDAY = 'CUSTOM_DATE_UP_TO_YESTERDAY'
}

export interface IProfile {
  pictureURL: string;
  fullName: string;
  userName: string;
  signOutLabel: string;
  profileLabel: string;
  privacyPolicyLabel: string;
}

export interface IGoTo {
  originalEvent?: MouseEvent;
  whereToGo: EGoTo;
  options?: any;
  mode: EMediaMode;
  total?: number;
}

export enum EMediaMode {
  NONE = '',
  ADVERTISER = 'advertiser',
  ACCOUNT = 'account',
  FEED = 'feed',
  FEED_PLUS = 'feed_plus',
  HELP = 'help',
  VERSION = 'version',
  PAGE = 'page',
  OTHER = 'other',
  ITEM = 'item'
}

export enum EMediaType {
  NONE = '',
  ADVERTISER = 'advertiser',
  CHANNEL = 'channel',
  ACCOUNT = 'account',
  CAMPAIGN = 'campaign',
  MCC = 'mcc',
  FEED = 'feed',
  FEED_PLUS = 'feed_plus',
  VIEW = 'view',
  VERSION = 'version',
  JOB = 'job',
  PAGE = 'page',
  DYNAMIC = 'dynamic',
  REPORT = 'report',
  OPTIMIZATION = 'optimization',
  NOTIFICATION = 'notification',
  SYSTEM = 'system',
  SUBMISSION = 'submission',
  SHOPPING = 'shopping',
  OTHER = 'other',
  MERCHANT = 'merchant',
  MCA = 'mca',
}

export enum EMediaSubType {
  NONE = '',
  WIX = 'wix',
  SHOPIFY = 'shopify',
  TEXT = 'text',
  LINK = 'link',
  FILE = 'file',
  SYSTEM = 'system',
  AGENCY = 'agency',
  CLIENT = 'client',
  MICROSOFT = 'microsoft',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  SHOPPING = 'shopping',
  SEMDOCTOR = 'semdoctor',
  OVERVIEW = 'account_only',
  CHECKLIST = 'checklist',
  QUICKWIN = 'quickwin',
  CAMPAIGNS = 'campaigns_only',
  ASSETS = 'assets',
  SEARCHTERM = 'searchterm',
  SEARCHQUERY = 'searchquery',
  REPORT = 'report',
  PRODUCT = 'product',
  KEYWORD = 'keyword',
  COCKPIT = 'cockpit',
  FIXIT = 'fixit',
  FEEDIT = 'feedit',
  EFFORTLESSFEED = 'effortlessfeed',
  CAMPAIGN = 'campaign',
  AUDIENCE = 'audience',
  ADGROUP = 'adgroup',
  AD = 'ad',
  ACCOUNTS = 'accounts',
  ACCOUNT = 'account',
  OTHER = 'other',
  MANUAL = 'manual',
  SCHEDULED = 'scheduled'
}

export interface ISearchParam {
  mode: EMediaMode | EMediaMode.NONE;
  searchQuery: string;
  searchEnd?: (searchResults: IMediaRow[], total?: number) => void;
  inProgress: boolean | false;
  skip: number | 0;

  [optional: string]: any;
}

export interface IMediaSelectedEvent {
  mode: EMediaMode;
  row: IMediaRow | null;
  originEvent?: MouseEvent;
  [optional: string]: any;
}

export interface IMediaEditEvent {
  mode: EMediaMode;
  row: IMediaRow | null;
  originEvent?: MouseEvent;
  originValue: any;
  changedProp: string;
  changedValue: any;
  createNew?: boolean;

  [optional: string]: any;
}

export interface ILoadMoreEvent {
  mode: EMediaMode;
  skip: number | 0;
  loadEnd?: (loadResults: IMediaRow[], total?: number) => void;
  inProgress: boolean;

  [optional: string]: any;
}

export const isOverflowing = (el: any): boolean => {
  return (el?.offsetWidth < el?.scrollWidth);
};

export interface IHelpItem {
  label: string;
  url: string;
  tab?: string | null;
  order?: number;
  app?: string | null;
  key?: string | null;
  _id?: string | null;
  active?: boolean;
}

export interface IMediaConnector {
  hide: boolean;
  total: number;
  forceHide?: boolean;
}

export interface IMerchantFtpInfo {
  engine: string;
  feedName: string;
  ftpUser: string;
  ftpPassword: string;
  export_format: string;
  ftpDetails?: IFtpDetails;
  selectedAccount?: IMediaRow;
  columnId: string;
  targetCountry: string;
  channel: string;
  language: string;
  isDirectUpload?: boolean;
  feedId: string;
}

export interface IFtpDetails {
  host: string;
  port: number;
  remoteRoot: string;
  protocol: string;
}

export enum EScheduleType {
  NEVER = 'NEVER',
  NOW = 'NOW',
  ONE_TIME = 'ONE_TIME',
  MINUTELY = 'MINUTELY',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NONE = ''
}

export interface ISchedule {
  repeater: EScheduleType;
  label?: string;
  inactive?: boolean;
  repeaterOptions?: any[];
  isSpecificHour?: boolean;
  timezone?: string;

  [optional: string]: any;
}

export interface IGetLink {
  viewId?: string;
  viewType?: string;
  feedId?: string;
  userEmail?: string;
  generatedDate?: string | null;
  currentActionKey?: string | null;
}

export interface IGetLinkResult {
  exportURL: string;
  exportFormat: string;
  [optional: string]: any;
}


export const safeListAt = (list: any[] | undefined, index: number, defaultValue: any = null): any => {
  if (list && list.length && index < list.length) {
    return list[index];
  }
  return defaultValue;
};

export const safeListFromIndex = (list: any[] | undefined, index: number, defaultValue: any[] = []): any => {
  return list?.slice(index + 1) || defaultValue;
};

export const safeToObjProp = (obj: any | undefined, propName: string, newValue: any): void => {
  if (obj) {
    obj[propName] = newValue;
  }
};

export const delay = (ms: number = 300) => {
  return new Promise( resolve => setTimeout(resolve, ms) );
};

export interface IAction {
  componentKey: string;
  key: string;
  data?: any;
  icon?: string;
  visible?: boolean;
  validate?: () => void;
  [optional: string]: any;
}

