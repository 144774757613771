<p-progressBar mode="indeterminate" styleClass="main-progressbar" [style]="{ height: '6px' }" *ngIf="loading"></p-progressBar>
<ng-container *ngIf="!loading">
    <p-toolbar [styleClass]="trialStyleClass" *ngIf="isShowTrialPanel">
        <ng-template pTemplate="start">
            <div class="panel-info font-bold"><i class="pi pi-info-circle mr-2"></i><label>{{trialText}}</label></div>
        </ng-template>
        <ng-template pTemplate="end">
            <div class="flex flex-column justify-content-center">
                <div class="days-left">{{daysTillEnd}} {{translate('DAYS_LEFT')}}</div>
                <p-progressBar [value]="progress" [showValue]="false"></p-progressBar>
            </div>
        </ng-template>
    </p-toolbar>
    <ng-container *ngIf="!importFeedForm">
        <div class="flex flex-column flex-nowrap main-section" [ngClass]="isShowTrialPanel ? 'show-panel' : ''">
            <div class="main-section-align">
                <div class="font-bold main-title">{{welcomeMessage}}</div>
                <ng-container *ngIf="!gotIt">
                    <div class="font-bold align-title">{{translate('TAKE_THE_FIRST_ACTION')}}</div>
                    <p-button [label]="translate('IMPORT-FEED')" severity="secondary"
                              styleClass="btn-primary w-full" (onClick)="importFeedForm=true" [disabled]="isEnded"></p-button>
                    <ul class="list-none p-0">
                        <li class="flex align-items-center">
                            <i class="pi pi-check-circle"></i>
                            <span>{{translate('GET_INSTANT_FEED_HEALTH_CHECK_TO_YOUR_MAILBOX')}}</span>
                        </li>
                        <li class="flex align-items-center">
                            <i class="pi pi-check-circle"></i>
                            <span>{{translate('START_OPTIMIZING_YOUR_FEED_DESKTOP_ONLY')}}</span>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="gotIt">
                    <div class="font-bold">{{translate('TAKE_ADDITIONAL_ACTIONS')}}</div>
                    <ul class="list-none info p-0">
                        <li class="flex">
                            <i class="pi pi-info-circle"></i>
                            <span>{{translate('APPLY_CUSTOM_ACTIONS_TO_YOUR_FEED_AND_EXPORT')}}</span>
                        </li>
                    </ul>
                </ng-container>
                <table class="video-list">
                    <thead>
                        <tr>
                            <th colspan="2">
                                <div class="font-bold text-left">{{translate('WATCH_AND_LEARN')}}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listItems">
                            <td><app-mobile-video-item [listItem]="item.col1" [showDetails]="false"></app-mobile-video-item> </td>
                            <td><app-mobile-video-item [listItem]="item.col2" [showDetails]="false"></app-mobile-video-item></td>
                        </tr>
                    </tbody>
    
                    <tfoot>
                        <tr>
                            <td colspan="2" class="text-center">
                                <p-button styleClass="btn-secondary-link" [label]="translate('LOAD_MORE')" icon="pi pi-angle-down"
                                          iconPos="right" [link]="true"
                                (onClick)="loadMore()" [disabled]="loadingList"></p-button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="importFeedForm">
        <ng-container *ngIf="!importFeedFormSent">
            <div class="flex flex-column flex-nowrap main-section main-form" [ngClass]="isShowTrialPanel ? 'show-panel' : ''">
                <div class="main-section-align">
                    <div class="flex align-items-center justify-content-between">
                        <div class="font-bold main-title">{{translate('IMPORT-FEED').toLowerCase()}}</div>
                        <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="secondary" (onClick)="importFeedForm=false" [disabled]="importing"></p-button>
                    </div>
                    <div class="field">
                        <label for="feedName" class="col-fixed title-row">{{translate('FEEDNAME')}}</label>
                        <div class="col input-row w-full">
                            <input id="feedName" type="text" [(ngModel)]="feedData.feedName" [placeholder]="translate('FEED_NAME_LABEL')" pInputText>
                        </div>
                    </div>
                    <div class="field">
                        <label for="feedUrl" class="col-fixed title-row">{{translate('FEED_URL_LABEL')}}</label>
                        <div class="col input-row w-full">
                            <input id="feedUrl" type="text" [(ngModel)]="feedData.feedUrl" [placeholder]="'https://www.myfeed.com/category/?id=brand'" pInputText  [ngClass]="{'ng-valid': !feedUrlError,
                            'ng-invalid': feedUrlError}" (blur)="feedUrlError = false;">
                        </div>
                    </div>
                    <div class="field">
                        <label for="feedEmail" class="col-fixed title-row">{{translate('NOTIFICATION_EMAIL')}}</label>
                        <div class="col input-row w-full">
                            <input id="feedEmail" type="email" [(ngModel)]="feedData.feedEmail" [placeholder]="translate('NOTIFICATION_EMAIL')" pInputText>
                        </div>
                    </div>
                    <div class="flex align-items-center green-palette checkbox-align">
                        <p-checkbox name="feedIsSecured" [(ngModel)]="feedData.feedIsSecured" [binary]="true" id="feedIsSecured"
                                    [label]="translate('FEED_IS_SECURED_LABEL')"></p-checkbox>
                    </div>
                    <ng-container *ngIf="feedData.feedIsSecured">
    
                        <div class="field">
                            <label for="feedUsername" class="col-fixed title-row">{{translate('FEED_USERNAME_LABEL')}}</label>
                            <div class="col input-row w-full">
                                <input id="feedUsername" type="text" [(ngModel)]="feedData.feedUsername"
                                [placeholder]="translate('FEED_USERNAME_LABEL')" autocomplete="off" pInputText>
                            </div>
                        </div>
                        <div class="field">
                            <label for="feedPassword" class="col-fixed title-row">{{translate('FEED_PASSWORD_LABEL')}}</label>
                            <div class="col input-row w-full">
                                <input id="feedPassword" type="password" [(ngModel)]="feedData.feedPassword" [placeholder]="translate('FEED_PASSWORD_LABEL')"
                                autocomplete="new-password" pInputText>
                            </div>
                        </div>
                    </ng-container>
    
                    <p-button [label]="translate('IMPORT-FEED')" severity="secondary" [loading]="importing"
                              styleClass="btn-primary w-full" (onClick)="importFeed()" [disabled]="isEnded || !valid"></p-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="importFeedFormSent">
            <div class="flex flex-column flex-nowrap main-section text-center" [ngClass]="!importFeedFormSentError ? 'succ-view' : 'err-view'" [attr.hasError]="importFeedFormSentError">
                <div class="main-section-align">
                    <ng-container *ngIf="!importFeedFormSentError">
                        <div>
                            <div class="icons"><i></i><span class="confetti"></span></div>
                            <div class="font-bold main-title">{{translate('YOUVE_RECEIVED_A_BONUS').toLowerCase()}}</div>
                            <div class="content" [innerHTML]="translate('THE_FEED_HAS_BEEN_SUCCESSFULLY_IMPORTED')"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="importFeedFormSentError">
                        <div>
                            <div class="icons"><i></i></div>
                            <div class="font-bold main-title">{{translate('FEED_IMPORT_ERROR').toLowerCase()}}</div>
                            <div class="content" [innerHTML]="translate('THERE_WAS_AN_ERROR_WHILE_IMPORTING_THE_FEED')"></div>
                        </div>
                    </ng-container>
                    <p-button [label]="translate('GOT_IT')" severity="secondary"
                              styleClass="btn-primary w-full" (onClick)="importFeedForm=false; gotIt=true;"></p-button>    
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

