<svg [attr.width]="width" [attr.height]="height">
  <g>
    <g *ngIf="!isLast">
      <line [attr.x1]="goalLineX" y1="0" [attr.x2]="goalLineX" [attr.y2]="height" stroke-dasharray="5"
            [attr.stroke]="goalLineColor" />
    </g>
    <g *ngIf="isLast">
      <line [attr.x1]="goalLineX" y1="0" [attr.x2]="goalLineX" [attr.y2]="height - lastSignWidth" stroke-dasharray="5"
            [attr.stroke]="goalLineColor" />
      <line [attr.x1]="goalLineX - lastSignWidth" y1="0" [attr.x2]="goalLineX"
            [attr.y1]="height" [attr.y2]="height - lastSignWidth" stroke-width="2"
            [attr.stroke]="goalLineColor" />
      <line [attr.x1]="goalLineX" y1="0" [attr.x2]="goalLineX + lastSignWidth"
            [attr.y1]="height - lastSignWidth" [attr.y2]="height" stroke-width="2"
            [attr.stroke]="goalLineColor" />
    </g>
  </g>
  <g>
    <linearGradient [attr.id]="gradId" [attr.x1]="x1" [attr.x2]="x2" gradientUnits="objectBoundingBox">
      <stop *ngFor="let stopColor of stopColors" [attr.offset]="stopColor.offset" [attr.stop-color]="stopColor.color"/>
    </linearGradient>
    <path [attr.d]="sliderPath" attr.fill="url(#{{gradId}})"></path>
  </g>
  <g>
    <circle *ngIf="isPositionValid()" [attr.cx]="indicatorCenter.x" [attr.cy]="indicatorCenter.y"
            [attr.r]="indicatorRadius" stroke-width="2" stroke="var(--needlecolor)"
            fill="none"/>
  </g>
  <g>
    <text [attr.x]="titleX1" [attr.y]="titleY" fill="#909296">{{leftMeasureTitle}}</text>
    <text [attr.x]="titleX2" [attr.y]="titleY" fill="#909296">{{rightMeasureTitle}}</text>
  </g>
</svg>
