<div class="flex field fluid mb-3 align-items-center" [ngClass]="classStyle">
    <p-dropdown class="flex-none flex" [disabled]="isDisabled" [panelStyleClass]="classStyle"
                [options]="countryCodes"
                dataKey="code"
                [ngModel]="selectedCountry"  [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onCodeModelChange($event, focusable)"
                [filter]="false"
                appendTo="body">
        <ng-template pTemplate="selectedItem">
            {{selectedCountry?.label}}
        </ng-template>
    </p-dropdown>
    <div class="flex-grow-1 flex">
        <input pInputText type="tel"
               [disabled]="isDisabled"
               (blur)="onTouched()"
               (keydown)="onInputKeyPress($event)"
               [(ngModel)]="phoneNumber"
               (ngModelChange)="onPhoneNumberChange()"
               [ngModelOptions]="{standalone: true}"
               autocomplete="off"
               [attr.maxLength]="maxLength"
               #focusable
               [class]="borderClass"
               [placeholder]="dialCodePlaceHolder"/>

    </div>
    <aux-info *ngIf="tooltipContent" [content]="tooltipContent"></aux-info>
</div>

