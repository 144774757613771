import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslationService} from '../../helpers/translation.service';
import * as models from '../../helpers/models';
import * as aux from 'adcore-auxiliary';
import moment from 'moment';

@Component({
  selector: 'app-mobile-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss']
})
export class MobileVideoItemComponent implements OnInit, OnChanges {
  @Input() showDetails = false;
  @Input() listItem: models.IMyCloudListItem | null = null;
  previewLoading = false;

  constructor(private translationService: TranslationService, private winRef: aux.WindowRefService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listItem.currentValue) {
      // console.log({changes});
      this.previewLoading = true;
    }
  }

  translate(key: string | undefined): string {
    return this.translationService.translate(key);
  }
  ngOnInit(): void {
    // if (!this.listItem) {
    //   throw new Error('list item not defined');
    // }
  }

  onLoadPreview(): void {
    this.previewLoading = false;
  }
  get publishDate(): string {
    return  moment(this.listItem?.publishDate).format('MMM D, YYYY');
  }
  get videoTitle(): string {
    return this.translate(this.listItem?.titleTranslationKey);
  }
  get previewLink(): string {
    return this.listItem?.previewLink || 'assets/images/no-image.png';
  }
  openVideoPlayer(event: any): void {
    if (this.listItem && !this.previewLoading){
      this.winRef.nativeWindow.open(this.listItem.contentLink, '_blank');
    }
  }
}
