import {Pipe, PipeTransform} from '@angular/core';
import {prependMoney} from './currencies';

@Pipe({
  name: 'shortNumber'
})

export class ShortNumberPipe implements PipeTransform {

  transform(value: number, currencyCode?: string, digits?: number, maxChars?: number): string {
    if (isNaN(value)) {
      return prependMoney(currencyCode, '0');
    } // will only work value is a number
    if (value === null) {
      return '';
    }
    let abs = Math.abs(value);
    const rounder = Math.pow(10, 1);
    const isNegative = value < 0; // will also work for Negative numbers
    let key = '';

    const powers = [
      {key: 'Q', value: Math.pow(10, 15)},
      {key: 'T', value: Math.pow(10, 12)},
      {key: 'B', value: Math.pow(10, 9)},
      {key: 'M', value: Math.pow(10, 6)},
      {key: 'K', value: 1000}
    ];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    let res: string = abs.toFixed(digits);
    if (maxChars) {
      res = res.substring(0, maxChars);
      if (res.substring(res.length - 1) === '.') {
        res = res.slice(0, -1);
      }
    }
    return prependMoney(currencyCode, `${(isNegative ? '-' : '')}${res}${key}`);
  }
}
