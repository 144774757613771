import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiCallService} from '../helpers/api-call.service';
import {MessagingService} from '../helpers/messaging.service';
import {MediaService} from '../helpers/media.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DOCUMENT, Location} from '@angular/common';
import * as aux from 'adcore-auxiliary';
import {takeUntil} from 'rxjs/operators';
import {Userpilot} from 'userpilot';
import * as utils from '../helpers/utils';
import * as cfg from '../helpers/config';

@Component({
  selector: 'app-upgrade',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  upgrading = true;
  appName = '';
  aid = '';
  op = '';

  constructor(private apiCallService: ApiCallService, private router: Router,
              private activatedRoute: ActivatedRoute, private titleService: Title,
              private location: Location,
              @Inject(DOCUMENT) private _document: any,
              private winRefService: aux.WindowRefService) {
    this.router.events
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          Userpilot.reload();
          this.titleService.setTitle('Upgrade | Marketing Cloud');
          utils.setAppFavIcon(this._document, 'upgrade');
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  get colorApp(): string {
    let colorPalette = '';
    switch (this.appName) {
      case 'views':
        colorPalette = 'blue-palette';
        break;
      case 'semdoctor':
        colorPalette = 'orange-palette';
        break;
      case 'alerter':
        colorPalette = 'turkiz-palette';
        break;
      case 'effortless':
        colorPalette = 'purple-palette';
        break;
      case 'effortless-marketing':
        colorPalette = 'purple-palette';
        break;
      case 'settings':
        colorPalette = 'pink-palette';
        break;
      case 'hurricane':
        colorPalette = 'blue-darker-palette';
        break;
      case 'mediablast':
        colorPalette = 'purple-darker-palette';
        break;
      default:
        colorPalette = 'green-palette';
    }
    return colorPalette;
  }

  private navigateToApp(): void {
    setTimeout(() => {
      this.upgrading = false;
      if (this.appName) {
        this.router.navigate([`/${this.appName.toLowerCase().trim()}`]);
      } else {
        this.router.navigate(['/']);
      }
    }, 200);

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(params => {
          // console.log({params});
          this.appName = params.app;
          this.aid = params.aid;
          this.op = params.op;
          this.apiCallService.startPromotion({
            aid: this.aid,
            op: this.op,
            app: this.appName
          }).then((res) => {
            if (res && res.success) {
              Userpilot.track('Advertiser Promotion', {appName: this.appName, aid: this.aid, op: this.op});
            }
            this.navigateToApp();
          }, (reason) => {
            this.navigateToApp();
          });
        }
      );
  }
}
