<p-progressSpinner *ngIf="loading"></p-progressSpinner>
<ng-container *ngIf="!loading">
  <h1 class="text-center mb-3">Thank you!</h1>
  <div class="inline-block">You have been removed from  this subscriber list and</div>
  <div class="inline-block mb-4">won't receive any further emails from us</div>
  <div><div class="inline-block">Did you unsubscribe by accident?</div>
    <p-button styleClass="p-button-link" label="Re-Subscribe" (onClick)="logIn()" [link]="true"></p-button>
  </div>
</ng-container>

