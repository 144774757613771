<ng-template #searchBox>
  <div>
     <span class="p-input-icon-left search-box">
      <span class="search-title" (click)="clear()">{{label}}</span>
        <i class="pi pi-search pointer" (click)="search()"></i>
        <input type="text" pInputText [(ngModel)]="searchParam.searchQuery" [placeholder]="placeholder"
               class="p-inputtext-sm" (keydown.enter)="search()" (keyup.escape)="clear()"/>
       <p-button icon="pi pi-times" (onClick)="clear()"></p-button>
    </span>

  </div>
</ng-template>
<div style="display: inline-block">
  <div #actualTarget (click)="toggleSearchBox($event, actualTarget)" *ngIf="!isSearchVisible" style="display: inline-block">
    <p-button icon="pi pi-search" styleClass="p-button-text p-button-sm mr-1" [text]="true" size="small" [label]="label" [disabled]="disabled"></p-button>
  </div>
  <div *ngIf="isSearchVisible" [@fadeInOut] style="display: inline-block">
    <ng-template *ngTemplateOutlet="searchBox"></ng-template>
  </div>
</div>
<p-overlayPanel #op [dismissable]="true" [appendTo]="componentTemplate" [focusOnShow]="true" styleClass="no-arrow-panel" *ngIf="!inline">
  <ng-template pTemplate>
    <ng-template *ngTemplateOutlet="searchBox"></ng-template>
  </ng-template>
</p-overlayPanel>

