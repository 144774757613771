<div #actualTarget class="align-items-center actual-target">
  <div>{{translate(periodLabel.toString())}}</div>
  <p-button icon="down-icon" iconPos="right" [label]="selectionDisplay" styleClass="p-button-outlined down-btn"
            [disabled]="disabled" (click)="toggleOverlayPanel($event, actualTarget)" [outlined]="true"></p-button>
</div>
<p-overlayPanel #op [dismissable]="true" [appendTo]="componentTemplate" [focusOnShow]="true" styleClass="no-arrow-panel time-period-panel" (onShow)="onShow()" (onHide)="onHide()" (clickOutside)="clickedOutside()">
  <ng-template pTemplate>
    <div class="pointer bold-link" [attr.selected]="isCustomDateChosen" (click)="customDateClicked($event)">Custom <i class="pi" [ngClass]="isCustomDateChosen?'pi-angle-up':'pi-angle-down'"></i></div>
    <div class="flex flex-column custom-date mb-2" *ngIf="isCustomDateChosen">
      <table>
        <tr>
          <td>{{translate('from')}}:</td>
          <td><p-calendar  (onShow)="onShowDatePicker($event)"  (onClose)="onClose($event)"  styleClass="mb-2" [showIcon]="true" [readonlyInput]="true" [(ngModel)]="customDate.from"
                           [dateFormat]="datePickerFormat" [maxDate]="today" dataType="date" selectionMode="single" view="date" [appendTo]="componentTemplate">
          </p-calendar></td>
        </tr>
        <tr>
          <td>{{translate('to')}}:</td>
          <td><p-calendar (onShow)="onShowDatePicker($event)" (onClose)="onClose($event)" [showIcon]="true" [readonlyInput]="true" [(ngModel)]="customDate.to"
                            [dateFormat]="datePickerFormat" [maxDate]="today" dataType="date" selectionMode="single" view="date" [appendTo]="componentTemplate">
          </p-calendar></td>
        </tr>
      </table>
    </div>
    <p-divider></p-divider>
    <p-tieredMenu [model]="periods"></p-tieredMenu>
    <p-divider></p-divider>
    <div class="flex align-items-center mb-2">
      <div>
        <p-inputNumber #deltaToday [(ngModel)]="customDate.deltaToday" [size]="4" [max]="9999" [allowEmpty]="true" [min]="1" [useGrouping]="false" styleClass="mr-1"
                       placeholder="30" (onInput)="deltaTodayFocus($event)" (onFocus)="deltaTodayFocus($event)"></p-inputNumber>
      </div>
      <div>{{translate('CUSTOM_DATE_UP_TO_TODAY')}}</div>
    </div>
    <div class="flex align-items-center mb-2">
      <div>
        <p-inputNumber #deltaYesterday [(ngModel)]="customDate.deltaYesterday" [size]="4" [max]="9999" [allowEmpty]="true" [min]="1" [useGrouping]="false"
                       styleClass="mr-1" (onInput)="deltaYesterdayFocus($event)" (onFocus)="deltaYesterdayFocus($event)"
                       placeholder="30"></p-inputNumber>
      </div>
      <div>{{translate('CUSTOM_DATE_UP_TO_YESTERDAY')}}</div>
    </div>
    <div class="action-btn" *ngIf="isShowFooterButtons">
      <p-button [label]="translate('APPLY')" styleClass="btn-primary" (onClick)="applyCustomDate($event)"></p-button>
      <p-button [label]="translate('CANCEL')" styleClass="btn-secondary-link" (onClick)="cancel()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>
