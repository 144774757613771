<div class="field">
  <label for="exportFormat" class="title-row input-title col-fixed">{{translate("EXPORT_FILE_FORMAT")}}</label>
  <div class="col input-row with-info">
    <select id="exportFormat" [(ngModel)]="exportFormat" class="p-component p-dropdown" [disabled]="disabled">
      <option *ngFor="let f of exportFormats; first as isFirst" [value]="f" [label]="translate(f)" [selected]="exportFormat === f || isFirst"></option>
    </select>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('exportFormat', true)"></aux-info>
  </div>
</div>
<div class="field" *ngIf="isFeedLink">
  <label for="exportURL" class="title-row input-title col-fixed">{{translate("EXPORT_URL")}}</label>
  <div class="col input-row " [ngClass]="{'with-button': buildId, 'with-info': !buildId}">
    <input id="exportURL" type="text" pInputText [readOnly]="true" [ngModel]="exportURL"/>
  </div>
  <div class="col info-row">
    <aux-info [content]="getToolTip('exportURL')"></aux-info>
  </div>
  <div class="col button-row" *ngIf="buildId">
    <p-button type="button" [label]="translate('COPY')" styleClass="btn-primary" (onClick)="copyToClipboard1.emit()"></p-button>
  </div>
</div>
<div class="field" *ngIf="buildId">
  <label for="shortURL" class="title-row input-title col-fixed">{{translate("SHORT_EXPORT_URL")}}</label>
  <ng-container *ngIf="metadataId">
    <div class="col input-row with-button">
      <input id="shortURL" type="text" pInputText [readOnly]="true" [ngModel]="shortURL"/>
    </div>
    <div class="col info-row">
      <aux-info [content]="getToolTip('shortURL')"></aux-info>
    </div>
    <div class="col button-row">
      <p-button type="button" [label]="translate('COPY')" styleClass="btn-primary" (onClick)="copyToClipboard2.emit()"></p-button>
    </div>
  </ng-container>
  <div class="loader" *ngIf="!metadataId" style="margin-left: 30px">
    <div></div>
  </div>
</div>
