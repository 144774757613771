import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import moment from 'moment';
import {ETimePeriod} from '../shared/helper';
import * as _ from 'lodash';

@Component({
  selector: 'aux-period-picker',
  templateUrl: './period-picker.component.html',
  styleUrls: ['./period-picker.component.scss']
})
export class PeriodPickerComponent implements OnInit {
  options: any = [];
  @Input() translateService: any | undefined;
  @Input() classStyle = 'green-palette';
  @Input() currentPeriod: any;
  @Input() defaultPeriod: any;
  @Output() periodChanged = new EventEmitter<any>();
  selectedOption: any = null;

  constructor() {}

  ngOnInit(): void {
    const yesterday = moment().subtract(1, 'd').endOf('day');
    this.options =  [{
      name: ETimePeriod.TODAY,
      id: 2,
      from: null,
      to: moment().endOf('day').toDate()
    }, {
      name: ETimePeriod.YESTERDAY,
      id: 3,
      from: moment().subtract(1, 'd').toDate(),
      to: moment().toDate()
    },
      {
        name: ETimePeriod.LAST_7_DAYS,
        id: 6,
        from: moment().subtract(7, 'd').toDate(),
        to: yesterday.toDate()
      },
      {
        name: ETimePeriod.LAST_WEEK,
        id: 7,
        from: moment().day('Monday').subtract(1, 'w').toDate(),
        to: moment().day(0).endOf('day').toDate()
      },
      {
        name: ETimePeriod.LAST_BUSINESS_WEEK,
        id: 8,
        from: moment().day('Monday').subtract(5, 'd').toDate(),
        to: moment().day(-2).endOf('day').toDate()
      },
      {
        name: ETimePeriod.LAST_14_DAYS,
        id: 9,
        from: moment().subtract(14, 'd').toDate(),
        to: yesterday.toDate()
      },
      {
        name: ETimePeriod.THIS_MONTH,
        id: 10,
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
      },
      {
        name: ETimePeriod.LAST_30_DAYS,
        id: 11,
        from:  moment().subtract(30, 'd').toDate(),
        to: yesterday.toDate()
      },
      {
        name: ETimePeriod.LAST_MONTH,
        id: 12,
        from: moment().startOf('month').subtract(1, 'M').toDate(),
        to: moment().subtract(1, 'M').endOf('month').toDate()
      },
      {
        name: ETimePeriod.LAST_60_DAYS,
        id: 17,
        from: moment().subtract(60, 'd').toDate(),
        to: yesterday.toDate()
      },
      {
        name: ETimePeriod.LAST_90_DAYS,
        id: 15,
        from: moment().subtract(90, 'd').toDate(),
        to: yesterday.toDate()
      },
      {
        name: ETimePeriod.ALL_TIME,
        id: 13,
        from: null,
        to: null
      }
    ];
    // console.log({currentPeriod: this.currentPeriod, selectedOption: this.selectedOption, defaultPeriod: this.defaultPeriod});
    if (this.currentPeriod) {
      this.selectedOption = this.options.find((op: any) => op.id === this.currentPeriod.pid || op.id === this.currentPeriod.id);
    } else if (this.defaultPeriod) {
      this.selectedOption = this.options.find((op: any) => op.name.toUpperCase() === this.defaultPeriod.toUpperCase());
    }
    this.options.forEach((op: any) => op.name = this.translateService.translate(op.name));
  }
  get formattedTimePeriods(): string {
    const from = this.selectedOption.from !== null ? moment(this.selectedOption.from).format('MMM DD YYYY') : '';
    const to = this.selectedOption.to !== null ? moment(this.selectedOption.to).format('MMM DD YYYY') : '';
    return (from !== '' ? ': ' : '') + from + (from === '' && to !== '' ? ': ' : from === '' && to === '' ? '' : '-') + to;
  }
  onPeriodChanged(): void {
    this.periodChanged.emit({name: this.selectedOption.name, pid: this.selectedOption.id,
      from: this.selectedOption.from, to: this.selectedOption.to});
  }

  public validate(): boolean {
    return !!this.selectedOption;
  }
  public get isValid(): boolean {
    return this.validate();
  }
  translate(key?: string): string {
    return this.translateService?.translate(key?.toUpperCase()) || key?.toUpperCase();
  }
}
