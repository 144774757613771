import {Component, Inject, OnInit} from '@angular/core';
import {SchedulerComponent} from '../scheduler.component';
import * as _ from 'lodash';
import * as helper from '../../shared/helper';
@Component({
  selector: 'aux-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {

  param: number | undefined;
  constructor(@Inject(SchedulerComponent) public parent: SchedulerComponent) { }

  ngOnInit(): void {
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l) {
      this.param =  helper.safeListAt(this.parent.schedule?.repeaterOptions, 0);
    }else {
      this.param = 1;
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param]);
    }
  }

  onInputParamChanged(event: any): void {
    helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [ event.value]);
  }

  get suffix(): string {
    return ` ${this.parent.translate('days')}`.toLowerCase();
  }
}
