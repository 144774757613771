import {Inject, Injectable, InjectionToken} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {MediaService} from './media.service';

export const DEFAULT_CONTENT_TYPE: InjectionToken<string> = new InjectionToken<string>('defaultContentType');

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_CONTENT_TYPE) protected defaultContentType: string, private mediaService: MediaService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.params.get('isFileUpload')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': this.defaultContentType || 'application/json'
        }
      });
    }
    if (['client', 'admin'].some(s => request.url.toLowerCase().includes(s))) {
      request = request.clone({
        setHeaders: {
          'x-access-token': this.mediaService.accessToken || '',
          'x-access-advertiser':  this.mediaService.selectedAdvertiserId || '',
          'x-access-media-mcc': this.mediaService.currentAccountParent?.key || '',
          'x-access-media-feed': this.mediaService.currentFeed?.key || '',
          'x-access-media-campaign': '',
          'x-access-media-account': this.mediaService.currentAccount?.key || '',
          'x-access-media-channel': this.mediaService.currentAccountChannel?.key || ''
        }
      });
    }

    // we can add here all media if required
    return next.handle(request);
  }
}
