import {Component, Inject, OnInit} from '@angular/core';
import {SchedulerComponent} from '../scheduler.component';
import * as helper from '../../shared/helper';

@Component({
  selector: 'aux-minutely',
  templateUrl: './minutely.component.html',
  styleUrls: ['./minutely.component.scss']
})
export class MinutelyComponent implements OnInit {

  param: number | undefined;
  constructor(@Inject(SchedulerComponent) public parent: SchedulerComponent) { }

  ngOnInit(): void {
    const l = this.parent.schedule?.repeaterOptions?.length || 0;
    if (l) {
      this.param = helper.safeListAt(this.parent.schedule?.repeaterOptions, 0);
    }else {
      this.param = 5;
      helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [this.param]);
    }
  }

  onInputParamChanged(event: any): void {
    helper.safeToObjProp(this.parent.schedule, 'repeaterOptions', [event.value]);
  }
  get suffix(): string {
    return ` ${this.parent.translate('minutes')}`.toLowerCase();
  }
}
