import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {SafePipe} from './shared/safe.pipe';
import {ShortNumberPipe} from './shared/short-number.pipe';
import {MediaComponent} from './media/media.component';
import {HelpComponent} from './help/help.component';
import {ButtonModule} from 'primeng/button';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SharedModule} from 'primeng/api';
import {TreeModule} from 'primeng/tree';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {SearchComponent} from './search/search.component';
import {AvatarModule} from 'primeng/avatar';
import {DividerModule} from 'primeng/divider';
import {TooltipModule} from 'primeng/tooltip';
import {InplaceModule} from 'primeng/inplace';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MenuModule} from 'primeng/menu';
import {LeftMenuComponent} from './left-menu/left-menu.component';
import {DelimitersComponent} from './delimiters/delimiters.component';
import {TextFileReadDirective} from './shared/text-file-read.directive';
import {IframeTrackerDirective} from './shared/iframe-tracker.directive';
import {WindowRefService} from './shared/windowref.service';
import {NgClickOutsideDirective} from 'ng-click-outside2';
import {TimePeriodComponent} from './time-period/time-period.component';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {InputNumberModule} from 'primeng/inputnumber';
import {CalendarModule} from 'primeng/calendar';
import {EmailsBoxComponent} from './emails-box/emails-box.component';
import {MerchantComponent} from './merchant/merchant.component';
import {KeyFilterModule} from 'primeng/keyfilter';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PasswordModule} from 'primeng/password';
import {DropdownModule} from 'primeng/dropdown';
import {OneTimeComponent} from './scheduler/one-time/one-time.component';
import {MinutelyComponent} from './scheduler/minutely/minutely.component';
import {HourlyComponent} from './scheduler/hourly/hourly.component';
import {DailyComponent} from './scheduler/daily/daily.component';
import {WeeklyComponent} from './scheduler/weekly/weekly.component';
import {MonthlyComponent} from './scheduler/monthly/monthly.component';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {SchedulerComponent} from './scheduler/scheduler.component';
import {GetLinkComponent} from './get-link/get-link.component';
import {InfoComponent} from './info/info.component';
import {TourStepTemplateComponent} from './tour/tour-step-template.component';
import {TourAnchorDirective} from './tour/tour-anchor.directive';
import {TourStepTemplateService} from './tour/tour-step-template.service';
import {TourService} from './tour/tour.service';
import {PeriodPickerComponent} from './period-picker/period-picker.component';
import {FtpDetailsComponent} from './ftp-details/ftp-details.component';
import {PhoneComponent} from './phone/phone.component';
import { EditPropComponent } from './edit-prop/edit-prop.component';
import { PasswordPropComponent } from './password-prop/password-prop.component';
import {PanelModule} from 'primeng/panel';
import {ImageResizerService} from './shared/image-resizer.service';
import {AutoFocusModule} from 'primeng/autofocus';
import {ClosePropService} from './shared/close-prop.service';
import {MediaInternalService} from './shared/media-internal.service';
import {SpinProgressComponent} from './spin-progress/spin-progress.component';
import {ChartModule} from 'primeng/chart';
import { CallbackFilterPipe } from './shared/callback-filter.pipe';
import { DigitCodeComponent } from './digit-code/digit-code.component';
import {DigitOnlyModule} from '@uiowa/digit-only';
import {RadioButtonsComponent} from './radio-buttons/radio-buttons.component';
import { UploadMerchantComponent } from './upload-merchant/upload-merchant.component';
import { SvgGaugeComponent } from './svg-gauge/svg-gauge.component';
import { SvgSliderComponent } from './svg-slider/svg-slider.component';
import { SvgChartComponent } from './svg-chart/svg-chart.component';
import {HighlightSearchPipe} from './shared/highlight-search.pipe';
import {CustomDatePipe} from './shared/custom-date.pipe';
import {StrongPwdFeedbackComponent} from './strong-pwd-feedback/strong-pwd-feedback.component';

@NgModule({
  declarations: [SafePipe, ShortNumberPipe, UserProfileComponent, MediaComponent, HelpComponent,
    SearchComponent, LeftMenuComponent, DelimitersComponent, TextFileReadDirective, IframeTrackerDirective,
    TimePeriodComponent, EmailsBoxComponent, SchedulerComponent,
    MerchantComponent, OneTimeComponent, MinutelyComponent,
    HourlyComponent, DailyComponent, WeeklyComponent, MonthlyComponent, GetLinkComponent,
    InfoComponent, TourStepTemplateComponent, TourAnchorDirective, PeriodPickerComponent,
    FtpDetailsComponent, PhoneComponent, EditPropComponent, PasswordPropComponent,
    RadioButtonsComponent, UploadMerchantComponent, DigitCodeComponent,
    SpinProgressComponent, CallbackFilterPipe,
    SvgChartComponent, SvgGaugeComponent, SvgSliderComponent, HighlightSearchPipe, CustomDatePipe, StrongPwdFeedbackComponent],
  imports: [
    CommonModule,
    ButtonModule,
    OverlayPanelModule,
    SharedModule,
    TreeModule,
    DialogModule,
    RadioButtonModule,
    FormsModule,
    InputTextModule,
    AvatarModule,
    DividerModule,
    TooltipModule,
    MenuModule,
    InplaceModule,
    NgClickOutsideDirective,
    TieredMenuModule,
    InputNumberModule,
    CalendarModule,
    AutoCompleteModule,
    KeyFilterModule,
    PasswordModule,
    DropdownModule,
    CheckboxModule,
    MultiSelectModule,
    InputSwitchModule,
    PanelModule,
    AutoFocusModule,
    ChartModule,
    DigitOnlyModule
  ],
  exports: [UserProfileComponent, LeftMenuComponent, MediaComponent, SearchComponent,
    HelpComponent, DelimitersComponent, SafePipe, ShortNumberPipe,
    TextFileReadDirective, IframeTrackerDirective, TimePeriodComponent,
    EmailsBoxComponent, SchedulerComponent, MerchantComponent, GetLinkComponent,
    InfoComponent, TourStepTemplateComponent, TourAnchorDirective,
    PeriodPickerComponent, FtpDetailsComponent, PhoneComponent, EditPropComponent, PasswordPropComponent,
    RadioButtonsComponent, UploadMerchantComponent, DigitCodeComponent,
    SpinProgressComponent, CallbackFilterPipe,
    SvgChartComponent, SvgGaugeComponent, SvgSliderComponent, HighlightSearchPipe, CustomDatePipe, StrongPwdFeedbackComponent]
})
export class AdcoreAuxiliaryModule {
  public static forRoot(): ModuleWithProviders<AdcoreAuxiliaryModule> {
    return {
      ngModule: AdcoreAuxiliaryModule,
      providers: [WindowRefService, TourStepTemplateService, TourService, ImageResizerService, ClosePropService, MediaInternalService],
    };
  }
}
