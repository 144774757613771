import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as helper from '../shared/helper';

@Component({
  selector: 'aux-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnChanges {
  @Input() disabled = false;
  @Input() autoload = false;
  private selectedTab: helper.IMediaRow | null = null;
  @Input() tabs: helper.IMediaRow[] = [];
  @Output() selectingTab: EventEmitter<helper.IMediaSelectedEvent> = new EventEmitter<helper.IMediaSelectedEvent>(false);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.cdr.detectChanges();
    // if (changes.activeTab && changes.activeTab.currentValue) {
    //   this.selectingTab.emit({
    //     mode: helper.EMediaMode.PAGE,
    //     row: this.selectedTab
    //   });
    // }
  }

  @Input()
  get activeTab(): helper.IMediaRow | null {
    return this.selectedTab;
  }

  set activeTab(value: helper.IMediaRow | null) {
    this.selectedTab = value;
  }

  private activation(tab: helper.IMediaRow | null): void {
    // console.log({tab});
    if (tab && (tab.alwaysEnabled || !tab.disabled)) {
      this.selectedTab = tab;
      this.selectingTab.emit({
        mode: helper.EMediaMode.PAGE,
        row: tab
      });
    }
  }

  get tabList(): helper.IMediaRow[] | [] {
    return this.tabs?.filter(t => t.visible || t.visible === undefined) || [];
  }
  onActivate(event: MouseEvent | null, tab: helper.IMediaRow): void {
    if (!this.disabled) {
      this.activation(tab);
    }
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    if (this.autoload) {
      this.activateTab();
    }
  }


  activateTab(tab?: helper.IMediaRow): void {
    if (!tab && !this.selectedTab && this.tabs?.length) {
      this.activation(this.tabs[0]);
    } else if (tab && this.tabs?.length) {
      tab = this.findTab(tab);
      this.activation(tab);
    }
  }

  isTabActive(tab: helper.IMediaRow, isFirst: boolean): boolean {
    // if (!this.selectedTab) {
    //   return isFirst;
    // }
    return tab.key === this.selectedTab?.key;
  }

  findTab(tab?: helper.IMediaRow): helper.IMediaRow {
    return this.tabs.find(t => t.key === tab?.key) || this.tabs[0];
  }

  trackByMethod(index: any, row: any): any {
    return row.key;
  }

  calcItemHeight() {
    if (this.tabList?.length > 0) {
      return 100/this.tabList.length;
    }
    return 0;
  }
   
}
