import * as aux from 'adcore-auxiliary';
import * as cfg from './config';
import moment from 'moment';


export const timeout = (ms: number): Promise<any> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const sleep = async (ms: number, fn: any, ...args: any): Promise<any> => {
  await timeout(ms);
  return fn(...args);
};

export const isUndefinedOrNull = (value: any): boolean => {
  return value === null || typeof value === 'undefined';
};

export const isStringIsNumber = (value: string): boolean => {
  const maybeNumber = Number(value);
  return !isNaN(maybeNumber);
};

export const deepcopy = <T>(o: T): T => {
  return (o) ? JSON.parse(JSON.stringify(o)) : {};
};

export const printLog = (logItem: any, production: boolean = false, isError: boolean = false, functionName?: string) => {
  if (!functionName) {
    const stackTrace = (new Error()).stack || ''; // Only tested in latest FF and Chrome
    let callerName = stackTrace.replace(/^Error\s+/, ''); // Sanitize Chrome
    callerName = callerName.split('\n')[1]; // 1st item is this, 2nd item is caller
    callerName = callerName.replace(/^\s+at Object./, ''); // Sanitize Chrome
    callerName = callerName.replace(/ \(.+\)$/, ''); // Sanitize Chrome
    callerName = callerName.replace(/\@.+/, ''); // Sanitize Firefox
    functionName = callerName;
  }
  functionName = `${functionName}:  `;
  if (!production) {
    if (isError) {
      console.error(functionName, logItem);
    } else {
      console.log(functionName, logItem);
    }
  }
};

export const checkPopupWindow = (popup: any, onclose: () => Promise<void>): void => {
  const w = popup;
  const cb = onclose || null;
  const t = setTimeout(() => {
    checkPopupWindow(w, cb);
  }, 500);
  let closing = false;
  try {
    // happens when window is closed in FF/Chrome/Safari
    if (popup.closed || popup.top === null) {
      closing = true;
    }
  } catch (e) {   // happens when window is closed in IE
    closing = true;
  }
  if (closing) {
    clearTimeout(t);
    // tslint:disable-next-line:no-unused-expression
    onclose && onclose();
  }
};

export const formatAccountNumber = (customerId: string): string => {
  const parts = [customerId.slice(0, 3), customerId.slice(3, 6), customerId.slice(6, 10)];
  return `${parts[0]}-${parts[1]}-${parts[2]}`;
};


// Turn enum into array of strings
export const EnumToArray = (eNum: any): string[] => {
  return Object.keys(eNum)
    .filter(isStringIsNumber)
    .map(key => eNum[key]);
};


export const listOfAttributes = (obj: any): string[] => {
  if (obj) {
    return Object.keys(obj).sort((key1: string, key2: string): number => {
      return obj[key1].order - obj[key2].order;
    });
  }
  return [];
};

export const addToRecentMedia = (media: aux.IMediaRow | null, recent: aux.IMediaRow[]) => {
  if (media) {
    const i = recent.findIndex(rc => rc.key === media.key);
    if (i === -1) {
      if (recent.length === cfg.MAX_RECENT_ITEMS + 1) {
        recent.pop();
      }
      recent.unshift(media);
    } else {
      recent[i] = media;
    }
  }
};

export const removeFromRecentMedia = (key: string | null | undefined, recent: aux.IMediaRow[]) => {
  const i = recent.findIndex(rc => rc.key === key);
  if (i !== -1) {
    recent.splice(i, 1);
  }
};

export const updateRecentMedia = (media: aux.IMediaRow | null, recent: aux.IMediaRow[]) => {
  if (media) {
    const i = recent.findIndex(rc => rc.key === media.key);
    if (i > -1) {
      recent[i] = media;
    }
  }
};

export const validateUrl = (urlParam: string): boolean => {
  let url;
  // urlParam = decodeURIComponent(urlParam);
  // console.log({urlParam});
  // const re = new RegExp( '^(ftp|http|https)://[^\.]+(\\.[^\.]+)+$');
  // return re.test(urlParam);
  try {
    url = new URL(urlParam);
  } catch (_) {
    return false;
  }
  // console.log({url, urlParam});
  return ['ftp:', 'http:', 'https:'].includes(url.protocol);
};

export const getDateTime = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  let month = (now.getMonth() + 1).toString();
  let day = now.getDate().toString();
  let hour = now.getHours().toString();
  let minute = now.getMinutes().toString();
  let second = now.getSeconds().toString();
  if (month.length === 1) {
    month = `0${month}`;
  }
  if (day.length === 1) {
    day = `0${day}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  if (minute.length === 1) {
    minute = `0${minute}`;
  }
  if (second.length === 1) {
    second = `0${second}`;
  }
  return `${year}${month}${day}${hour}${minute}${second}`;
};


export const prepJobRow = (mongoRow: any): aux.IMediaRow | null => {
  if (mongoRow) {
    return {
      key: mongoRow._id,
      label: mongoRow.name,
      description: mongoRow.description,
      is_manual: mongoRow.isManual,
      value: mongoRow,
      type: mongoRow.type,
      subType: mongoRow.isManual ? aux.EMediaSubType.MANUAL : aux.EMediaSubType.SCHEDULED,
      visible: mongoRow.status !== 'deleted',
      status: mongoRow.status
    };
  }

  return null;
};

export const safeListAt = (list: any[] | null | undefined, index: number, defaultValue: any = null): any => {
  if (list && list.length && index < list.length) {
    return list[index];
  }
  return defaultValue;
};

export const setAppFavIcon = (doc: any, appName: string) => {
  if (doc) {
    switch (appName) {
      case 'feeditor':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_feeditor.svg');
        break;
      case 'feeditor-plus':
      case 'feeditor-max':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_feeditor_plus.svg');
        break;
      case 'views':
      case 'adcore':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_views.svg');
        break;
      case 'effortless-marketing':
      case 'effortless_feed':
      case 'effortless':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_effortless.svg');
        break;
      case 'semdoc':
      case 'semdoctor':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_semdoc.svg');
        break;
      case 'alerter':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_alerter.svg');
        break;
      case 'hurricane':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_hurricane.svg');
        break;
      case 'mediablast':
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon_mediablast.svg');
        break;
      default:
        doc.getElementById('appFavIcon')?.setAttribute('href', 'favicon.svg');
    }
  }
};


export const capitalize = (word: string): string => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
};

export const titleCase = (sentence: string): string => {
  if (!sentence) {
    return sentence;
  }
  return sentence.split(' ').map(capitalize).join(' ');
};

export const momentDiff = (day1: any = null, day2: any = null, durationBy?: any): number => {
  if (!durationBy){
    durationBy = 'days';
  }
  if (typeof day1 === 'number') {
    day1 *= 1000;
  }
  if (typeof day2 === 'number') {
    day2 *= 1000;
  }
  day1 = (day1) ? moment(new Date(day1)).startOf('day') : null;
  day2 = (day2) ? moment(new Date(day2)).startOf('day') : null;
  if (!day1) {
    day1 = moment().add(1, 'years').startOf('day');
  }
  if (!day2) {
    day2 = moment().startOf('day');
  }
  const duration = moment.duration(day1.diff(day2.startOf('day')));
  return Number(duration.as(durationBy).toFixed(0));
};

// returns hex
const getRGB = (value: any): any => {
  let g;
  let r;
  let b;
  const valueNum = parseFloat(value);
  if (valueNum < 50) {
    r = Math.floor(255 * (valueNum / 50));
    g = 255;
    b = 0;
  } else if (valueNum >= 50 && valueNum < 100) {
    r = 255;
    g = Math.floor(255 * ((50 - valueNum % 50) / 50));
    b = 0;
  } else /*case of 100*/{
    r = 255;
    g = 255;
    b = 255;
  }
  return [r, g, b].join();
};


/* Colour Handling functions STARTS */
const changeRange0To100 = (minVal: number = 0, maxVal: number = 0, val: number = 0): number => {
  minVal = isNaN(minVal) ? 0 : minVal;
  maxVal = isNaN(maxVal) ? 0 : maxVal;
  val = isNaN(val) ? 0 : val;
  let oldRange = maxVal - minVal;
  // tslint:disable-next-line:no-unused-expression
  !oldRange && (oldRange = 1);
  const newRange = 100;
  return (((val - minVal) * newRange) / oldRange);
};

export const getColorByNumber = (valMin: number, valMax: number, value: number): string => {
  // console.log("getColorByNumber", val_min,val_max,number);
  const colour = getRGB(changeRange0To100(valMin, valMax, value));
  return `rgb(${colour})`;
};

export const between = (x: number, min: number, max: number): boolean => {
  return x > min && x <= max;
};

const toClass = (mainClass: string, childClassType: string, score: number = 0) => {
  if (score !== null) {
    if (between(score, 70, 100)) {
      return `${mainClass} ${childClassType}-green`;
    } else if (between(score, 45, 70)) {
      return `${mainClass} ${childClassType}-blue`;
    } else {
      return `${mainClass} ${childClassType}-red`;
    }
  }
  return `${mainClass}`;
};

export const toBackgroundClass = (mainClass: string, score: number = 0): string => {
  return toClass(mainClass, 'background', score);
};

export const toBorderClass = (mainClass: string, score: number = 0): string => {
  return toClass(mainClass, 'border', score);
};

export const hashCode = (s: string) => s.split('').reduce((a, b) => {
  // tslint:disable-next-line:no-bitwise
  a = ((a << 5) - a) + b.charCodeAt(0);
  // tslint:disable-next-line:no-bitwise
  return a & a;
}, 0);

export const findClosestDivisibleBySomeNumber = (num: number, someNumber: number): number => {
  return Math.ceil(num / someNumber) * someNumber;
};

export const isDivisibleBySomeNumber = (num: number, someNumber: number): boolean => {
  return num % someNumber === 0;
};


export const getUTCNow = (): Date => {
  const now = new Date();
  return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
    now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
};


export const getUTCTimestampInSeconds = (): number => {
  const now = new Date();
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
    now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds()) / 1000;
};

export const getUTCTimestamp = (): number => {
  const now = new Date();
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
};

export const getDomainNameFromURL = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (e) {
    return '';
  }
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const formatCurrency = (amount: number, currency: string = 'USD'): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
};


export const readFileAsBase64 = async (file?: File | null): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryString = reader.result;
      const base64EncodedFile = (binaryString) ? btoa(binaryString.toString()) : '';
      resolve(base64EncodedFile);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    if (file) {
      reader.readAsBinaryString(file);
    }
    else {
      reject('File is not provided');
    }
  });
};

export const getExtensionFromContentType = (contentType?: string) => {
  if (!contentType) {
    return '';
  }
  const mapping: any = {
    'image/png': '.png',
    'image/jpeg': '.jpg',
    'image/jpg': '.jpg',
    'image/gif': '.gif',
    'image/svg+xml': '.svg',
    'application/pdf': '.pdf'
  };

  return mapping[contentType] || '';
};

