import { Component, Input, AfterContentChecked, ElementRef } from '@angular/core';


@Component({
 selector: 'aux-svg-slider',
 templateUrl: './svg-slider.component.html',
 styleUrls: ['./svg-slider.component.scss']
})
export class SvgSliderComponent implements AfterContentChecked {
 @Input() percentageWidth = 100;
 @Input() height = 0;
 @Input() sliderHeight = 18;
 @Input() offSetX = 15;
 @Input() offSetY = 50;
 @Input() position = -1; // in percentages
 @Input() indicatorRadius = 18;
 @Input() gradId = 'linear-grad1';
 @Input() reverseGradient = false;
 @Input() leftMeasureTitle = '-30%';
 @Input() rightMeasureTitle = '+30%';

 width = 350;
 stopColors = [
   {color: '#f98989', offset: '0'},
   {color: '#f3cd71', offset: '0.14'},
   {color: '#9ddc6d', offset: '0.541'},
   {color: '#38dcdc', offset: '1'},
 ];
 sliderPath = '';
 indicatorCenter: any = {};
 x1 = 1;
 x2 = -0.025;
 titleY = 0;
 titleX1 = 0;
 titleX2 = 0;
 goalLineX = this.width / 2;
 goalLineColor = '#BEBEBE';
 isLast = false;
 lastSignWidth = 5;
 constructor(private el: ElementRef) {}

 ngAfterContentChecked(): void {
   if (this.gradId === 'gauge_roas') {
     this.isLast = true;
   }
   this.goalLineX = this.width / 2;
   this.width =  this.getParentWidth();
   const sliderWidth = this.width - this.offSetX * 4;
   this.titleY = this.height - this.offSetY / 2;
   this.titleX1 = this.offSetX;
   this.titleX2 = sliderWidth - this.offSetX * 4;

   if (this.reverseGradient) {
    // this.stopColors = this.stopColorsReversed;
     this.x1 = 0.025;
     this.x2 = 1.025;
   }

   this.sliderPath =
     `M ${this.offSetX * 2} ${this.height - this.offSetY - this.sliderHeight} ` +
     `L ${this.width - this.offSetX * 2} ${this.height - this.offSetY - this.sliderHeight} ` +
     `A 1 1, 0, 0 1, ${this.width - this.offSetX * 2} ${this.height - this.offSetY} ` +
     `L ${this.offSetX * 2} ${this.height - this.offSetY} ` +
     `A 1 1, 0, 0 1, ${this.offSetX * 2} ${this.height - this.offSetY - this.sliderHeight}`;

   this.indicatorCenter = {
     x: this.offSetX * 2 + (this.width - this.offSetX * 4) * this.position / 100,
     y: this.height - this.offSetY - this.sliderHeight / 2
   };
 }

 private getParentWidth(): number {
   return this.el.nativeElement.parentElement.offsetWidth;
 }

 isPositionValid(): boolean {
    return this.position >= 0 && this.position <= 100;
 }
}

